import { b64ToUtf8 } from "../../utils/base64";
import { getRequest } from "../../services";
import { APICONFIG } from "../../services/apiConfig";
import { message } from "antd";
import { setUserInfoInLS } from "../../utils/helper";

const _getUserInfo = async () => {
  try {
    const response = await getRequest(APICONFIG.getUserInfoUrl);
    if (response["error"]) {
      message.error(response["error"]["message"]);
      return undefined;
    }
    return response["data"];
  } catch (e) {
    console.error("getUserInfo failed", e);
    message.error(
      "An error occurred while processing your request. Please try again."
    );
    return undefined;
  }
};

export const getUserInfo = async (dispatch) => {
  try {
    const payload = await _getUserInfo();
    if (payload) {
      dispatch({
        type: "USER_DATA",
        payload
      });
      setUserInfoInLS(btoa(JSON.stringify(payload)));
    }
  } catch (err) {
    console.error(err);
  }
};

const postLogin = async (idToken, dispatch, searchParams, navigate) => {
  try {
    await dispatch({
      type: "LOGIN",
      payload: {
        token: idToken
      }
    });
    await getUserInfo(dispatch);
    const callbackEncoded = searchParams.get("callback");
    const callbackURL = callbackEncoded ? b64ToUtf8(callbackEncoded) : "/";
    navigate(callbackURL);
  } catch (err) {
    console.error(err);
  }
};

export default postLogin;
