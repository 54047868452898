/** @jsxImportSource @emotion/react */
import { useContext } from "react";
import styled from "@emotion/styled";
import { css, useTheme } from "@emotion/react";
import {
  background,
  border,
  color,
  flexbox,
  layout,
  position,
  space,
  typography
} from "styled-system";

import { Form as AntdForm, Row, Col } from "antd";
import { FlexRow, Box } from "./index";
import { SideNavContext } from "../../App";

// Form building blocks
// Use them for simplicity

// Form: a customized Antd Form with sensible defaults in the following area:
// a Paliq form by default has a fixed maximum width

export const Form = ({ layout, autoComplete, children, ...props }) => (
  <StyledForm layout="vertical" autoComplete="off" {...props}>
    {children}
  </StyledForm>
);

Form.useForm = AntdForm.useForm;
Form.useFormInstance = AntdForm.useFormInstance;

// FormRow: defines a row of fields, based on Antd's <Row> and <Col>
// Do not wrap the underlying fields with <Col>, instead <Col> will be inserted automatically

export const FormRow = ({ spans, children, ...props }) => {
  const theme = useTheme();
  const fields = Array.isArray(children) ? children : [children];
  const colSpans = Array.isArray(children) ? spans : spans || [24];
  // when there is only one field, the span is default to 24, unless specified otherwise

  return (
    <StyledRow gutter={[16, 16]} {...props}>
      {fields.map((field, index) => (
        <Col
          key={index}
          span={
            colSpans && colSpans.length > index ? colSpans[index] : undefined
          }
        >
          {field}
        </Col>
      ))}
    </StyledRow>
  );
};

// ButtonRow: a row for buttons

export const ButtonRow = ({ children }) => {
  const theme = useTheme();
  const { sideNavOpen } = useContext(SideNavContext);
  const buttons = Array.isArray(children) ? children : [children];
  return (
    <>
      <div
        css={css`
          // a spaceholder to make sure there is enough whitespace at the bottom of the form, in case the form
          // is taller than the screen, it can scroll clear the bottons row, which is fixed to the bottom of the screen
          height: 80px;
        `}
      ></div>
      <div
        css={css`
          // use an outer container to make sure the top border is across the entire page
          position: fixed; // attach the row to the bottom of the screen
          bottom: 0;
          right: 0;
          left: ${sideNavOpen
            ? theme.layout.sideNavOpenWidth
            : theme.layout.sideNavClosedWidth};
          box-shadow: 0 0 3px #ccc;
          background-color: #fff; // so it covers anything that scrolls underneath
        `}
      >
        <div
          css={css`
            // use an inner container to sync with the form width
            max-width: ${theme.layout.maxWidth};
            padding: 16px 24px;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
          `}
        >
          {buttons.map((button, index) => (
            <Box key={index} ml={3}>
              {button}
            </Box>
          ))}
        </div>
      </div>
    </>
  );
};

export const StyledForm = styled(AntdForm)(space, layout);

export const StyledRow = styled(Row)(space, layout);
