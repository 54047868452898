import React, { useState, useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { getAllBills } from "./billing_helper";
import ListTable from "../ListTable";
import { Box, Spinner } from "../common";
import { withTooltip, formatDate, formatMoney } from "../common/table";
import { StatusTag } from "../common/tags";
import { error } from "../../utils/commonActions";
import { getBillingPeriod } from "./billing_helper";

const BiilsList = () => {
  const [searchParams] = useSearchParams();
  const [bills, setBills] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const externalGUID = searchParams.get("external-id") ?? "";

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        setBills((await getAllBills(externalGUID)).result);
      } catch (err) {
        console.error(err);
        error("Error fetching bills");
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const columns = [
    // column headers for the table
    {
      title: "Bill Number",
      dataIndex: "bill_number",
      width: "10%",
      render: (_, record) => (
        <Link to={`/billing/details/${record?.uid}`} className="link-color">
          Bill No: {record.bill_number}
        </Link>
      )
    },
    {
      title: "Customer Name",
      dataIndex: "customer_name",
      width: "20%"
    },
    {
      title: "Billing Period",
      dataIndex: "last_update_date",
      width: "15%",
      render: (_, record) => getBillingPeriod(record.start_at, record.end_at)
    },
    {
      title: "Billing Date",
      dataIndex: "last_update_date",
      width: "12%",
      render: (_, record) => formatDate(record.billing_date)
    },
    {
      title: "Due Date",
      dataIndex: "due_date",
      width: "12%",
      render: (_, record) => formatDate(record.due_date)
    },
    {
      title: "Bill Amount",
      dataIndex: "charges",
      width: "15%",
      render: (_, record) => formatMoney(record.currency_code, record.total)
    },
    {
      title: "Bill Status",
      dataIndex: "status",
      width: "8%",
      render: (text) => <StatusTag status={text} />
    }
  ];

  return isLoading ? (
    <Box height="70vh">
      <Spinner />
    </Box>
  ) : (
    <ListTable
      title="Bills"
      data={bills}
      columns={withTooltip(columns)}
      rowKey={(record) => record.uid}
      pagination="pagination"
    />
  );
};

export default BiilsList;
