import React from "react";
import { Layout, Menu } from "antd";
import MenuItem from "antd/lib/menu/MenuItem";
import { Title } from "..";
import "./styles.scss";
import { SectionTitle } from "../page";

const { Sider } = Layout;

const Sidebar = (props) => {
  const { selectedKey, onMenuClick, data, title } = props;
  console.log(title)
  const buildMenuItems = (items) => {
    return items.map(({ label, route, icon, children }) => {
      if (children) {
        return (
          <React.Fragment key="label">
            <Title
              level={5}
              style={{
                marginLeft: "12px",
                fontWeight: "bold",
                marginTop: "12px",
                marginBottom: "12px"
              }}
            >
              {label}
            </Title>
            {buildMenuItems(children)}
          </React.Fragment>
        );
      }
      return (
        <MenuItem key={route} icon={icon ? icon : null}>
          {label}
        </MenuItem>
      );
    });
  };

  return (
    <Sider className="paliq-sidebar">
      {title && <SectionTitle title={title} />}
      <Menu model="inline" selectedKeys={[selectedKey]} onClick={onMenuClick}>
        {buildMenuItems(data)}
      </Menu>
    </Sider>
  );
};

export default Sidebar;
