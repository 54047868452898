import React, { useState } from "react";
import { message, Modal } from "antd";
import { APICONFIG } from '../../services/apiConfig';
import { AuthContext } from "../../App";
import { postRequest } from "../../services";
import { logoutFromLS, getRefreshTokenFromLS } from "../../utils/helper";
import { useNavigate } from "react-router-dom";

function LogoutModal({show, setShow}) {

  const { dispatch } = React.useContext(AuthContext);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [modalText, setModalText] = useState("Press OK to logout");

  const navigate = useNavigate();

  const handleOk = async () => {
    setModalText('Logging out...');
    setConfirmLoading(true);
    try {
      const ref_token = getRefreshTokenFromLS();
      const response = await postRequest(APICONFIG.getUserLogoutUrl, { refresh_token: ref_token });
      console.log("res = ", response);
      const status = response['op_status'];
      if (status === 'OK') {
        message.info("You are logged out successfully.");
      } else if (status === 'ERROR') {
        message.error("An error occurred while processing your request.");
      }
      logoutFromLS();
      dispatch({ type: 'LOGOUT' });
      setShow(false);
      setConfirmLoading(false);
      navigate("/");
    } catch (err) {
      console.log('logout failed');
      setModalText('Logout Failed. Check your internet access and Try Again');
      setConfirmLoading(false);
      Promise.reject(err);
    }
  };


  const handleCancel = () => {
    console.log('Clicked cancel button');
    setModalText('Press OK to logout');
    setShow(false);
  };

  return (
    <Modal
      title="Confirm Logout"
      open={show}
      onOk={handleOk}
      confirmLoading={confirmLoading}
      onCancel={handleCancel}
    >
      <p>{modalText}</p>
    </Modal>
  );
};


export default LogoutModal;