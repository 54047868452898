import React from "react";
import PaymentMethodForm from "./PaymentMethodForm";

const CreatePaymentMethod = (props) => {
  const data = {
  };

  return <PaymentMethodForm data={data}/>;
};

export default CreatePaymentMethod;
