import {Button, DatePicker, Space, Popconfirm, InputNumber, Input} from "antd";
import { QuestionCircleOutlined } from '@ant-design/icons';
import { FormItem } from "../../../paliq/input/FormItem";
import { FormRow } from "../../../common/form";
import { PercentageOutlined } from "@ant-design/icons";
import { CollapsibleCard } from "../../../common/CollapsibleCard";
import { BILLING_SCHEDULE_STATUS, CURRENCY_DATA, termData } from "../../../../constants/globalStaticConstacts";
import { useNavigate } from "react-router-dom";
import { putRequest } from "../../../../services";
import {putBillingSchedule, terminateAccountPlan} from "../../../../services/apiConfig";
import { info } from "../../../../utils/commonActions";
import { ActiveBillingScheduleIcon, DisableBillingScheduleIcon } from "../../../common/icons";
import {StatusTag} from "../../../common/tags";
import {concatStrings} from "../../../common/table";
import React from "react";
import {Text} from "../../../common";
import Status from "../../../../utils/status";




const getPlanStatus = (name, code) => {

  return <div><Space><Text>{name }</Text><StatusTag status={code} /></Space></div>;

} ;


const CustomerPlanDetail = (props) => {
  const navigate = useNavigate();
  const {pricingPlan, accountPlan: p, external_guid} = props;
  const plan_name = pricingPlan?.name;
  const plan_status = p.status;
  const account_plan_id = p.id;
  const plan_id = p.plan_id;
  const customer_id = p.customer_id;
  const schedule_status = p.billing_schedule_status;

  const handleViewLatestBill = (customer_id, plan_id) => {
    const url = "/billing/details/" + customer_id + "/" + plan_id;
    navigate(url);
  };

  const displayBillingSchedule = () => {
    console.info("Inside Display Billing Schedule");
  };

  const handleCustomerPlanActivation = (customer_id, account_plan_id) => {
    const start_date = p.start_date;
    const end_date = p.end_date;
    const today = new Date();
    if ( Date.parse(end_date) <= today) {
      info("Plan End Date must be in future.");
      return '';
    }
    else {
      const res = putRequest(putBillingSchedule(customer_id, account_plan_id));
      if (res) {
        console.log(`created schedule for the customer = ${res}`);
        info("Billing Schedule Created for this plan");
      } else {
        console.log(`error in creating schedule for the customer = ${res}`);
        info("Error in creating billing schedule for this plan");
      }

      return res;

    }
  };

  const handleTerminatePlan = () => {
    //console.log("Terminate plan id = "+ account_plan_id);
    const res = putRequest(terminateAccountPlan(account_plan_id));
    if (res) {
      console.log(`Terminated Plan  = ${res}`);
      info("Customer Plan (contract) terminated successfully");
    } else {
      console.log(`error terminating plan = ${res}`);
      info("Error in terminating customer plan");
    }
    return res;
  };


  return (
    <CollapsibleCard
      title={ getPlanStatus(plan_name, plan_status) }
      accessories={
        <>
          <Space>
            {schedule_status === BILLING_SCHEDULE_STATUS.CREATED ||
            schedule_status === BILLING_SCHEDULE_STATUS.TERMINATED ? (
              <ActiveBillingScheduleIcon
                title="Billing Schedule Exists for this plan."
                onClick={() => {
                  displayBillingSchedule();
                }}
              />
            ) : (
              <DisableBillingScheduleIcon />
            )}
            <Button
              type={"primary"}
              onClick={async () => {
                await handleCustomerPlanActivation(
                  customer_id,
                  account_plan_id
                );
              }}
              disabled= {!Status.isActive(plan_status)}
                /*{
                schedule_status === BILLING_SCHEDULE_STATUS.CREATED ||
                schedule_status === BILLING_SCHEDULE_STATUS.TERMINATED
              }*/
            >
              Activate Billing
            </Button>
            <Button
              type={"primary"}
              ghost
              onClick={() => {
                handleViewLatestBill(
                  external_guid,
                  account_plan_id
                );
              }}
            >
              View Bill To-date
            </Button>
            <Popconfirm
              title="Are you sure you want to terminate this plan?"
              description={"Are you sure you want to terminate this plan?"}
              onConfirm={handleTerminatePlan}

              okText="Yes"
              cancelText="No"
              icon={
                <QuestionCircleOutlined
                  style={{
                    color: 'red',
                  }}
                />
              }
            >
              <Button danger ghost disabled={!Status.isActive(plan_status)}>
                Terminate Plan
              </Button>
            </Popconfirm>
          </Space>
        </>
      }
      onDelete={() => alert("TODO: delete plan from customer")}
    >
      <FormRow spans={[6, 6, 6, 6, 6, 6, 6, 6,6,6]} mb={2}>
        <FormItem
          name={`${p.plan_id}.start_date`}
          label="Start Date"
          rules={[
            { required: true, message: "Start Date is required" }
          ]}
        >
          <DatePicker format="M/D/YYYY" style={{ width: "100%" }} />
          {/* TODO: figure out why Ash is using antd.DatePicker instead of FormItem */}
        </FormItem>
        <FormItem
          name={`${p.plan_id}.end_date`}
          label="End Date"
          rules={[
            { required: true, message: "End Date is required" }
          ]}
        >
          <DatePicker format="M/D/YYYY" style={{ width: "100%" }} />
        </FormItem>
        <FormItem
          name={`${p.plan_id}.billing_currency`}
          type="select"
          defaultValue={"USD"}
          data={CURRENCY_DATA}
          label="Billing Currency"
          tooltip="Currency in which the customer will be billed"
          rules={[
            {
              required: true,
              message: "Billing Currency is required"
            }
          ]}
          onChange={(e) => {}}
          size="middle"
        />
        <FormItem
          name={`${p.plan_id}.sales_tax_percent`}
          type="number"
          prefix={<PercentageOutlined />}
          label="Sales Tax (%)"
          tooltip="Sales tax amount applied on the bill"
          rules={[
            {
              required: true,
              message: "Sales Tax Percentage is required"
            }
          ]}
          onChange={(e) => {}}
          size="middle"
        />
        <FormItem
          name={`${p.plan_id}.payment_terms`}
          type="selectkvp"
          placeholder="Select"
          data={termData}
          label="Payment Terms"
          rules={[
            { required: true, message: "Payment Terms is required" }
          ]}
        />
        <FormItem
          name={`${p.plan_id}.discount_percent`}
          type="number"
          prefix={<PercentageOutlined />}
          label="Discount (%)"
          size="middle"
        />
        <FormItem
          name={`${p.plan_id}.salesorder`}
          placeholder="Enter Sales Order Number"
          type="text"
          label="Sales Order Number"
          size="middle"
        />
        <FormItem
          name={`${p.plan_id}.po_number`}
          placeholder="Enter PO Number"
          type="text"
          label="Purchase Order Number"
        />
        <FormItem
          name={`${p.plan_id}.pre_commit_qty`}
          placeholder="Pre-commit Usage Qty"
          type="text"
          label="Pre-commit Usage (Qty)"
        />
        <FormItem
          name={`${p.plan_id}.pre_commit_amt`}
          placeholder="Pre-commit Usage Amt"
          type="text"
          label="Pre-commit Usage ($)"
        />
      </FormRow>
    </CollapsibleCard>
  );
};

export default CustomerPlanDetail;
