import React, { useState } from "react";
import {
  Input,
  Select,
  Checkbox,
  Radio,
  Cascader,
  InputNumber,
  Switch,
  DatePicker,
  Button,
  Tooltip
} from "antd";
import { formatNumber, parseNumber } from "@brightspace-ui/intl/lib/number.js";
import { TagsInput } from "react-tag-input-component";
import { CopyOutlined } from "@ant-design/icons";
import { CURRENCY_DATA } from "../../../constants/globalStaticConstants";

const { Search, TextArea } = Input;
const { Option } = Select;
const { RangePicker } = DatePicker;

export const TextInput = (props) => {
  return (
    <Input
      name={props?.name}
      placeholder={props?.placeholder}
      type={props.type}
      size={props.size}
      style={props.style}
      disabled={props.disabled}
      id={props.id}
      value={props?.value}
      onChange={props.onChange}
      className={props?.className}
      defaultValue={props.defaultValue}
      prefix={props.prefix}
      addonBefore={props.addonBefore}
      addonAfter={props.addonAfter}
      bordered={props.bordered}
      readOnly={props.readOnly}
    />
  );
};

export const TextAreaField = (props) => {
  return (
    <TextArea
      id={props.id}
      placeholder={props.placeholder}
      type={props.type}
      value={props.value}
      name={props.name}
      size={props.size}
      onChange={props.onChange}
      className={props.className}
      style={props.style}
      readOnly={props.readOnly}
    />
  );
};

export const SelectField = (props) => {
  return (
    <Select
      placeholder={props.placeholder}
      onChange={props.onChange}
      defaultValue={props.defaultValue}
      value={props.value}
      className={props.className}
      label={props.label}
      style={props.style}
      name={props.name}
      size={props.size}
      allowClear
      mode={props.mode}
      disabled={props.readOnly}
    >
      {props?.data?.map(({ value, label, disabled = false }, dataIndex) => (
        <Option key={dataIndex} value={value} disabled={disabled}>
          {label}
        </Option>
      ))}
    </Select>
  );
};

export const CheckboxField = (props) => {
  const { options, onChange, defaultValue, style, className, checked, name } =
    props;
  return (
    <Checkbox.Group
      options={options}
      checked={checked}
      name={name}
      className={className}
      style={style}
      defaultValue={defaultValue}
      onChange={onChange}
    />
  );
};

export const SingleCheckbox = (props) => {
  return (
    <Checkbox
      style={props.style}
      key={props.data}
      name={props.name}
      checked={props?.checked}
      onChange={props.onChange}
      defaultChecked={props.defaultChecked}
      value={props.data}
      className={props.className}
      disabled={props.disabled}
    >
      {props.label}
    </Checkbox>
  );
};
export const RadioField = (props) => {
  return (
    <Radio.Group
      className="radiogroup"
      name={props.name}
      onChange={props.onChange}
      value={props.value}
    >
      {props.radioData?.map((radioItem, radionValue) => (
        <Radio name={props.name} key={radionValue} value={radionValue + 1}>
          {radioItem}
        </Radio>
      ))}
    </Radio.Group>
  );
};

export const CascaderField = (props) => {
  return (
    <Cascader
      style={props.style}
      name={props.name}
      options={props.options}
      onChange={props.onChange}
      size={props.size}
      multiple
      maxTagCount="responsive"
      placeholder={props.placeholder}
      value={props.value}
    />
  );
};

export const TagInputField = (props) => {
  return (
    <>
      <TagsInput
        value={props.value}
        onChange={props.onChange}
        name={props.name}
        placeHolder={props.placeHolder}
      />
    </>
  );
};

export const InputNumberField = (props) => {
  return (
    <InputNumber
      style={props.style}
      className={props.className}
      defaultValue={props.defaultValue}
      min={props.min}
      size={props.size}
      placeholder={props.placeholder}
      max={props.max}
      suffix={props.suffix}
      onChange={props.onChange}
      value={props.value}
      readOnly={props.readOnly}
      disabled={props.disabled}
      addonBefore={props.addonBefore}
      addonAfter={props.addonAfter}
    />
  );
};

export const CurrencyField = (props) => {
  const { symbol, min, style, size, defaultValue, value, disabled, readOnly } =
    props;
  return (
    <InputNumber
      // style={props.style}
      // className={props.className}
      // defaultValue={props.defaultValue}
      // min={props.min}
      // size={props.size}
      // placeholder={props.placeholder}
      // max={props.max}
      // suffix={props.suffix}
      // onChange={props.onChange}
      // value={props.value}
      // readOnly={props.readOnly}
      // disabled={props.disabled}
      size={size || "middle"}
      style={{
        width: "100%", // default to fill the containing element, typically a <Col>
        ...style
      }}
      addonBefore={symbol}
      controls={false} // hide the up/down arrow
      formatter={(numberValue) => {
        if (typeof numberValue === "string") {
          if (numberValue === "") return "";
          else numberValue = parseNumber(numberValue);
        }
        return formatNumber(numberValue, { maximumFractionDigits: 2 });
      }}
      parser={(stringValue) => parseNumber(stringValue)}
      value={value}
      min={typeof min === "undefined" ? 0 : min}
      defaultValue={defaultValue}
      disabled={disabled}
      readOnly={readOnly}
    />
  );
};

export const PasswordField = (props) => {
  return (
    <Input.Password
      id={props.id}
      placeholder={props.placeholder}
      text={props.text}
      name={props.name}
      value={props.value}
      disabled={props.disabled}
      style={props.style}
      size={props.size}
      onChange={props.onChange}
      className={props.className}
    />
  );
};

export const SearchSelect = (props) => {
  const selectAfter = (
    <Select
      size={props.size}
      placeholder={props.placeholder}
      defaultValue={props.defaultValue}
      disabled={props.isdisabled}
      className="select-after"
    >
      {props.data?.map((selectAfterItem, selectAfterIndex) => (
        <Option key={selectAfterIndex} value={selectAfterItem}>
          {selectAfterItem}
        </Option>
      ))}
    </Select>
  );
  return (
    <Input
      name={props.name}
      style={props.style}
      size={props.size}
      disabled={props.isdisabled}
      button
      addonAfter={selectAfter}
      placeholder={props.placeholder}
      prefix={props.prefix}
      bordered={props.bordered}
    />
  );
};

export const CopyClipboardField = (props) => {
  return (
    <Input.Group compact>
      <Input
        style={{ width: "calc(100% - 55px)" }}
        defaultValue={props.defaultValue}
        readOnly={props.readOnly}
        value={props.value}
      />
      <Tooltip title="Copy">
        <Button icon={<CopyOutlined />} onClick={props.onChange} />
      </Tooltip>
    </Input.Group>
  );
};

export const DatePickerField = (props) => {
  const { defaultValue, format, style, onChange, className, size } = props;
  return (
    <DatePicker
      defaultValue={defaultValue}
      size={size}
      onChange={onChange}
      className={className}
      format={format}
      style={style}
    />
  );
};

export const SwitchInputField = (props) => {
  const {
    name,
    onChange,
    disabled,
    checked,
    size,
    key,
    className,
    checkedChildren,
    unCheckedChildren,
    defaultChecked
  } = props;
  return (
    <Switch
      onChange={onChange}
      checked={checked}
      className={className}
      key={key}
      value={checked}
      disabled={disabled}
      name={name}
      size={size}
      checkedChildren={checkedChildren}
      unCheckedChildren={unCheckedChildren}
      defaultChecked={defaultChecked}
    />
  );
};

export const SearchField = (props) => {
  const { name, placeholder, onSearch, className } = props;
  return (
    <Search
      name={name}
      placeholder={placeholder}
      onSearch={onSearch}
      className={className}
    />
  );
};

export const DateRangeField = (props) => {
  const { dateFormat, defaultValue, onChange } = props;
  return (
    <>
      <RangePicker
        defaultValue={defaultValue}
        format={dateFormat}
        onChange={onChange}
      />
    </>
  );
};

export const SelectFieldKVP = (props) => {
  // dataValues will be [{label: "Net30" , value: "30"},...]
  return (
    <Select
      showSearch
      placeholder={props.placeholder}
      onChange={props.onChange}
      defaultValue={props.defaultValue}
      value={props.value}
      className={props.className}
      label={props.label}
      style={props.style}
      name={props.name}
      size={props.size}
      allowClear
      mode={props.mode}
      disabled={props.disabled}
      filterOption={props.filterOption}
    >
      {props?.data?.map((dataValue, dataIndex) => (
        <Option key={dataIndex} value={dataValue.value}>
          {dataValue.label}
        </Option>
      ))}
    </Select>
  );
};

export const InputWithCurrency = (props) => {
  const [symbol, setSymbol] = useState("$");
  const onSelect = (value) => {
    props.onCurrencyChange(value);
    const currency = CURRENCY_DATA.find((c) => c.value == value);
    setSymbol(currency.symbol);
  };
  return (
    <InputNumber
      style={props.style}
      className={props.className}
      defaultValue={props.defaultValue}
      min={props.min}
      size={props.size}
      placeholder={props.placeholder}
      max={props.max}
      onChange={props.onChange}
      value={props.value}
      readOnly={props.readOnly}
      disabled={props.disabled}
      prefix={symbol}
      addonAfter={
        <Select
          defaultValue="usd"
          onChange={onSelect}
          value={props.currencyValue}
        >
          {CURRENCY_DATA.map((currency) => (
            <Option value={currency.value} key={currency.value}>
              {currency.label}
            </Option>
          ))}
        </Select>
      }
      step="0.001"
    />
  );
};
