import { getRequest, postRequest, putRequest } from ".";
import { APICONFIG, getServiceMeterUrl, getServicePricingUrl } from "./apiConfig";


export const onError = err => {
  console.log("Error in useMutation = ", err);
  const message = "error"
  alert(message);
};


//Create new Service w/ or w/o meters
export const createService = async (data) => { // for RQ
  const res = await postRequest(APICONFIG.getServiceUrl, data);
  console.log("res from createService = ", res);
  return res;
};

//Update Service Basic Info PUT
export const updateServiceBasicInfo = async ({ data, id }) => {
  const res = await putRequest(`${APICONFIG.getServiceUrl}/${id}`, data);
  console.log("res from updateServiceBasicInfo = ", res);
  return res;
};

//Update Service Meter PUT
export const updateServiceMeters = async ({ data, id }) => {
  const res = await putRequest(getServiceMeterUrl(id), data);
  console.log("res from updateServiceMeters = ", res);
  return res;
};

//Create New Service Meter(s) POST
export const createServiceMeters = async ({ data, id }) => {
  if (data && data.length > 0) {
    const res = await postRequest(getServiceMeterUrl(id), data);
    console.log("res from createServiceMeters = ", res);
    return res;
  } else {
    return "No new meters to be created. All Ok."
  }
};

//Create Basic Service 
export const createServicePricing = async (data, id) => {
  return postRequest(getServicePricingUrl(id), data).then((res) => {
    console.log("createServicePricing res = ", res);
    return res;
  }).catch((err) => {
    console.log('An Error Occured in createServicePricing = ', err);
    return Promise.reject(err)
  })
}

//Active Service 
export const activateService = async (data, id) => {
  return putRequest(`${APICONFIG.getServiceUrl}/${id}`, data).then(res => {
    console.log("activateService res = ", res);
    return res;
  })
    .catch(err => {
      console.log('An Error Occured in activateService = ', err);
      return Promise.reject(err)
    });
}

//GET SERVICES LIST
export const getAllServices = async () => {
  return getRequest(`${APICONFIG.getServiceUrl}?page_size=50`).then(res => {
    console.log("getAllServices res = ", res);
    return res;
  }).catch((err) => {
    console.log('An Error Occured in getting All Services = ', err);
    return Promise.reject(err)
  })
};

//GET SERVICE BY ID
export const getService = async (id) => {
  if (id) {
    return await getRequest(`${APICONFIG.getServiceUrl}/${id}`).then(res => {
      console.log("getServices by id res = ", res);
      return res;
    })
      .catch(err => {
        console.log('An Error Occured in getting Service by id = ', err);
        return err;
      })
  } else {
    return null;
  }
};
