/** @jsxImportSource @emotion/react */
import Icon, {
  ReadOutlined,
  CloseOutlined,
  CheckCircleFilled
} from "@ant-design/icons";
import { ReactComponent as MeterSvg } from "../../assets/img/icons/meter.svg";
import { css } from "@emotion/react";
import { info } from "../../utils/commonActions";

const iconStyles = css`
  & svg {
    fill: rgba(0, 0, 0, 0.45);
    width: 16px;
    height: 16px;
    vertical-align: -2px;
  }
`;

const activeIconStyles = css`
  & svg {
    fill: #009879;
    width: 16px;
    height: 16px;
    vertical-align: -2px;
  }
`;

// used in Collapsible cards
export const CloseIcon = (props) => (
  <CloseOutlined {...props} css={iconStyles} />
);

// used in Additional Comments
export const ReadIcon = (props) => <ReadOutlined {...props} css={iconStyles} />;

export const MeterIcon = (props) => <Icon component={MeterSvg} {...props} />;

export const ActiveBillingScheduleIcon = (props) => (
  <CheckCircleFilled {...props} css={activeIconStyles} />
);

export const DisableBillingScheduleIcon = (props) => (
  <CheckCircleFilled {...props} css={iconStyles} />
);
