import { Alert, Button, Form } from "antd";
import { ReactComponent as Logo } from "../../assets/img/paliq-logo-login.svg";
import { Box, Center, Title } from "../common";
import { FormItem } from "../paliq/input/FormItem";
import { CardContainer } from "../common/CardContainer";
import { FormRow } from "../common/form";

const ChangePasswordCard = (props) => {
  const [form] = Form.useForm();

  const { onSubmit, onSubmitFailed, isLoading, formInfo, alert } = props;

  return (
    <CardContainer>
      <Center mt="4rem">
        <Box width="24rem">
          <Center mb="2rem">
            <Logo />
            <Title level={4}>
              Change Password
            </Title>
          </Center>
          <Form
            layout="vertical"
            form={form}
            name="changePassword"
            onFinish={onSubmit}
            onFinishFailed={onSubmitFailed}
            scrollToFirstError
            requiredMark={false}
          >
            {formInfo && <FormRow mb={24}>
              <Box>
                {formInfo}
              </Box>
            </FormRow>}
            {alert.type && <FormRow mb={24}>
              <Alert message={alert.message} type={alert.type} />
            </FormRow>}
            <FormRow mb={24}>
              <FormItem
                name="new_password"
                placeholder="Enter your new password"
                type="password"
                rules={[{ required: true, message: 'Enter your new password' }]}
                label="New Password"
              />
            </FormRow>
            <FormRow mb={24}>
              <FormItem
                name="confirm_password"
                placeholder="Confirm your new password"
                type="password"
                rules={[{ required: true, message: 'Confirm your new password' }]}
                label="Confirm Password"
              />
            </FormRow>
            <FormRow mb={24}>
              <Center>
                <Button
                  type="primary"
                  size="large"
                  htmlType="submit"
                  loading={isLoading}
                  style={{ width: "100%" }}
                >
                  Change Password
                </Button>
              </Center>
            </FormRow>
          </Form>
        </Box>
      </Center>
    </CardContainer>
  );
};

export default ChangePasswordCard;
