import { useParams } from "react-router";
import { Box, Spinner } from "../../../common";
import { CodePageTitle } from "../../../common/page";
import React, { useEffect, useState } from "react";
import { PrimaryButton } from "../../../paliq/buttons";
import { getCustomerById } from "../../../../services/customerFormAction";
import { useNavigate } from "react-router-dom";
import {
  AttributesPanel,
  LeftAlignedCardPanel
} from "../../../common/InfoPanel";
import { Button } from "antd";
import { FlexRight } from "../../../common";
import {StatusTag} from "../../../common/tags";


const getStripeCustomerId = (data) => {
    const externalIds =  data?.external_ids;
    if (externalIds != null) {
    //TODO : Get Strip customer id from the array and send it back.
      for (const item of externalIds) {
        if (item.app_name = 'stripe') {
          return item.source_account_uuid;
        }else
          return null;
      }
  }
    else {
      return null;
    }
}

const CustomerDetailPage = ({ onAccountDetail }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [accountData, setAccountData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [autocollection, setAutoCollection] = useState(false);
  const [stripeCustomerId, setStripeCustomerId] = useState("");

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const data = (await getCustomerById(id))?.account;
        setAccountData(data);
        setAutoCollection(data.payment_autocollection);
        //setStripeCustomerId(getStripeCustomerId(data));
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  if (isLoading)
    return (
      <Box height="70vh">
        <Spinner />
      </Box>
    );

  return (
    <>
      <LeftAlignedCardPanel
        cols={2}
        attrs={[
          ["Customer name", accountData?.name],
          ["Invoice method", "Paliq"], //"accountData?.source_account_uuid],
          ["Contact email", accountData?.billing_alert_email],
          ["Payment method", "Charge via Stripe"], //accountData?.source_account_uuid],
          ["Contact phone", accountData?.phone],
          ["Stripe customer Id", accountData?.payment_app_cust_id], //accountData?.source_account_uuid],
          ["External customer ID", accountData?.external_guid],
          ["Auto-collection", <StatusTag status={accountData?.payment_autocollection.toString()} />] //,
          //["Status", accountData?.status]
        ]}
      />
      <FlexRight my={"24px"}>
        <Box mr={2}>
          <Button
            size="large"
            style={{ padding: "5px 30px 10px 30px" }}
            onClick={() => navigate("/customers")}
          >
            Back
          </Button>
        </Box>
        <Button
          size="large"
          type="primary"
          style={{ padding: "6px 30px 10px 30px" }}
          onClick={() => {
            if (accountData) {
              onAccountDetail(false);
            }
          }}
        >
          Edit
        </Button>
      </FlexRight>
    </>
  );
};

// export it separately from the definition to avoid tripping lazy load in routes.
export default CustomerDetailPage;
