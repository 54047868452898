import { getSanitizedValues } from "../../utils/helper";
import dayjs from "dayjs";
import moment from "moment";
import { info } from "../../utils/commonActions";

const customerStaticField = {
  company_id: 21
};

// need to rework

// const extraValues = [
//   "overages_alerts",
//   "invoice_by_email",
//   "billing_alerts",
//   "contacts",
//   "billing_alert_email",
//   "overage_alert_email",
//   "invoice_email",
//   "date_created",
//   "date_updated",
//   "id",
//   "parent_account_id",
//   "source_account_uuid"
// ];

export const payloadCustomerLisDuplicate = (postValues) => {
  //  need to re-work
  let payload = getSanitizedValues(postValues);
  payload["name"] = `copy of ${payload["name"]}`;
  payload = { ...payload, ...customerStaticField };
  // console.log(payload)
  // let data = Object.entries(payload)
  // data = data.filter(val => !extraValues.includes(val));
  // console.log(data)
  delete payload?.overages_alerts;
  delete payload?.invoice_by_email;
  delete payload?.billing_alerts;
  delete payload?.contacts;
  delete payload?.billing_alert_email;
  delete payload?.overage_alert_email;
  delete payload?.invoice_email;
  delete payload?.date_created;
  delete payload?.date_updated;
  delete payload?.id;
  delete payload?.parent_account_id;
  delete payload?.source_account_uuid;
  return payload;
};

// need to rework

const textItem = [
  {
    text: "Not Sent",
    color: "default"
  },
  {
    text: "Invoiced",
    color: "processing"
  },

  {
    text: "Paid",
    color: "success"
  },
  {
    text: "Over Due",
    color: "warning"
  }
];

export const colorContrast = (text) => {
  const data = textItem?.find((elm) => (elm?.text === text ? elm : null));
  return data?.color;
};

export const contactsCreatePayload = (value) => {
  value?.forEach((element) => {
    element["company_id"] = 21;
    element["status"] = "active";
    if (!element?.is_primary) {
      element["is_primary"] = false;
    }
    if (!element?.is_billing) {
      element["is_billing"] = false;
    }
  });
  return value;
};

export const ReviewTableData = (data) => {
  data?.forEach((element) => {
    element["is_billing"] = !element["is_billing"] === false ? "Yes" : "No";
    element["is_primary"] = !element["is_primary"] === false ? "Yes" : "No";
  });
  return data;
};

/* In createNewCustomerPlanJSONitemFromPlan, Create this planJSON:
 {
    "plan_id": "6de10289-a677-486e-b912-28a58ffb67ec",
    "customer_id": "d9edb5ce-e437-423c-ac9c-47ca7c3665ac",
    "salesorder": null,
    "payment_terms": null,
    "start_date": null,
    "end_date": null,
    "billing_currency": null,
    "sales_tax_percent": null,
    "company_id": 1,
  }

  From this plan:
  {
    "id": "6de10289-a677-486e-b912-28a58ffb67ec",
    "name": "Programmable Voice - Volume Discounts",
    "description": "Volume discount plan Programmable voice service",
    "status": "draft",
    "plan_duration_months": 1,
    "billing_duration_months": 1,
    "plan_location": null,
    "currency": "USD",
    "additional_comments": null,
    "plan_json": [],
    "date_created": "2022-09-16 04:28:12.069475+00:00",
    "date_updated": "2022-09-16 04:28:12.069472+00:00"
  },
*/
export const createNewCustomerPlanJSONitemFromPlan = (plan_id, customer_id) => {
  return {
    id: null,
    plan_id: plan_id,
    customer_id: customer_id,
    salesorder: null,
    payment_terms: null,
    start_date: null,
    end_date: null,
    billing_currency: null,
    sales_tax_percent: null,
    discount_percent: null,
    pre_commit_qty:null,
    pre_commit_amt:null
  };
};

const otherFields = [
  "billing_currency",
  "sales_tax_percent",
  "payment_terms",
  "salesorder",
  "discount_percent",
  "po_number",
  "pre_commit_qty",
  "pre_commit_amt"
];
const dateFields = ["start_date", "end_date"];
const allFields = [...otherFields, ...dateFields];

export const generateFormValuesFromPlanJSONs = (planJSONs) => {
  const retVals = {};
  var customParseFormat = require("dayjs/plugin/customParseFormat");
  dayjs.extend(customParseFormat);
  planJSONs.map((pj, i) => {
    otherFields.map((f) => {
      retVals[`${pj.plan_id}.${f}`] = pj[f] ?? null;
    });
    dateFields.map((f) => {
      const rv = pj[f] ? moment(pj[f]) : moment();
      retVals[`${pj.plan_id}.${f}`] = rv;
    });
  });
  return retVals;
};

/*
{
    "name": "Test company 12346",
    "external_guid": "klk",
    "billing_alert_email": "a@e.com",
    "parent_account_id": "a78c19b9-831b-47d1-b4d2-c699230d8561",
    "6de10289-a677-486e-b912-28a58ffb67ec.start_date": "2022-09-20T07:53:17.814Z",
    "6de10289-a677-486e-b912-28a58ffb67ec.end_date": "2022-09-19T07:53:21.144Z",
    "6de10289-a677-486e-b912-28a58ffb67ec.billing_currency": "USD",
    "6de10289-a677-486e-b912-28a58ffb67ec.sales_tax_percent": 1,
    "6de10289-a677-486e-b912-28a58ffb67ec.payment_terms": "30",
    "6de10289-a677-486e-b912-28a58ffb67ec.discount_percent": 11.45,
    "6de10289-a677-486e-b912-28a58ffb67ec.salesorder": null,
    "6de10289-a677-486e-b912-28a58ffb67ec.po_number": "123-456-789"
}
*/
export const updateCustomerPlanJSONsWithFieldValues = (planJSONs, values) => {
  const newPlanJSONs = [...planJSONs];
  newPlanJSONs.map((pj) => {
    otherFields.map((f) => {
      pj[f] = values[`${pj.plan_id}.${f}`] ?? null;
    });
    dateFields.map((f) => {
      pj[f] = values[`${pj.plan_id}.${f}`].format("YYYY-MM-DD") ?? null;
    });
  });
  return newPlanJSONs;
};

export const prepPayloadForCustomerInfo = (formValues, create = true) => {
  const payload = { ...formValues };
  if (!payload.parent_account_id || payload.parent_account_id === "None") {
    payload.parent_account_id = null;
    payload.is_root_account = true;
  } else {
    payload.is_root_account = false;
  }
  if (create) {
    if (
      !formValues.is_root_account &&
      (!formValues.parent_account_id ||
        formValues.parent_account_id == "00000000-0000-0000-0000-000000000000")
    ) {
      payload.is_root_account = true;
    }
  }
  return payload;
};

export const prepPayloadForCustomerPlan = (planJSON) => {
  const retVal = {};
  allFields.map((f) => {
    retVal[f] = planJSON[f];
  });
  retVal.plan_id = planJSON.plan_id;
  retVal.status = "active";
  return retVal;
};

// validation helpers. Can be used if needed
export const areStartEndDatesDifferent = (values, planJSONs) => {
  let retVal = true;
  planJSONs.forEach((m) => {
    const start_date = moment(values[`${m.plan_id}.start_date`]).format(
      "YYYY-MM-DD"
    );
    const end_date = moment(values[`${m.plan_id}.end_date`]).format(
      "YYYY-MM-DD"
    );
    console.log("start and end dates = ", start_date, end_date);
    if (start_date === end_date) retVal = false;
  });
  return retVal;
};

// data processing helpers
export const processCustomerDataForShow = (res) => {
  return res?.account;
};

export const processAllPlansData = (res) => {
  return res.pricing_plans;
};

// RQ messages
export const onNewCustomerSuccess = (data) => {
  console.log("Success in useMutation = ", data);
  const message = "Success: New Customer Created";
  info(message);
};

export const onNewCustomerError = (err) => {
  console.log("Error = ", err);
  const message = "Error: New Customer Creation Failed ";
  info(message);
};

export const onCustomerUpdateSuccess = (data) => {
  console.log("Success in useMutation = ", data);
  const message = "Success: Customer Info Updated";
  info(message);
};

export const onCustomerUpdateError = (err) => {
  console.log("Error = ", err);
  const message = "Error: Customer Info Update Failed ";
  info(message);
};
// plans
export const onAddNewCustomerPlanSuccess = (data) => {
  console.log("Success in useMutation = ", data);
  const message = "Success: New Plan Assigned to Customer";
  info(message);
};

export const onAddNewCustomerPlanError = (err) => {
  console.log("Error = ", err);
  const message = "Error: New Plan Assignment to Customer Failed ";
  info(message);
};

export const onCustomerPlanUpdateSuccess = (data) => {
  console.log("Success in useMutation = ", data);
  const message = "Success: Customer Plan Updated";
  info(message);
};

export const onCustomerPlanUpdateError = (err) => {
  console.log("Error = ", err);
  const message = "Error: Customer Plan Update Failed ";
  info(message);
};
