/** @jsxImportSource @emotion/react */
import React from "react";
import { Link } from "react-router-dom";
import { css, useTheme } from "@emotion/react";
import dayjs from "dayjs";
import { Button, Popconfirm, Tooltip, Typography } from "antd";
import { Infotip, FlexRow } from "./index";
import { getServiceMetersViewEditKey } from "../menus/menuKeys.constants";
import utc from "dayjs/plugin/utc";

// Insert <Tooltip> component automatically if `tooltip` prop exists
export const withTooltip = (columns) =>
  columns && Array.isArray(columns)
    ? columns.map(({ title, tooltip, ...rest }) => {
        return {
          title: tooltip ? <Infotip t={title} tt={tooltip} /> : title,
          ...rest
        };
      })
    : columns;

// formatDate: parse date/time from original data and format into the standard M/D/YYYY format
export const formatDate = (dt, defaultValue = "") => {
  var utc = require("dayjs/plugin/utc");
  dayjs.extend(utc);
  if (!dt) return defaultValue;
  //return dayjs(dt).format("M/D/YYYY");
  return dayjs(dt).utc().format("M/D/YYYY");
};

// formatMonth: parse date/time from original data and format into the standard M/YYYY format
export const formatMonth = (dt, defaultValue = "") => {
  var utc = require("dayjs/plugin/utc");
  dayjs.extend(utc);
  if (!dt) return defaultValue;
  //return dayjs(dt).format("M/D/YYYY");
  return dayjs(dt).utc().format("M/YYYY");
};

// formatDate: parse date/time from original data and format into the standard M/D/YYYY format
export const formatDateToUTC = (dt, defaultValue = "") => {
  var utc = require("dayjs/plugin/utc");
  dayjs.extend(utc);
  if (!dt) return defaultValue;
  //return dayjs(dt).format("M/D/YYYY");
  return dayjs(dt).toISOString();
};

export const concatStrings = (string1, string2) => {
  return string1 + string2;
};

export const formatMoney = (currency, amt) => {
  if (!currency) currency = "USD";

  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency
  });
  if (amt === null) {
    amt = 0.0;
  }
  return formatter.format(amt);
};

export const formatQty = (qty) => {
  return qty.toLocaleString(undefined, { maximumFractionDigits: 2 });
};

// formatDateTime: parse date/time from original data and format into a local date time format
export const formatDateTime = (dt, defaultValue = "") => {
  if (!dt) return defaultValue;
  return dayjs(dt).format("M/D/YYYY h:mm:ss a");
};

// ActionGroup: a wrapper for a list action icons
// we move the styling of action icons here, to make it easier to use any Antd icons
export const ActionGroup = ({ children }) => {
  const theme = useTheme();
  return (
    <FlexRow
      mx="-4px"
      css={
        css`
          height: 28px;
          padding: 2px 8px;
          font-size: 13px;
          align-items: center;
          justify-content: center;
        `
      }
    >
      {children}
    </FlexRow>
  );
};

// ActionItem: a common component for action icons
export const ActionItem = ({
  icon,
  tooltip,
  confirm,
  onConfirm,
  linkTo,
  onClick,
  disabled
}) => {
  let iconAndAction;
  if (linkTo)
    iconAndAction = (
      <Link to={linkTo} disabled={disabled} style={{ fontSize: "24px" }}>
        {icon}
      </Link>
    );
  else if (onClick)
    iconAndAction = (
      <Typography.Link
        onClick={onClick}
        disabled={disabled}
        style={{ fontSize: "15px" }}
      >
        {icon}
      </Typography.Link>
    );
  else if (confirm && onConfirm)
    iconAndAction = (
      <Popconfirm
        title={confirm}
        onConfirm={onConfirm}
        style={{ fontSize: "16px" }}
      >
        {/*{icon}*/}
        <Typography.Link style={{ fontSize: "16px" }}
                         disabled={disabled}>{icon}</Typography.Link>
      </Popconfirm>
    );

  return tooltip ? (
    <Tooltip title={tooltip} placement="bottom">
      {iconAndAction}
    </Tooltip>
  ) : (
    iconAndAction
  );
};

// ActionItem: a common component for action icons
export const ActionButton = ({
  title,
  tooltip,
  confirm,
  onConfirm,
  linkTo,
  onClick,
  disabled,
  shape
}) => {
  let iconAndAction;

  if (onClick)
    iconAndAction = (
      <Button shape={shape} size="medium" type={"primary"} onClick={onClick}>
        {title}
      </Button>
    );
  else if (confirm && onConfirm)
    iconAndAction = (
      <Popconfirm title={confirm} onConfirm={onConfirm} disabled={disabled}>
        <Button shape={shape} size="medium" type={"primary"} onClick={onClick}>
          {title}
        </Button>
      </Popconfirm>
    );

  return tooltip ? (
    <Tooltip title={tooltip} placement="bottom">
      {iconAndAction}
    </Tooltip>
  ) : (
    iconAndAction
  );
};
