import React from "react";
import { Button } from "antd";
import "./styles.scss";
import { GooglePlusOutlined } from "@ant-design/icons";
import { classes } from "../../../utils/helper";

const BaseButton = ({ Icon, children, fullWidth, className, ...rest }) => (
  <Button
    className={classes("primary", className, fullWidth ? "fullWidth" : "")}
    {...rest}
  >
    {Icon || null}
    {children}
  </Button>
);

export const PrimaryButton = (props) => (
  <BaseButton type="primary" className="primary-button" {...props} />
);

export const SecondaryButton = (props) => (
  <BaseButton className="secondary-button" {...props} />
);

export const DefaultButton = (props) => <Button {...props} />;

export const PrimaryGhost = ({ Icon, children, ...rest }) => (
  <Button className="primary" ghost type="primary" {...rest}>
    {Icon || null}
    {children}
  </Button>
);
export const MainButton = ({ Icon, children, ...rest }) => (
  <Button className="main" {...rest}>
    {Icon || null}
    {children}
  </Button>
);

export const SignInButton = (props) => (
  <Button type="primary" htmlType="submit" block className="singIn" {...props}>
    {props.children}
  </Button>
);

export const GoogleButton = (props) => (
  <Button block className="google">
    <span>
      <GooglePlusOutlined /> {props.children} with Google
    </span>
  </Button>
);
