/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Button, Spin, Typography, Tooltip as AntTooltip } from "antd";
import {
  alignContent,
  background,
  border,
  color,
  flexbox,
  height,
  justifyContent,
  layout,
  position,
  space,
  typography
} from "styled-system";

import {
  PlusOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  InfoCircleOutlined
} from "@ant-design/icons";

// Low level building blocks
// Use them for their maximum flexibility

// Box: a general purpose container with styles-as-props
export const Box = styled("div")(
  background,
  border,
  color,
  flexbox,
  layout,
  position,
  space,
  typography,
  height
);

// FlexRow: a flex box that goes horizontally, similar to Antd's Row but supports styles-as-props
export const FlexRow = styled(Box)`
  display: flex;
  flex-direction: row;
`;
export const FlexCol = styled(Box)`
  display: flex;
  flex-direction: column;
`;
export const FlexRight = styled(FlexRow)`
  justify-content: right;
  align-items: center;
`;

// Center: a Box that centers its content
export const Center = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Left = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: left;
`;


// Title: Antd Typography with styles-as-props
export const Title = styled(Typography.Title)(
  typography,
  position,
  color,
  space
);

// Text: Antd Text with styles-as-props
export const Text = styled(Typography.Text)(typography, position, color, space);

// Spinner: a large spinner centered on page, mainly used for top level navigation
export const Spinner = () => (
  <Center position="absolute" top={0} left={40} right={0} bottom={0}>
    <Spin size="large" />
  </Center>
);

// StyledButton: an Antd button with styles-as-props
export const StyledButton = styled(Button)(layout, position, space);

// PlusButton: a primary button with an icon (default to +) and title
export const PlusButton = ({ icon, title, ...props }) => (
  <StyledButton type="primary" icon={icon ? icon : <PlusOutlined />} {...props}>
    {title}
  </StyledButton>
);

// Infotip: a wrapper component that adds a circle "i" icon and show tooltip on hover
export const Infotip = ({ t: text, tt: tooltip }) => {
  return tooltip ? (
    <AntTooltip title={tooltip} placement="top">
      <>
        {text}
        <InfoCircleOutlined
          css={css`
            margin-left: 6px;
            & svg {
              fill: rgba(0, 0, 0, 0.45);
              width: 12px;
              height: 12px;
              vertical-align: 0;
            }
          `}
        />
      </>
    </AntTooltip>
  ) : (
    <>{text}</>
  );
};
