import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Collapse, Row, Col, Divider, Table } from "antd";
import { Box, Spinner, Title, Text, Center } from "../common";
import { CardContainer } from "../common/CardContainer";
import { formatQty, formatMoney } from "../../components/common/table";
import { TabContainer } from "../common/page";
import { itemKeys as l } from "../../components/menus/menuKeys.constants";
import { PlusCircleTwoTone, MinusCircleTwoTone } from "@ant-design/icons";
import { ChargeKVpairs } from "../../constants/globalConstants";
import "./style.scss";

// const header = css({ backgroundColor: 'blue' });

const PlanCard = ({ planDetail, diffMonth }) => {
  const [expandedRows, setExpandedRows] = useState([]);
  let {
    plan_name: name,
    plan_description: desciption,
    currency_code: currencyCode,
    currency_symbol: currencySymbol,
    charges,
    meter_costs: meterCosts,
    plan_id: id
  } = planDetail;
  if (!desciption) {
    desciption = "";
  }
  if (!meterCosts) meterCosts = [];
  const firstMeterCosts = meterCosts.filter(
    (meterCost) => meterCost.cost_breakup !== undefined
  );
  const secondMeterCosts = meterCosts.filter(
    (meterCost) => meterCost.cost_breakup === undefined
  );
  meterCosts = firstMeterCosts.concat(secondMeterCosts);
  meterCosts = meterCosts.map((meterCost, idx) => ({
    ...meterCost,
    key: idx
  }));

  const totalCost = formatMoney(currencyCode, charges);
  const MonthlyCost = formatMoney(currencyCode, charges / diffMonth);

  const columns = [
    {
      title: "Billable Metric Name",
      dataIndex: "meter_name",
      width: "28%"
    },
    {
      title: "Pricing Type",
      dataIndex: "pricing_type",
      width: "28%",
      render: (_, record) => ChargeKVpairs[record.pricing_type]
    },
    {
      title: "Metered Usage (Qty)",
      dataIndex: "meter_usage",
      width: "22%",
      render: (_, record) => formatQty(record.meter_usage)
    },
    {
      title: "Usage Charges",
      dataIndex: "charges",
      width: "15%",
      render: (_, record) => formatMoney(currencyCode, record.charges)
    }
  ];

  const subColumns = [
    {
      title: "Name",
      dataIndex: "name",
      align: "center",
      width: "30%"
    },
    {
      title: "Individual Meter Usage",
      dataIndex: "meter_usage",
      align: "center",
      width: "30%",
      render: (_, record) => formatQty(record.meter_usage)
    },
    {
      title: "Individual Usage Charges",
      dataIndex: "charges",
      align: "center",
      width: "40%",
      render: (_, record) => formatMoney(currencyCode, record.charges)
    }
  ];

  const handleRowExpand = (expanded, record) => {
    let tempExpandedRows = expandedRows;
    tempExpandedRows = tempExpandedRows.includes(record.key)
      ? tempExpandedRows.filter((key) => record.key !== key)
      : [...tempExpandedRows, record.key];
    setExpandedRows(tempExpandedRows);
  };

  const showOriginalTable = (e) => {
    setExpandedRows([]);
  };

  return (
    <div onClick={(e) => showOriginalTable(e)}>
      <CardContainer>
        <TabContainer>
          <Title
            level={4}
            className="plan-title"
            style={{ marginRight: 30 }}
            strong
          >
            {name}
          </Title>
          <Link to={l.planDetail.getRoute({id: id})}><font size="2"  color="#00B0F0"> View Plan</font></Link>
          <Row>
            <Col span={19}>
              <Title level={5}>{desciption}</Title>
              <>
                {meterCosts.length !== 0 && (
                  <>
                    <Divider />
                    <Row style={{ marginTop: 24 }}>
                      <Col span={24}>
                        <Title level={5} strong>
                          Metered Costs
                        </Title>
                        <Table
                          dataSource={meterCosts}
                          columns={columns}
                          pagination={false}
                          expandable={{
                            expandRowByClick: true,
                            expandedRowRender: (record) => {
                              if (record.cost_breakup) {
                                return (
                                  <div className="subTable">
                                    <Table
                                      dataSource={record.cost_breakup}
                                      columns={subColumns}
                                      pagination={false}
                                      rowKey="name"
                                    />
                                  </div>
                                );
                              }
                            },
                            rowExpandable: (record) => record.cost_breakup,
                            expandIcon: ({
                              expandable,
                              expanded,
                              onExpand,
                              record
                            }) => {
                              if (!expandable) return;
                              if (expanded) {
                                return (
                                  <MinusCircleTwoTone
                                    onClick={(e) => {
                                      onExpand(record, e);
                                    }}
                                  />
                                );
                              } else {
                                return (
                                  <PlusCircleTwoTone
                                    onClick={(e) => {
                                      onExpand(record, e);
                                    }}
                                  />
                                );
                              }
                            },
                            expandedRowKeys: expandedRows,
                            onExpand: (expanded, record) =>
                              handleRowExpand(expanded, record)
                          }}
                        />
                      </Col>
                    </Row>
                  </>
                )}
              </>
            </Col>
            <Col span={1}>
              <Divider type="vertical" style={{ height: "100%" }} />
            </Col>

            <Col span={4}>
              <Title level={5} strong>Total Usage Revenue</Title>
              <Title level={3} className="cost-text" strong>
                {totalCost}
              </Title>
              <Title level={5} style={{ marginTop: 48 }} strong>
               Average Monthly Revenue
              </Title>
              <Title level={3} className="cost-text">
                {MonthlyCost}
              </Title>
            </Col>
          </Row>
        </TabContainer>
      </CardContainer>
    </div>
  );
};

const PricingResults = ({ planDetails, loading, diffMonth }) => {
  return (
    <>
      {loading ? (
        <Box height="50vh">
          <Spinner />
        </Box>
      ) : (
        <>
          {planDetails.map((planDetail) => (
            <PlanCard
              planDetail={planDetail}
              diffMonth={diffMonth}
              key={planDetail.plan_id}
            ></PlanCard>
          ))}
        </>
      )}
    </>
  );
};

export default PricingResults;
