import React, { useMemo, useState } from "react";
import { Button, message, Modal } from "antd";
import { Form, FormRow } from "../common/form";
import "./style.scss";

import { FormItem } from "../paliq/input/FormItem";
import { postUserInvite } from "../../services/apiConfig";
import { postRequest } from "../../services";
import { AuthContext } from "../../App";

const processRolesForSelect = (roles) => {
  return roles.map(r => {
    return { label: r.name, value: r.id };
  });
};

const InviteTeamModal = ({ show, setShow }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const { state: { roles} } = React.useContext(AuthContext);

  const rolesData = useMemo(() => processRolesForSelect(roles), [roles]);
  const onFinish = async (values) => {
    try {
      setLoading(true);
      const response = await postRequest(postUserInvite(), values);
      console.log(response);

      if (response['error']) {
        message.error(response['error']['message']);
        return;
      }
      if (response['data']) {
        message.success(`Sent invitation to ${values['name']}`);
        form.resetFields();
        setShow(false);
        return;
      }
      message.error("An error occurred while processing your request. Please try again.");
    } catch (err) {
      console.log(err);
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal
      title="Invite Team Member"
      centered
      open={show}
      onOk={onFinish}
      onCancel={() => setShow(false)}
      footer={[
        <Button onClick={() => setShow(false)}>I'll do this later</Button>,
        <Button
          loading={loading}
          type="primary"
          key="submit"
          htmlType="submit"
          onClick={() => {
            form.validateFields().then(onFinish);
          }}
        >
          Send Invite
        </Button>
      ]}
    >
      <Form layout="vertical" form={form} name="invite"
            scrollToFirstError
            requiredMark={false}
      >
        <FormRow mb={16}>
          <FormItem
            name="name"
            placeholder="Enter the name"
            type="text"
            rules={[{ required: true, message: 'Name is required' }]}
            label="Name"
          />
        </FormRow>
        <FormRow mb={16}>
          <FormItem
            name="email"
            placeholder="Enter the email address"
            type="email"
            label="Email"
            rules={[
              { required: true, message: 'Email is required' },
              { type: "email", message: "This is not a valid email address" }
            ]}
          />
        </FormRow>
        <FormRow mb={16}>
          <FormItem
            name="role_id"
            type="selectkvp"
            placeholder="Select Role"
            data={rolesData}
            label="Role"
            rules={[{ required: true, message: "Role is required" }]}
          />
        </FormRow>
      </Form>
    </Modal>
  );
};

export default InviteTeamModal;
