import React from "react";
import { useNavigate } from "react-router-dom";
import { Box, Center, PlusButton, Text, Title } from "../../common";

const EmptyPage = ({ heading, subHeading, buttonText, url }) => {
  const navigate = useNavigate();
  // the block is located roughly 20% above the center
  return (
    <Center height="100vh">
      <Center mt="-20vh" width="32rem">
        <Title level={4}>{heading}</Title>
        <Box mb={24} lineHeight="16px">
          <Text color="textSubdued">{subHeading}</Text>
        </Box>
        <PlusButton
          size="large"
          title={buttonText}
          onClick={() => navigate(url)}
        />
      </Center>
    </Center>
  );
};

export default EmptyPage;
