import { ArrowUpOutlined, ReloadOutlined } from "@ant-design/icons";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { UPLOAD_USAGE_LABELS } from "../../../constants/labels";
import { PlusButton, Spinner } from "../../common";
import { formatDateTime, withTooltip } from "../../common/table";
import { StatusTag } from "../../common/tags";
import ListTable from "../../ListTable";

import { getUploadJobStatus } from "../usage_helpers";

import UploadUsageModal from "./uploadUsageModal";

const processDataToShow = (res) => {
  return res.result.job_statuses;
};

const UploadUsageList = (props) => {
  const navigate = useNavigate();
  const [modalVisible, setModalVisible] = useState(false);
  const queryClient = useQueryClient();
  const {
    isLoading,
    error,
    data: dataSource,
    isFetching
  } = useQuery(['jobstatus'], () => getUploadJobStatus(), {staleTime: Infinity, select: processDataToShow});

  useEffect(() => {
    if (!modalVisible) {
      // refresh the list after the modal is dismissed
      queryClient.refetchQueries(['jobstatus'])
    }
  }, [modalVisible])

  if (isLoading) return <Spinner/>;
  if (error) return "An error has occurred: " + error.message;

  // console.log("uploadJobStatus data=", dataSource);

  const columns = [
    {
      title: "File",
      dataIndex: "file",
    },
    {
      title: "Import Job",
      dataIndex: "job_id",
    },
    {
      title: "Upload Time",
      dataIndex: "startedAt"
    },
    {
      title: "Last activity Time",
      dataIndex: "last_updated",
      render: (_, record) => formatDateTime(record?.last_updated)
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text) => (<StatusTag status={text} />)
    }
  ];

  const onUploadButtonClick = () => {
    setVisible(true);
  };

  const onRefreshButtonClick = () => {
    queryClient.refetchQueries(['jobstatus']);
  }

  const onSearch = (value) => console.log(value);
  const onChange = (value) => console.log(value);
  const setVisible = (value) => {
    console.log("Setting visibility to " + value);
    setModalVisible(value);
  };

  return (
    <>
      <ListTable
        title={UPLOAD_USAGE_LABELS.PAGE_TITLE}
        titleButtons={[
          <PlusButton key="1" type="default" size="large" icon={<ReloadOutlined />} loading={isFetching} title="Refresh" onClick={onRefreshButtonClick} mr={3} />,
          <PlusButton key="2" size="large" icon={<ArrowUpOutlined />} title="Upload CSV Files" onClick={onUploadButtonClick} />
        ]}
        data={dataSource}
        columns={withTooltip(columns)}
        onSearch={onSearch}
        onChange={onChange}
        pagination="none"
      />
      <UploadUsageModal
        visible={modalVisible}
        setVisible={setModalVisible}
      />
    </>
  );
};

export default UploadUsageList;
