import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { postRequest, putRequest } from "../../services";
import { APICONFIG } from "../../services/apiConfig";
import { menuKeys as m } from "../menus/menuKeys.constants";
import { Form, FormRow } from "../common/form";
import { Box, FlexRight } from "../common";
import { CardContainer } from "../common/CardContainer";
import { CodePageTitle } from "../common/page";
import { FormItem } from "../paliq/input/FormItem";
import { PageTitleStatus } from "../common/tags";
import { Button, message } from "antd";
import { AuthContext } from "../../App";



const PaymentMethodForm = (props) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [heading, setHeading] = React.useState("");
  const { state: {counts} } = React.useContext(AuthContext);


  const { data } = props;
  const { uid, status } = data;

  useEffect(() => setHeading(data['account_name'] ?? ''), [data]);
  useEffect(() => {
    form.setFieldsValue(data);
  }, [data]);

  const onFinish = async () => {
    try {
      const row = await form.validateFields();
      let response;
      if (uid) {
        response = await putRequest(`${APICONFIG.getPaymentMethodsUrl}/${uid}`, row)
      } else {
        response = await postRequest(`${APICONFIG.getPaymentMethodsUrl}`, row)
      }
      if (response['data']) {
        message.success(`Payment method is updated`);
        await queryClient.invalidateQueries(['payment_methods']);
        navigate(m.paymentMethods.route);
        return;
      }
      message.error("An error occurred while processing your request. Please try again.");
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const onFinishFailed = async (values) => {
    console.log("Form onFinishFailed = ", values);
  }

  const onValuesChange = (change, all) => {
    setHeading(all['account_name']);
  }

  return (
    <>
      <CardContainer>
        <CodePageTitle title={uid ? heading : "Create Payment Method"}  buttons={<PageTitleStatus status={uid ? status: 'new'} />}/>
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={onValuesChange}
        >
          <Box my={12}>
            <FormRow mb={24} spans={[8, 8, 8]}>
              <FormItem
                name="account_name"
                label="Account Name"
                tooltip="Unique name to identify the account"
                rules={[
                  {
                    required: true,
                    message: "Please enter the account name"
                  }
                ]}
                placeholder="Enter account name"
                type="text"
              />
            </FormRow>
            <FormRow mb={24} spans={[8, 8, 8]}>
              <FormItem
                name="payment_method"
                label="Payment Type"
                type="selectkvp"
                data={[
                  { label: "Credit Card", value: "cc" },
                  { label: "Bank", value: "bank" },
                  { label: "Stripe", value: "stripe" },
                ]}
                rules={[
                  {
                    required: true,
                    message: "Please enter the payment type"
                  }
                ]}
              />
            </FormRow>
            <FormRow mb={24} spans={[8, 8, 8]}>
              <FormItem
                name="description"
                label="Description"
                type="text"
              />
            </FormRow>
          </Box>

          <FlexRight>
            <Box mr={2}>
              <Button
                size="large"
                onClick={() => navigate(m.paymentMethods.route)}
              >
                Cancel
              </Button>
            </Box>
            <Button size="large" type="primary" htmlType="submit">
              Submit
            </Button>
          </FlexRight>
        </Form>
      </CardContainer>
    </>
  );
};

export default PaymentMethodForm;
