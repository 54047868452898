import { useEffect, useState } from "react";
import { Form, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { PlusOutlined } from "@ant-design/icons";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  getCustomerCredits,
  getCustomerById,
  addNewCreditsToCustomer
} from "../../../../services/customerFormAction";
import { ButtonRow } from "../../../common/form";
import {Box, FlexRight, Spinner} from "../../../common";

import CustomerCreditDetails from "./CustomerCreditDetails";
import { processCustomerDataForShow } from "../../customer-helper";
import {
  getFieldsValueFromCreditsList,
  getPayloadsFromTouchedFormValues,
  onAddCreditSuccess,
  onNewCreditsError
} from "../../credits/credits-helpers";
import { creditJSON } from "../../../../constants/blankJSONs";
import React from "react";

const processCreditsDataForView = (res) => {
  console.log(res);
  return res?.credits ?? [];
};

const CustomerCredit = (props) => {
  const { id, action } = props; // id is uid of the customer (if it is an edit thing). action is either EDIT or null so far.
  const [form] = Form.useForm(); // will capture all form values of even sub-forms via FormInstance in child components.
  const [credits, setCredits] = useState([]);

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const onSettled = () => {
    queryClient.invalidateQueries(["allcredits", id]);
  };

 /* const { data: customerData } = useQuery(
    ["allcustomers", id],
    () => getCustomerById(id),
    { staleTime: Infinity, select: processCustomerDataForShow }
  ); // customerData is the JSON for this service coming in from the server.
*/

  const {
    isLoading,
    data: creditsData
  } = useQuery(
    ["allcredits", id],
    () => getCustomerCredits(id), /*{
      if (id) getCustomerCredits(id);
    },*/
    {
      staleTime: Infinity,
      select: processCreditsDataForView
    }
  ); // serviceData is the JSON for this service coming in from the server.


  const newCustomerCreditMutation = useMutation(addNewCreditsToCustomer, {
    onSuccess: onAddCreditSuccess,
    onError: onNewCreditsError,
    onSettled: onSettled
  });

  useEffect(() => {
    if (creditsData) {
      setCredits(creditsData ?? []);
      const vals = getFieldsValueFromCreditsList(creditsData);
      console.log("field Vals = ", vals);
      form.setFieldsValue(vals);
    }
    return () => {};
  }, [creditsData]);

  const addCredit = () => {
    const newRow = creditJSON();
    setCredits([...credits, newRow]);
    //const vals = getFieldsValueFromCreditsList(credits);
    //form.setFieldsValue(vals);
  };

  const onFinish = async (values) => {
    console.log("Form Values = ", values);
    console.log("credits = ", credits);
    const editedValues = {};
    Object.keys(values).map((k) => {
      if (form.isFieldTouched(k)) {
        editedValues[k] = values[k];
      }
    });
    const [addPayload, updatePayload] = getPayloadsFromTouchedFormValues(
      editedValues,
      credits
    );
    console.log("addPayload = ", addPayload);
    console.log("updatePayload = ", updatePayload);
    const combinedPayload = [...updatePayload, ...addPayload]; // sequence
    if (combinedPayload.length > 0) {
      await newCustomerCreditMutation.mutateAsync({
        cust_id: id,
        data: combinedPayload
      });
    }
    navigate("/customers");
    return;
  };

  const onFinishFailed = (values, errorFields, outOfDate) => {
    console.log("onFinishFailed");
    console.log("Form Values = ", values);
    console.log("Form errors = ", errorFields);
    console.log("Form outofdate = ", outOfDate);
    // Call when service Fails
  };

  return isLoading ? (
    <Box height="70vh">
      <Spinner />
    </Box>
  ) : (
    <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={addCredit}
        style={{ marginBottom: "16px"}}
        disabled={!!!id}
      >
        Add New Credit
      </Button>
      {/* <SectionTitle
        disabled={false}
        buttonText="Add New Credit"
        onButtonClick={() => addCredit()}
      /> */}
      {credits && credits.length > 0 && (
        <CustomerCreditDetails
          credits={credits}
          setCredits={setCredits}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        />
      )}
      <FlexRight mt={4}>
        <Box mr={2}>
          <Button
            size="large"
            onClick={() => {
              navigate("/customers");
            }}
          >
            Cancel
          </Button>
        </Box>
        <Button size="large" type="primary" htmlType="submit">
          Submit
        </Button>
      </FlexRight>

    </Form>
  );
};

export default CustomerCredit;
