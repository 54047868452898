import pickBy from "lodash/pickBy";
import { STORAGE_KEY } from "../constants";

export const getSanitizedValues = (values) =>
  pickBy(values, (value) => {
    const expression = !(
      value === 0 ||
      value === "" ||
      value === undefined ||
      value === null ||
      Number.isNaN(value) ||
      value?.length === 0
    );
    return expression;
  });

const setLocalStorage = (userData) => {
  userData.map((data) => {
    localStorage.setItem(data.key, data.value);
  });
};

export const getServiceId = () => localStorage.getItem(STORAGE_KEY.SERVICE_ID);

export const getService = () => localStorage.getItem(STORAGE_KEY.SERVICE);

export const getAccessToken = () =>
  localStorage.getItem(STORAGE_KEY.ACCESS_TOKEN);

export const setAccessTokeninLS = (token) =>
  localStorage.setItem(STORAGE_KEY.ACCESS_TOKEN, token);

export const getRefreshTokenFromLS = () =>
  localStorage.getItem(STORAGE_KEY.REFRESH_TOKEN);

export const getIdTokenfromLS = () =>
  localStorage.getItem(STORAGE_KEY.ID_TOKEN);

export const getUserInfoFromLS = () =>
  localStorage.getItem(STORAGE_KEY.USER_INFO);

export const setUserInfoInLS = (user) =>
  localStorage.setItem(STORAGE_KEY.USER_INFO, user);

export const setIdTokeninLS = (token) =>
  localStorage.setItem(STORAGE_KEY.ID_TOKEN, token);

export const getUserEmailfromLS = () =>
  localStorage.getItem(STORAGE_KEY.USER_EMAIL);

export const setLoginDetailsinLS = (apiResponse, userEmail = null) => {
  const userData = [
    {
      key: STORAGE_KEY.ACCESS_TOKEN,
      value: apiResponse?.AuthenticationResult.AccessToken
    },
    {
      key: STORAGE_KEY.REFRESH_TOKEN,
      value: apiResponse?.AuthenticationResult.RefreshToken
    },
    {
      key: STORAGE_KEY.ID_TOKEN,
      value: apiResponse?.AuthenticationResult.IdToken
    },
    {
      key: STORAGE_KEY.TOKEN_TYPE,
      value: apiResponse?.AuthenticationResult.TokenType
    },
    {
      key: STORAGE_KEY.TOKEN_TIMESTAMP,
      value: apiResponse?.ResponseMetadata?.HTTPHeaders?.date
      // date is currently of type "Sat, 27 Aug 2022 14:35:24 GMT"
    },
    {
      key: STORAGE_KEY.USER_EMAIL,
      value: userEmail
    }
  ];
  setLocalStorage(userData);
};

export const setRoleinLS = (role) => {
  localStorage.setItem(STORAGE_KEY.ROLE, JSON.stringify(role));
};

//export const getUserRolefromLS = () => JSON.parse(localStorage.getItem("role"));

export const getUserRolefromLS = () =>
  JSON.parse(localStorage.getItem(STORAGE_KEY.ROLE));

export function isLoggedInAsPerLS() {
  return !!localStorage.getItem(STORAGE_KEY.ID_TOKEN);
}

export function isArrayWithLength(arr) {
  return Array.isArray(arr) && arr.length;
}

export function classes() {
  return [...arguments].join(" ");
}

export async function copyTextToClipboard(copyText) {
  console.log("api_key in after passing to clipboard = ", copyText);
  if ("clipboard" in navigator) {
    return await navigator.clipboard.writeText(copyText);
  } else {
    return document.execCommand("copy", true, copyText);
  }
}

export const logoutFromLS = () => {
  localStorage.removeItem(STORAGE_KEY.ACCESS_TOKEN);
  localStorage.removeItem(STORAGE_KEY.ID_TOKEN);
  localStorage.removeItem(STORAGE_KEY.TOKEN_TIMESTAMP);
  localStorage.removeItem(STORAGE_KEY.REFRESH_TOKEN);
  localStorage.removeItem(STORAGE_KEY.TOKEN_TYPE);
  localStorage.removeItem(STORAGE_KEY.USER_EMAIL);
  localStorage.removeItem(STORAGE_KEY.ROLE);
  localStorage.removeItem(STORAGE_KEY.USER_INFO);
};

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const capitalizeString = (inputString) => {
  return inputString.toString().toUpperCase();
}