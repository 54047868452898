import React from "react";
import { useQuery } from "@tanstack/react-query";
import { getRequest } from "../../services";
import { APICONFIG } from "../../services/apiConfig";
import { Spinner } from "../common";
import { useParams } from "react-router";
import WorkflowForm from "./WorkflowForm";

const WorkflowDetail = (props) => {
  const { id: uid } = useParams();
  const {isLoading, error, data} = useQuery({
    queryKey: ['workflows', uid],
    queryFn: () => getRequest(`${APICONFIG.getWorkflowsUrl}/${uid}`).then(r => r['data'])
  });

  if (isLoading) return <Spinner />;

  if (error) return "An error has occurred: " + error.message;

  return <WorkflowForm data={data}/>;
};

export default WorkflowDetail;
