//Here we will have stubs for inserts
export const meterJSON = () => { // blank meterJSONs will have a 20 char uid for key purposes. It can be removed later when sending to server.
  return {
    "uid": Array.from(Array(20), () => Math.floor(Math.random() * 36).toString(36)).join(''),
    "name": "",
    "meter_key": null,
    "meter_group": null,
    "status": null,
    "aggregation": null,
    "aggregate_on": null,
    "group_by": [],
    "charge_type": null,
    "charge_interval_month": 1,
    "charge_json": []
  };
};



export const creditJSON = () => {
  return {
    "uid": Array.from(Array(20), () => Math.floor(Math.random() * 36).toString(36)).join(''),
    "credit_name": null,
    "credit_amount": null,
    "credit_bought":null,
    "start_date": null,
    "end_date": null
  };
}