import React from "react";
import { useNavigate, Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { getAllUsageData } from "../../components/usage/usage_helpers";
import ListTable from "../../components/ListTable";
import { Spinner } from "../../components/common";
import {
  withTooltip,
  formatDate,
  concatStrings,
  formatQty,
  formatMoney
} from "../../components/common/table";

const processDataToShow = (res) => {
  return res.result.customer_usages;
};

const UsageList = (props) => {
  const navigate = useNavigate();
  const {
    isLoading,
    error,
    data: dataSource,
    isFetching
  } = useQuery(["allusagedata"], () => getAllUsageData(), {
    staleTime: Infinity,
    select: processDataToShow
  });

  if (isFetching) return <Spinner />;

  if (error) return "An error has occurred: " + error.message;

  const columns = [
    // column headers for the table
    {
      title: "Customer Name",
      dataIndex: "customer_name",
      width: "30%",
      render: (_, record) => (
        <Link
          to={`/customers/usage/${record?.external_customer_id}`}
          className="link-color"
        >
          {record?.customer_name}
        </Link>
      )
    },
    {
      title: "External Customer Id",
      dataIndex: "external_customer_id",
      width: "25%"
    },
    {
      title: "As of Date",
      dataIndex: "last_update_date",
      width: "25%",
      render: (_, record) => formatDate(record.last_update_date)
    },

    {
      title: "Total Usage Cost",
      dataIndex: "charges",
      width: "25%",
      render: (_, record) => formatMoney(record.currency_code, record.charges)
    }
  ];

  return (
    <ListTable
      title="Customer Usages"
      data={dataSource}
      columns={withTooltip(columns)}
      rowKey={(record) => record.external_customer_id}
      pagination="pagination"
    />
  );
};

export default UsageList;
