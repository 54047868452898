import { ThemeProvider } from "@emotion/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import React, { useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import WithAxiosInterceptor from "./context/withAxiosInterceptor";
import AppRoutes from "./routes";
import { GlobalDebug } from "./global-debug";
import axiosClient from "./services/base.js";
import {
  getIdTokenfromLS,
  getUserInfoFromLS,
  logoutFromLS,
  setUserInfoInLS
} from "./utils/helper.js";
import { lightTheme } from "./themes/emotion.esm";
import { getUserInfo } from "./pages/login/postLogin";
import { ConfigProvider } from "antd";

export const AuthContext = React.createContext(); // added this

export const SideNavContext = React.createContext();

const queryClient = new QueryClient();

const initialState = {
  isAuthenticated: false,
  token: undefined,
  user: undefined,
  roles: {},
  counts: undefined
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      axiosClient.defaults.headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: action.payload.token // changed to see if LS set and read is causing the issue of old token
      };
      return {
        ...state,
        isAuthenticated: true,
        token: action.payload.token
      };
    case "LOGOUT":
      queryClient.invalidateQueries(); // doing it here for now
      logoutFromLS();
      return {
        ...state,
        isAuthenticated: false,
        token: undefined,
        user: undefined,
        counts: undefined
      };
    case "RETRIEVE_TOKEN":
      return {
        ...state,
        token: action.payload.token
      };
    case "USER_DATA":
      let { roles, user } = action.payload;
      let roleMap = roles.reduce((m, o) => {
        m[o.id] = o;
        return m;
      }, {});
      let userRole = roleMap[user.role_id];
      user["role"] = userRole.name;
      user["roleId"] = userRole.id;
      user["roleCode"] = userRole.code;
      return {
        ...state,
        user: user,
        counts: action.payload.counts,
        roles: action.payload.roles,
        rolesMap: roleMap
      };
    default:
      return state;
  }
};

function App() {
  const [state, dispatch] = React.useReducer(reducer, initialState, undefined);
  const [sideNavOpen, setSideNavOpen] = useState(true);

  useEffect(() => {
    const token = getIdTokenfromLS() || null;
    if (token) {
      dispatch({
        type: "LOGIN",
        payload: {
          token: token
        }
      });
      const u = getUserInfoFromLS();

      if (u) {
        try {
          let p = JSON.parse(atob(u));

          dispatch({
            type: "USER_DATA",
            payload: p
          });
        } catch (e) {}
      }
      getUserInfo(dispatch);
    }
  }, []);

  useEffect(() => {
    process.env.NODE_ENV === "production" && GlobalDebug(false);
  }, []);

  return (
    <ThemeProvider theme={lightTheme}>
      <AuthContext.Provider
        value={{
          state,
          dispatch
        }}
      >
        <WithAxiosInterceptor>
          <QueryClientProvider client={queryClient}>
            <BrowserRouter>
              <SideNavContext.Provider value={{ sideNavOpen, setSideNavOpen }}>
                <AppRoutes />
              </SideNavContext.Provider>
            </BrowserRouter>
            <ReactQueryDevtools position="bottom-right" />
          </QueryClientProvider>
        </WithAxiosInterceptor>
      </AuthContext.Provider>
    </ThemeProvider>
  );
}

export default App;
