import React, { useState, useEffect } from "react";
import {Table, Space, Row, Col, Typography, Form, Select} from "antd";
import { useQuery } from "@tanstack/react-query";
import { PlusCircleTwoTone, MinusCircleTwoTone } from "@ant-design/icons";
import {
  getUsageDataForCustomer,
  flattenDataForTable,
  setDataForCharts
} from "../../../usage/usage_helpers";
import { Spinner, Box } from "../../../common";
import {
  withTooltip,
  formatDate,
  formatQty,
  formatMoney, concatStrings
} from "../../../common/table";
import {SectionTitle, SubTitle, TabContainer} from "../../../common/page.js";
import { BarChart } from "../../../charts/barchart/BarChart";
import { FormItem } from "../../../paliq/input/FormItem";
import {CardBody, CardContainer} from "../../../common/CardContainer.jsx";
import {FormRow} from "../../../common/form";
import {AttributesPanel} from "../../../common/InfoPanel";

const { Title } = Typography;

// for cascader
const moOpts = [1, 2, 3, 4, 5, 6];
const options = moOpts.map((m) => {
  return { label: m, value: m };
});

const processDataToShow = (res) => {
  return res.result;
};

const labels = [];
const emptyData = {
  labels,
  datasets: [
    {
      label: "No Data",
      data: []
    }
  ]
};

const CustomerUsage = ({ accountData, id }) => {
  const [servicesUsage, setServicesUsage] = useState([]);
  const [monthsWindow, setMonthsWindow] = useState(6);
  const [chartDispData, setChartUsage] = useState(emptyData);
  const [isChartLoading, setChartLoading] = useState(true);
  const [expandedRows, setExpandedRows] = useState([]);
  const [form] = Form.useForm();

  const {
    isLoading,
    error,
    data: dataSource,
    isFetching
  } = useQuery(
    ["allusagedata", id, monthsWindow],
    () => getUsageDataForCustomer(id, monthsWindow),
    { staleTime: Infinity, select: processDataToShow }
  );

  useEffect(() => {
    const getData = () => {
      setServicesUsage(
        flattenDataForTable(
          dataSource?.services ? dataSource.services : [],
          dataSource?.currency_code
        )
      );
      setChartUsage(setDataForCharts(dataSource?.services));
      setChartLoading(false);
      //refetch();
    };
    getData();
  }, [monthsWindow, id, isLoading]);

  useEffect(() => {
    const initialExpandedRows = [];
    for (const record of servicesUsage) {
      if (record.grouped_allowances) {
        initialExpandedRows.push(getRecordKey(record));
      }
    }
    setExpandedRows(initialExpandedRows);
  }, [servicesUsage]);

  if (isFetching || isLoading || isChartLoading) {
    return (
      <Box height="70vh">
        <Spinner />
      </Box>
    );
  }

  if (error || !dataSource)
    return "An error has occurred: " + (error?.message ?? "");

  const onWindowChange = (value) => {
    form.setFieldValue("duration", value);
    setMonthsWindow(value);
  };

  const getRecordKey = (record) =>
    `${record.start_at}/${record.end_at}/${record.service_name}/${record.meter_name}`;

  const columns = [
    {
      title: "Start Date",
      dataIndex: "start_at",
      width: "10%",
      render: (_, record) => formatDate(record.start_at)
    },
    {
      title: "End Date",
      dataIndex: "end_at",
      width: "10%",
      render: (_, record) => formatDate(record.end_at)
    },
    {
      title: "Service Name",
      dataIndex: "service_name",
      width: "15%"
    },
    {
      title: "Billing Metric",
      dataIndex: "meter_name",
      width: "10%"
    },
    {
      title: "Usage Qty",
      dataIndex: "usage_qty",
      width: "10%",
      render: (_, record) => formatQty(record.usage_qty)
    },
    {
      title: "Pre Discount Total",
      dataIndex: "pre_discount_total",
      width: "10%",
      render: (_, record) =>
        formatMoney(dataSource.currency_code, record.pre_discount_total)
    },
    {
      title: "Discount",
      dataIndex: "discount",
      width: "10%",
      render: (_, record) =>
        formatMoney(dataSource.currency_code, record.discount)
    },
    {
      title: "Total",
      dataIndex: "total",
      width: "10%",
      render: (_, record) => formatMoney(dataSource.currency_code, record.total)
    },
    {
      title: "Allowance",
      dataIndex: "allowance",
      width: "10%"
    }
  ];

  const subColumns = [
    {
      title: "Group",
      dataIndex: "group",
      align: "center",
      width: "30%"
    },
    {
      title: "Quantity",
      dataIndex: "value",
      align: "center",
      width: "30%"
    }
  ];

  const handleRowExpand = (expanded, record) => {
    const recordKey = getRecordKey(record);
    let tempExpandedRows = expandedRows;
    tempExpandedRows = tempExpandedRows.includes(recordKey)
      ? tempExpandedRows.filter((key) => recordKey !== key)
      : [...tempExpandedRows, recordKey];
    setExpandedRows(tempExpandedRows);
  };

  return isFetching || isLoading || isChartLoading ? (
    <Box height="70vh">
      <Spinner />
    </Box>
  ) : (
    <>
      <Box my={3}>
        <Space direction="vertical" size="middle" style={{ display: "flex" }}>
          {/*<SectionTitle title={"Usage Summary"} ></SectionTitle>*/}
          <Form layout="horizontal" autoComplete="off" form={form}>
            <FormRow my={"3px"} spans={[8]}>
              <FormItem
                name="duration"
                size="medium"
                placeholder="Select no of months"
                label="Months"
                data={options}
                onChange={onWindowChange}
                style={{  fontSize: "14px"}}
                type={"selectkvp"}
                tooltip="Choose the number of months for which you want to see the usage data"
              >
              </FormItem>
            </FormRow>

            <FormRow my={"24px"} spans={[ 12,12]}>
              <AttributesPanel
                bordered={false}
                cols={3}
                attrs={[
                  ["Usage Cost",formatMoney(dataSource.currency_code, dataSource.charges)],
                  ["Discount", formatMoney(dataSource.currency_code, dataSource.discount)],
                  ["Total Cost",formatMoney(dataSource.currency_code, dataSource.charges - dataSource.discount)]

                ]}>
              </AttributesPanel>
              <AttributesPanel
                bordered={false}
                cols={3}
                attrs={[
                  ["Credits Bought",formatMoney(dataSource.currency_code, dataSource.credit_bought)],
                  ["Credits Used", formatMoney(dataSource.currency_code, dataSource.credit_bought - dataSource.credit_left)],
                  ["Credits Left",formatMoney(dataSource.currency_code, dataSource.credit_left)]
                ]}>
              </AttributesPanel>
            </FormRow>

          </Form>
        </Space>
      </Box>
      <TabContainer>
        <Space direction="vertical" size="middle" style={{ display: "flex" }}>

          {/*<CardBody>*/}
          <BarChart
            title= {concatStrings("Usage by services for the last " +  monthsWindow , " months")}
            chartData={
              chartDispData?.datasets.length !== 0 ? chartDispData : emptyData
            }
          />
          <SectionTitle title="Service Details" my={16} mx={10} />
          <Box mx={10}>
            <Table
              dataSource={servicesUsage}
              columns={withTooltip(columns)}
              rowKey={(record) => getRecordKey(record)}
              pagination="pagination"
              expandable={{
                expandRowByClick: true,
                expandedRowRender: (record) => {
                  console.log(record);
                  if (record.grouped_allowances) {
                    return (
                      <div className="subTable">
                        <Table
                          dataSource={record.grouped_allowances}
                          columns={subColumns}
                          pagination={false}
                          rowKey="name"
                        />
                      </div>
                    );
                  }
                },
                rowExpandable: (record) => record.grouped_allowances,
                expandIcon: ({ expandable, expanded, onExpand, record }) => {
                  if (!expandable) return;
                  if (expanded) {
                    return (
                      <MinusCircleTwoTone
                        onClick={(e) => {
                          onExpand(record, e);
                        }}
                      />
                    );
                  } else {
                    return (
                      <PlusCircleTwoTone
                        onClick={(e) => {
                          onExpand(record, e);
                        }}
                      />
                    );
                  }
                },
                expandedRowKeys: expandedRows,
                onExpand: (expanded, record) =>
                  handleRowExpand(expanded, record)
              }}
            />
          </Box>
          {/*</CardBody>*/}
        </Space>
      </TabContainer>
    </>
  );
};

export default CustomerUsage;
