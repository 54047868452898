/** @jsxImportSource @emotion/react */
import { css, Global } from "@emotion/react";
import { Layout, Menu, Dropdown, Space } from "antd";
import React, { memo, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import InviteTeamModal from "../teams/InviteTeamModal";
import { Menus } from "./Menus";
import { AuthContext } from "../../App";
import ChangePasswordModal from "../../pages/manageuser/ChangePasswordModal";
import LogoutModal from "../common/LogoutModal";
//import { ReactComponent as Logo } from "../../assets/img/paliq-white-logo.svg";
import { ReactComponent as Logo } from "../../assets/img/paliq-logo-login.svg";
import "../../assets/scss/_vars.scss";
import { DownOutlined } from "@ant-design/icons";
import MenuItem from "antd/lib/menu/MenuItem";
import Sidebar from "../common/Sidebar";
import "./styles.scss";

const { Header } = Layout;

const CustomNav = ({ setCollapsed }) => {
  const [modals, setModals] = useState({
    invite: false,
    password: false,
    logout: false
  });

  const {
    state: { user }
  } = React.useContext(AuthContext);

  const location = useLocation();
  const navigate = useNavigate();
  const [leftMenu, rightMenu] = Menus[user.roleCode];
  const [sideBarItems, setSideBarItems] = useState([]);
  const [selectedKey, setSelectedKey] = useState([]);
  const [selectedNavKey, setSelectedNavKey] = useState("");
  const [sidebarTitle, setSidebarTitle] = useState("");

  useEffect(() => {
    const arr = location.pathname.split("/");
    if (arr.length > 1) {
      makeSidebar("/" + arr[1]);
    }
  }, [location]);

  const makeSidebar = (path) => {
    const idx = findMenuItemIdxLeft(leftMenu, path);

    setCollapsed(true);

    if (idx !== -1) {
      setSideBarItems(leftMenu[idx].children);
      setSidebarTitle(leftMenu[idx].label);
      setSelectedNavKey(leftMenu[idx].route);
    } else {
      setCollapsed(false);
      setSelectedNavKey(path);
      setSideBarItems([]);
      setSidebarTitle(null);
    }
    setSelectedKey(path);
  };

  const findMenuItemIdxLeft = (menu, route, depth = 1) => {
    for (let i = 0; i < menu.length; i++) {
      if (menu[i].route == route) {
        if (depth !== 1) {
          return i;
        }
      }
      if (
        menu[i]?.children &&
        menu[i]?.children.length > 0 &&
        findMenuItemIdxLeft(menu[i]?.children, route, depth + 1) !== -1
      ) {
        return i;
      }
    }
    return -1;
  };

  const buildMenuItems = (menu) => {
    return menu.map((item) => {
      const { route, icon, label, type, style, children } = item;

      if (route === "userinfo") {
        return {
          key: route,
          disabled: true,
          style: {
            height: "35px",
            cursor: "default",
            lineHeight: "20px"
          },
          label: (
            <>
              <div
                style={{
                  fontSize: "small",
                  fontWeight: "bold",
                  color: "rgba(0, 0, 0, 0.85)"
                }}
              >
                {user.name}
              </div>
              <div
                style={{ fontSize: "x-small", color: "rgba(0, 0, 0, 0.85)" }}
              >
                {user.role}
              </div>
            </>
          )
        };
      }
      let menuItem = { key: route };
      if (type) {
        menuItem.type = type;
      } else if (children) {
        menuItem.label = label;
        menuItem.icon = icon;
        menuItem.children = buildMenuItems(children);
      } else {
        if (icon) {
          menuItem.icon = icon;
        }
        if (label) {
          menuItem.label = label;
        }
      }
      if (style) {
        menuItem.style = style;
        menuItem.label = label;
      }
      return menuItem;
    });
  };

  const setModalShow = (m, show) => {
    let n = { ...modals };
    n[m] = show;
    setModals(n);
  };

  const onMenuClick = ({ key }) => {
    if (findMenuItemIdxLeft(leftMenu, key) !== -1) {
      makeSidebar(key);
    }
    if (key === "password") {
      setModals({ ...modals, password: true });
    } else if (key === "logout") {
      setModals({ ...modals, logout: true });
    } else {
      navigate(key);
    }
  };

  return (
    <>
      <Header
        style={{
          position: "fixed",
          width: "100%",
          fontWeight: "bold",
          left: 0,
          top: 0,
          backgroundColor: "#daf4f1",
          borderBottom: "1px solid #f0f0f0",
          zIndex: 10
        }}
      >
        <Logo
          style={{
            position: "absolute",
            left: "20px",
            width: "80px",
            height: "50px"
          }}
        />
        <Menu
          mode="horizontal"
          className="paliq-top-left-menu"
          selectedKeys={[selectedNavKey]}
        >
          {leftMenu.map(({ label, icon, route, children }) => {
            return children && children.length > 1 ? (
              <MenuItem key={route} style={{ color: "#030303", padding: 0 }}>
                <Dropdown
                  menu={{
                    items: children.map((child) => {
                      return { ...child, key: child.route };
                    }),
                    selectable: true,
                    onClick: onMenuClick
                  }}
                  trigger={["click"]}
                  key={"/" + route}
                >
                  <Space
                    size={6}
                    style={{ paddingLeft: "16px", paddingRight: "16px" }}
                  >
                    {
                      <>
                        <>{icon}</>
                        <>{label}</>
                      </>
                    }
                    <DownOutlined style={{ fontSize: "12px", marginLeft: 0 }} />
                  </Space>
                </Dropdown>
              </MenuItem>
            ) : (
              <MenuItem
                key={route}
                icon={icon}
                onClick={() => {
                  navigate(route);
                  setSideBarItems([]);
                  setCollapsed(false);
                }}
              >
                {label}
              </MenuItem>
            );
          })}
        </Menu>

        <Menu
          mode="horizontal"
          className="paliq-top-right-menu"
          onClick={onMenuClick}
          selectedKeys={[selectedNavKey]}
          items={buildMenuItems(rightMenu)}
        />
      </Header>
      {sideBarItems.length > 0 && (
        <Sidebar
          title={sidebarTitle}
          data={sideBarItems}
          onMenuClick={({ key }) => {
            setSelectedKey(key);
            navigate(key);
          }}
          selectedKey={selectedKey}
        />
      )}
      <InviteTeamModal
        show={modals["invite"]}
        setShow={(show) => setModalShow("invite", show)}
      />
      <ChangePasswordModal
        show={modals["password"]}
        setShow={(show) => setModalShow("password", show)}
      />
      <LogoutModal
        show={modals["logout"]}
        setShow={(show) => setModalShow("logout", show)}
      />
      <Global
        styles={css`
          .ant-layout-header {
            height: 50px !important;
            line-height: 50px !important;
          }
          .ant-menu-horizontal {
            border-bottom: 0 !important;
          }
          .ant-menu-light {
            border-right: 0 !important;
          }
          .ant-menu-item-selected {
           color:"white";
           background-color: #0b8c5d !important;
          }
          
          .ant-menu-item{
          margin: 0 !important;
        }
      }
      }
        `}
      />
    </>
  );
};

export default memo(CustomNav);
