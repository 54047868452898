import { useContext, useEffect } from "react";
import axiosClient from "../services/base";
import { refreshIdToken } from "../services/auth";
import { AuthContext } from "../App";
import { responseCodes } from "../constants";
import { getIdTokenfromLS, logoutFromLS } from "../utils/helper";
import { utf8ToB64 } from "../utils/base64";

const WithAxiosInterceptor = ({ children }) => {
  const { dispatch } = useContext(AuthContext);

  useEffect(() => {
    // Intercept response
    const intrc = axiosClient.interceptors.response.use(
      (response) => {
        return response?.data;
      },

      async (error) => {
        const originalRequest = error.config;
        if (
          error?.response?.status === responseCodes.AUTH403 &&
          !originalRequest._retry
        ) {
          // we may have to remove auth header for the ref token request.
          axiosClient.defaults.headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: undefined
          };
          console.log("403 rcvd. Trying refreshToken call");
          originalRequest._retry = true;
          const [_, id_token] = await refreshIdToken();
          //alert("id token rcvd = ", id_token);
          // need to dispatch
          if (id_token) {
            dispatch({
              type: "LOGIN",
              payload: {
                token: id_token
              }
            });
          }
          // now we reset Auth header to new Id Token
          axiosClient.defaults.headers = {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: id_token
          };
          originalRequest.headers.Authorization = id_token;
          return axiosClient(originalRequest);
        }
        if (
          error?.response?.status === responseCodes.AUTH403 &&
          originalRequest._retry
        ) {
          console.log("ref token did not come thru so logging out");
          logoutFromLS();
          window.location.href = `/?callback=${utf8ToB64(
            window.location.pathname + window.location.search
          )}`;
        } else {
          console.log("failure, error = ", error);
          return Promise.reject(error.response.data);
        }
      }
    );
    return () => {
      axiosClient.interceptors.response.eject(intrc);
    };
  }, []);

  return children;
};

export default WithAxiosInterceptor;
