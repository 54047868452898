import { Layout } from "antd";
import React, { memo, Suspense, useState, useEffect } from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "../App";
import { useTheme } from "@emotion/react";
import "./styles.scss";
import { Spinner } from "../components/common";
import CustomerList from "../components/customers/CustomerList";
import CustomNav from "../components/menus/CustomNav";
import CreateCustomer from "../pages/customers/CreateCustomer";
import UploadsList from "../components/customers/upload";
import UsageUploadsList from "../components/usage/upload";
import UsageList from "../pages/usage/UsageList";
import BillsList from "../components/billing/BillsList";
import PricingAnalysisPage from "../pages/pricing";
import SetPassword from "../pages/login/setPassword";
import {
  menuKeys as m,
  itemKeys as l
} from "../components/menus/menuKeys.constants";
import PaymentMethodList from "../components/payment_methods/PaymentMethodList";
import CreatePaymentMethod from "../components/payment_methods/CreatePaymentMethod";
import PaymentMethodDetail from "../components/payment_methods/PaymentMethodDetail";
import CreateWorkflow from "../components/workflows/CreateWorkflow";
import WorkflowList from "../components/workflows/WorkflowList";
import WorkflowDetail from "../components/workflows/WorkflowDetail";
import CustomBreadcrumb from "../components/common/CustomBreadcrumb";
import { SETTINGS } from "../components/menus/Menus";

const CustomerCreditsView = React.lazy(() =>
  import("../pages/customers/CustomerCreditsView")
);
const CustomerUsageView = React.lazy(() =>
  import("../pages/customers/CustomerUsageView")
);
const BillDetailsPage = React.lazy(() =>
  import("../pages/bills/BillDetailsPage")
);
const BillToDatePage = React.lazy(() =>
  import("../pages/bills/BillToDatePage")
);

const Login = React.lazy(() => import("../pages/login"));
const Logout = React.lazy(() => import("../pages/logout"));
const Register = React.lazy(() => import("../pages/signup/register"));
const ConfirmSignUp = React.lazy(() => import("../pages/signup/confirm"));
const ForgotPassword = React.lazy(() =>
  import("../pages/manageuser/ForgotPassword")
);
const ConfirmForgotPassword = React.lazy(() =>
  import("../pages/manageuser/ConfirmForgotPassword")
);

const TeamList = React.lazy(() => import("../components/teams/TeamList"));
const CompanyInfo = React.lazy(() =>
  import("../components/company/CompanyDetail")
);
const MeterList = React.lazy(() =>
  import("../components/new_meters/MeterList")
);
const MeterDetail = React.lazy(() =>
  import("../components/new_meters/MeterDetail")
);
const CreateMeter = React.lazy(() =>
  import("../components/new_meters/CreateMeter")
);
const ServiceList = React.lazy(() =>
  import("../components/new_services/ServiceList")
);
const ServiceDetail = React.lazy(() =>
  import("../components/new_services/ServiceDetail")
);
const CreateNewService = React.lazy(() =>
  import("../components/new_services/CreateService")
);
const PlanList = React.lazy(() => import("../components/new_plans/PlanList"));
const PlanDetail = React.lazy(() =>
  import("../components/new_plans/PlanDetail")
);
const CreatePlan = React.lazy(() =>
  import("../components/new_plans/CreatePlan")
);

const CreatePlans = React.lazy(() => import("../pages/plans/CreatePlan"));
const HowItWorks = React.lazy(() => import("../pages/howworks"));
const IntegrationsPage = React.lazy(() => import("../pages/integrations"));
const PreferencesPage = React.lazy(() =>
  import("../pages/integrations/stripe/Preferences")
);
const CustomerMappingPage = React.lazy(() =>
  import("../pages/integrations/stripe/CustomerMapping")
);
const PreferencesOutlet = React.lazy(() =>
  import("../pages/integrations/stripe/PreferencesOutlet")
);

const Dashboard = React.lazy(() => import("../pages/dashboard"));
const ImportExportPage = React.lazy(() => import("../pages/fileimport"));
const ReportsPage = React.lazy(() => import("../pages/reports"));
const APIPage = React.lazy(() => import("../pages/api-key"));

const PricingAnalysis = React.lazy(() => import("../pages/pricing"));

// Customers

// for Bills
const ReportBill = React.lazy(() =>
  import("../components/customers/customer-detail/bills/BillReport")
);
//
const Partner = React.lazy(() => import("../pages/partner"));
const PartnerCreate = React.lazy(() => import("../components/partner/Create"));
const { Content } = Layout;

/* For call back pages after oAuth*/
const StripeCallbackPage = React.lazy(() =>
  import("../pages/integrations/stripe/StripeCallbackPage")
);
const ConnectedAppsPage = React.lazy(() =>
  import("../pages/connected_apps/ConnectedAppsPage")
);

const PaymentList = React.lazy(() =>
  import("../components/payments/PaymentList")
);
const PaymentCreate = React.lazy(() =>
  import("../components/payments/CreatePayment")
);
const PaymentDetail = React.lazy(() =>
  import("../components/payments/PaymentDetail")
);

// Coupons
const Coupons = React.lazy(() => import("../pages/coupons"));
const CouponCreate = React.lazy(() => import("../pages/coupons/CreateCoupon"));
const CouponEdit = React.lazy(() => import("../pages/coupons/EditCoupon"));

// Settings
const Settings = React.lazy(() => import("../pages/settings"));

function AppRoutes() {
  const { state } = React.useContext(AuthContext);
  const [collapsed, setCollapsed] = useState(false);
  const [breadcrumbItems, setBreadcrumbItems] = useState([]);
  const location = useLocation();

  useEffect(() => {
    const arr = location.pathname.split("/");
    let isSettings = false;
    if (arr.length > 1) {
      const path = "/" + arr[1];

      SETTINGS.forEach(({ children }) => {
        const idx = children.findIndex(({ route, label }) => path == route);
        if (idx !== -1) {
          isSettings = true;
          setBreadcrumbItems([
            {
              route: "/settings",
              label: "Settings"
            },
            {
              route: children[idx].route,
              label: children[idx].label
            }
          ]);
        }
      });
    }
    if (!isSettings) {
      setBreadcrumbItems([]);
    }
  }, [location]);

  useEffect(() => {
    if (!state.isAuthenticated || !state.user) {
      setCollapsed(false);
    }
  }, [state]);

  const theme = useTheme();
  const layout = theme.layout;
  if (!state.isAuthenticated || !state.user)
    return (
      <div className="sign-container">
        <div>
          <Layout>
            <Content>
              <Routes>
                <Route
                  path="/login"
                  element={
                    <Suspense fallback={<Spinner />}>
                      <Login />
                    </Suspense>
                  }
                />
                <Route
                  path="/forgotpassword"
                  element={
                    <Suspense fallback={<Spinner />}>
                      <ForgotPassword />
                    </Suspense>
                  }
                />
                <Route
                  path="/passwordreset"
                  element={
                    <Suspense fallback={<Spinner />}>
                      <ConfirmForgotPassword />
                    </Suspense>
                  }
                />
                <Route
                  path="/setpassword"
                  element={
                    <Suspense fallback={<Spinner />}>
                      <SetPassword />
                    </Suspense>
                  }
                />
                <Route
                  path="/signup"
                  element={
                    <Suspense fallback={<Spinner />}>
                      <Register />
                    </Suspense>
                  }
                />
                <Route
                  path="/signup/confirm"
                  element={
                    <Suspense fallback={<Spinner />}>
                      <ConfirmSignUp />
                    </Suspense>
                  }
                />
                <Route
                  path="*"
                  element={
                    <Suspense fallback={<Spinner />}>
                      <Login />
                    </Suspense>
                  }
                />
              </Routes>
            </Content>
          </Layout>
        </div>
      </div>
    );
  else
    return (
      <>
        <CustomNav setCollapsed={setCollapsed} />
        <Layout
          style={{
            marginLeft: collapsed
              ? layout.sideNavOpenWidth
              : layout.bodyMarginX,
            marginTop: layout.bodyMarginTop,
            marginRight: layout.bodyMarginX,
            maxWidth: layout.maxWidth,
            flexGrow: 1
          }}
        >
          <Content>
            {breadcrumbItems.length > 0 && (
              <CustomBreadcrumb items={breadcrumbItems} />
            )}
            <Routes>
              <Route
                path="/"
                element={
                  <Suspense fallback={<Spinner />}>
                    <Dashboard />
                  </Suspense>
                }
              />
              <Route
                path={m.meters.route}
                exact
                element={
                  <Suspense fallback={<Spinner />}>
                    <MeterList />
                  </Suspense>
                }
              />
              <Route
                path={l.meterDetail.route}
                exact
                element={
                  <Suspense fallback={<Spinner />}>
                    <MeterDetail />
                  </Suspense>
                }
              />
              <Route
                path={m.meterCreate.route}
                exact
                element={
                  <Suspense fallback={<Spinner />}>
                    <CreateMeter />
                  </Suspense>
                }
              />

              <Route
                path={m.services.route}
                exact
                element={
                  <Suspense fallback={<Spinner />}>
                    <ServiceList />
                  </Suspense>
                }
              />
              <Route
                path={l.serviceDetail.route}
                exact
                element={
                  <Suspense fallback={<Spinner />}>
                    <ServiceDetail />
                  </Suspense>
                }
              />
              <Route
                path={m.serviceCreate.route}
                exact
                element={
                  <Suspense fallback={<Spinner />}>
                    <CreateNewService />
                  </Suspense>
                }
              />

              <Route
                path={m.plans.route}
                exact
                element={
                  <Suspense fallback={<Spinner />}>
                    <PlanList />
                  </Suspense>
                }
              />
              <Route
                path={l.planDetail.route}
                exact
                element={
                  <Suspense fallback={<Spinner />}>
                    <PlanDetail />
                  </Suspense>
                }
              />
              <Route
                path={m.planCreate.route}
                exact
                element={
                  <Suspense fallback={<Spinner />}>
                    <CreatePlan />
                  </Suspense>
                }
              />

              <Route
                path={m.customer.route}
                exact
                element={
                  <Suspense fallback={<Spinner />}>
                    <CustomerList />
                  </Suspense>
                }
              />
              <Route
                path="/customercredits/:id/:action"
                exact
                element={
                  <Suspense fallback={<Spinner />}>
                    <CustomerCreditsView />
                  </Suspense>
                }
              />
              <Route
                path="/customers/upload"
                exact
                element={
                  <Suspense fallback={<>...</>}>
                    <UploadsList />
                  </Suspense>
                }
              />
              <Route
                path="/customers/create"
                exact
                element={
                  <Suspense fallback={<>...</>}>
                    <CreateCustomer />
                  </Suspense>
                }
              />
              <Route
                path="/customers/usage/:id"
                exact
                element={
                  <Suspense fallback={<Spinner />}>
                    <CustomerUsageView />
                  </Suspense>
                }
              />
              <Route
                path="/customers/bill/:id/:action"
                exact
                element={
                  <Suspense fallback={<Spinner />}>
                    <ReportBill />
                  </Suspense>
                }
              />
              <Route
                path="/customers/:id/:action"
                exact
                element={
                  <Suspense fallback={<Spinner />}>
                    <CreateCustomer />
                  </Suspense>
                }
              />
              <Route
                path={m.partner.route}
                exact
                element={
                  <Suspense fallback={<Spinner />}>
                    <Partner />
                  </Suspense>
                }
              />
              <Route
                path="/partner/create"
                exact
                element={
                  <Suspense fallback={<Spinner />}>
                    <PartnerCreate />
                  </Suspense>
                }
              />
              <Route
                path="/partner/list"
                exact
                element={
                  <Suspense fallback={<Spinner />}>
                    <CustomerList />
                  </Suspense>
                }
              />
              <Route
                path={m.billing.route}
                exact
                element={
                  <Suspense fallback={<Spinner />}>
                    <BillsList />
                  </Suspense>
                }
              />
              <Route
                path={m.pricing.route}
                exact
                element={
                  <Suspense fallback={<Spinner />}>
                    <PricingAnalysisPage />
                  </Suspense>
                }
              />
              <Route
                path="/billing/details/:id"
                exact
                element={
                  <Suspense fallback={<Spinner />}>
                    <BillDetailsPage />
                  </Suspense>
                }
              />
              <Route
                path="/billing/details/:cust_id/:plan_id"
                exact
                element={
                  <Suspense fallback={<Spinner />}>
                    <BillToDatePage />
                  </Suspense>
                }
              />
              <Route
                path={m.usage.route}
                exact
                element={
                  <Suspense fallback={<Spinner />}>
                    <UsageList />
                  </Suspense>
                }
              />
              <Route
                path={m.home.route}
                element={
                  <Suspense fallback={<Spinner />}>
                    <HowItWorks />
                  </Suspense>
                }
              />
              <Route
                path={m.planCreate.route}
                element={
                  <Suspense fallback={<Spinner />}>
                    <CreatePlans />
                  </Suspense>
                }
              />
              <Route
                path={m.coupons.route}
                element={
                  <Suspense fallback={<Spinner />}>
                    <Coupons />
                  </Suspense>
                }
              />
              <Route
                path={m.couponCreate.route}
                element={
                  <Suspense fallback={<Spinner />}>
                    <CouponCreate />
                  </Suspense>
                }
              />
              <Route
                path={l.couponDetail.route}
                element={
                  <Suspense fallback={<Spinner />}>
                    <CouponEdit />
                  </Suspense>
                }
              />
              <Route path={m.integrations.route}>
                <Route
                  index
                  element={
                    <Suspense fallback={<Spinner />}>
                      <IntegrationsPage />
                    </Suspense>
                  }
                />
                <Route
                  path="preferences"
                  element={
                    <Suspense fallback={<Spinner />}>
                      <PreferencesOutlet />
                    </Suspense>
                  }
                >
                  <Route
                    index
                    element={
                      <Suspense fallback={<Spinner />}>
                        <PreferencesPage />
                      </Suspense>
                    }
                  />
                  <Route
                    path="customer-mapping"
                    element={
                      <Suspense fallback={<Spinner />}>
                        <CustomerMappingPage />
                      </Suspense>
                    }
                  />
                </Route>
              </Route>
              <Route
                path={m.team.route}
                exact
                element={
                  <Suspense fallback={<Spinner />}>
                    <TeamList />
                  </Suspense>
                }
              />
              <Route
                path={m.company.route}
                exact
                element={
                  <Suspense fallback={<Spinner />}>
                    <CompanyInfo />
                  </Suspense>
                }
              />
              <Route
                path={m.paymentMethods.route}
                exact
                element={
                  <Suspense fallback={<Spinner />}>
                    <PaymentMethodList />
                  </Suspense>
                }
              />
              <Route
                path={l.paymentMethodDetail.route}
                exact
                element={
                  <Suspense fallback={<Spinner />}>
                    <PaymentMethodDetail />
                  </Suspense>
                }
              />
              <Route
                path={m.paymentMethodCreate.route}
                exact
                element={
                  <Suspense fallback={<Spinner />}>
                    <CreatePaymentMethod />
                  </Suspense>
                }
              />
              <Route
                path={m.workflows.route}
                exact
                element={
                  <Suspense fallback={<Spinner />}>
                    <WorkflowList />
                  </Suspense>
                }
              />
              <Route
                path={l.workflowDetail.route}
                exact
                element={
                  <Suspense fallback={<Spinner />}>
                    <WorkflowDetail />
                  </Suspense>
                }
              />
              <Route
                path={m.workflowCreate.route}
                exact
                element={
                  <Suspense fallback={<Spinner />}>
                    <CreateWorkflow />
                  </Suspense>
                }
              />
              <Route
                path={m.dashboard.route}
                element={
                  <Suspense fallback={<Spinner />}>
                    <Dashboard />
                  </Suspense>
                }
              />
              <Route
                path={m.logout.route}
                element={
                  <Suspense fallback={<Spinner />}>
                    <Logout />
                  </Suspense>
                }
              />
              <Route
                path={m.imexport.route}
                element={
                  <Suspense fallback={<Spinner />}>
                    <ImportExportPage />
                  </Suspense>
                }
              />
              <Route
                path="/usage/upload"
                element={
                  <Suspense fallback={<Spinner />}>
                    <UsageUploadsList />
                  </Suspense>
                }
              />
              <Route
                path={m.reports.route}
                element={
                  <Suspense fallback={<Spinner />}>
                    <ReportsPage />
                  </Suspense>
                }
              />
              <Route
                path={m.api.route}
                element={
                  <Suspense fallback={<Spinner />}>
                    <APIPage />
                  </Suspense>
                }
              />
              <Route
                path="/st_callback"
                element={
                  <Suspense fallback={<Spinner />}>
                    <StripeCallbackPage />
                  </Suspense>
                }
              />
              <Route
                path={m.connected_apps.route}
                element={
                  <Suspense fallback={<Spinner />}>
                    <ConnectedAppsPage />
                  </Suspense>
                }
              />
              <Route
                path={m.payments.route}
                element={
                  <Suspense fallback={<Spinner />}>
                    <PaymentList />
                  </Suspense>
                }
              />
              <Route
                path={m.paymentCreate.route}
                element={
                  <Suspense fallback={<Spinner />}>
                    <PaymentCreate />
                  </Suspense>
                }
              />
              <Route
                path={l.paymentDetail.route}
                element={
                  <Suspense fallback={<Spinner />}>
                    <PaymentDetail />
                  </Suspense>
                }
              />
              <Route
                path={m.settings.route}
                element={
                  <Suspense fallback={<Spinner />}>
                    <Settings />
                  </Suspense>
                }
              />
            </Routes>
          </Content>
        </Layout>
      </>
    );
}

export default memo(AppRoutes);
