import { Bar, Line } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { FormRow } from "../../common/form.js";
import { Box } from "../../common";
import { Colors } from "chart.js";

export const BarChart = ({ chartData, title }) => {
  Chart.register(Colors);

  console.log("chartData from Bar Chart = ", chartData);
  const labels = [];
  const emptyData = {
    labels,
    datasets: [
      {
        label: "",
        data: []
      }
    ]
  };

  if (!!chartData) {
    return (
      <Bar
        height={75}
        data={chartData}
        options={{
          plugins: {
            title: {
              display: true,
              text: title
            },
            legend: {
              display: true,
              position: "bottom"
            },
            colors: {
              forceOverride: true
            }
          }
        }}
      />
    );
  }

  /*
  if (chartData !== undefined) {
  return (
    <div>
      <Bar
        data={data}
        options={{
          plugins: {
            title: {
              display: true,
              text: {title}
            },
            legend: {
              display: true,
              position: "bottom"
            }
          }
        }}
      />
    </div>
  );
  }return null;*/
};
