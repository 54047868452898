export const staticMetricValues = {
  enabled: false,
  is_addon: false,
  is_support: false,
  is_professional_service: false,
  status: "active",
  company_id: 1,
  service_attributes: ["numHosts", "dbHosts"]
};

export const metricStatic = {
  status: "active",
  description: "This is a test",
  meter_group: "group1",
  company_id: 1
};

export const finalStaticBillable = {
  name: "DD Hosts 2022",

  description: "Datadog hosts service",

  external_sku_code: "TESTSKU12345",

  status: "active",

  version: 1,

  enabled: false,

  is_addon: false,

  is_support: false,

  is_professional_service: false,

  company_id: 1,

  service_attributes: ["numHosts"]
};

export const staticPlanValues = {
  status: "draft",
  timeframe: "days",
  plan_json: []
};
export const StatusData = ["Active", "Inactive", "Draft"];
export const VersionData = [1, 2, 3];

export const gutter = { xs: 8, sm: 16, md: 24, lg: 24 }; // TODO: delete when nothing depends on this
export const dataPlan = ["dd_host_count", "time_track", "new_monitoring"];

export const multiInputOptions = [
  { value: "Option 1", label: "Option 1" },
  { value: "Option 2", label: "Option 2" },
  { value: "Option 3", label: "Option 3" },
  { value: "Option 4", label: "Option 4" }
];

export const layout = {
  labelCol: {
    xs: { span: 8 },
    sm: { span: 16 },
    md: { span: 24 },
    lg: { span: 32 }
  },
  wrapperCol: {
    xs: { span: 8 },
    sm: { span: 16 },
    md: { span: 24 },
    lg: { span: 32 }
  }
};
export const CURRENCY_DATA = [
  {value: "usd", label: "USD", symbol: "$"},
  {value: "eur", label: "EUR", symbol: "€" },
  {value: "gbp", label: "GBP", symbol: "£" },
];

//export const CURRENCY_DATA = ["USD", "EUR", "GBP"];
export const CURRENCY_SYMBOLS = {
  USD: '$',
  EUR: '€',
  GBP: '£'
};
export const Subscription = ["Subscription Price"];
export const paymentStatus = [
  {
    label: "Paid",
    value: "paid"
  },
  {
    label: "Partially Paid",
    value: "partially_paid"
  },
  {
    label: "Pre-payment",
    value: "pre-pay"
  }
  /*{
    label: "Paid With Credits",
    value: "paid_with_credits"
  },
  {
    label: "Past Due",
    value: "past_due"
  },
  {
    label: "Due",
    value: "due"
  }*/
];

// For radio Group of attribute form
export const attributeTypeOptions = ["String", "Number"];
export const tempData = ["atr1", "atr2", "atr3", "atr4"];
export const aggregationData = ["count", "sum", "max", "unit"];
export const radioData = ["Fixed", "Pay As You Go", "Tiered"];
export const charge_frequencyData = [
  "30 Days",
  "60 Days",
  "90 Days",
  "120 Days"
];
export const KeyCodes = {
  comma: 188,
  enter: 13
};

export const delimiters = [KeyCodes.comma, KeyCodes.enter];

export const serviceListData = [
  {
    key: "1",
    companyName: "Hosts",
    unique_key: "dd_Hosts",
    version: 1,
    status: "Active",
    createdOn: "03 May 22",
    activatedOn: "03 May 22",
    deactivatedOn: "03 May 22"
  },
  {
    key: "2",
    serviceName: "Data Usage",
    unique_key: "usage",
    version: 1,
    status: "Draft",
    createdOn: "03 May 22",
    activatedOn: "03 May 22",
    deactivatedOn: "03 May 22"
  },
  {
    key: "3",
    serviceName: "Number Of Events",
    unique_key: "events",
    version: 1,
    status: "InActive",
    createdOn: "03 May 22",
    activatedOn: "03 May 22",
    deactivatedOn: "03 May 22"
  }
];

export const plansListData = [
  {
    key: "1",
    planName: "Data Dog Pro",
    billingFrequency: "3 Months",
    currency: "USD",
    status: 1,
    createdOn: "03 May 22",
    activatedOn: "03 May 22",
    deactivatedOn: "-",
    customers: "10",
    partners: "24"
  },
  {
    key: "2",
    planName: "Data Transfer",
    billingFrequency: "6 Months",
    currency: "USD",
    status: 2,
    createdOn: "04 Apr 22",
    activatedOn: "-",
    deactivatedOn: "-",
    customers: "5",
    partners: "35"
  },
  {
    key: "3",
    planName: "Web Services",
    billingFrequency: "1 Month",
    currency: "USD",
    status: 3,
    createdOn: "17 Feb 22",
    activatedOn: "17 Feb 22",
    deactivatedOn: "19 Feb 22",
    customers: "8",
    partners: "12"
  }
];

export const BILLING_FREQUENCY = ["Monthly", "Yearly"];
export const intialMeterValues = {
  displayName: "",
  mater_key: "",
  arregration: "",
  aggregate_on: "",
  group_by: false
};

export const pricingPayload = {
  base_price: 1,

  billing_frequency: 30,

  currency: "USD",

  meters: [
    {
      pricing: {
        usage: {
          unit_price: 0.007,

          pricing_unit: 1
        }
      },

      meter_key: 1
    },
    {
      pricing: {
        usage: {
          unit_price: 0.0045,

          pricing_unit: 1
        }
      },

      meter_key: 2
    },
    {
      pricing: {
        recurring: {
          name: "monthly",

          base_price: 1,

          billing_frequency: 30
        }
      },

      meter_key: 3
    }
  ]
};

export const text = `
  A dog is a type of domesticated animal.
  Known for its loyalty and faithfulness,
  it can be found as a welcome guest in many households across the world.
`;

export const secondTable = [
  {
    name: "Customer Pre-Commit",
    amount: `$ ${10000}`,
    expiration: "06 / 21 / 2022"
  },
  {
    name: "Customer Pre-Commit",
    amount: `$ ${10000}`,
    expiration: "08 / 21 / 2022"
  },
  {
    name: "Customer Pre-Commit",
    amount: `$ ${10000}`,
    expiration: "06 / 21 / 2022"
  }
];

export const customerReview = {
  contact_column: [
    {
      title: "Name",
      dataIndex: "name",
      placeholder: "Please Enter Name"
    },
    {
      title: "Email Id.",
      dataIndex: "email",
      placeholder: "Please Enter Email"
    },
    {
      title: "Phone No.",
      dataIndex: "phone",
      placeholder: "Please Enter Phone No."
    },
    {
      title: "Primary Contact.",
      dataIndex: "is_primary",
      placeholder: "Please Enter Phone No."
    },
    {
      title: "Billing Contacts.",
      dataIndex: "is_billing",
      placeholder: "Please Enter Phone No."
    }
  ],

  credit_column: [
    {
      title: "Name",
      dataIndex: "name",
      width: "66%",
      placeholder: "Please Enter Name"
    },
    {
      title: "Amount.",
      dataIndex: "amount",
      placeholder: "Please Enter Email"
    },
    {
      title: "Expiration.",
      dataIndex: "expiration",
      placeholder: "Please Enter Phone No."
    }
  ],
  plans: {
    plan_name: "Hosts",
    description: "service to record number of hosts."
  }
};

export const billsTableData = [
  {
    billNo: "PP87248756",
    billingPeriod: "06/23/2022 - 07/22/2022",
    billedUser: "Jane Cooper",
    invoiceNo: "#526589",
    invoiceDate: "-",
    dueDate: "-",
    amount: "$456.00",
    totalAmount: "$456.00",
    status: "Not Sent",
    id: 1
  },
  {
    billNo: "PP15839595",
    billingPeriod: "06/20/2022 - 07/19/2022",
    billedUser: "Esther Howard",
    invoiceNo: "#526587",
    invoiceDate: "07/20/2022",
    dueDate: "08/19/2022",
    amount: "$600.00",
    totalAmount: "$600.00",
    status: "Invoiced",
    id: 2
  },
  {
    billNo: "PP98325223",
    billingPeriod: "06/23/2022 - 07/22/2022",
    billedUser: "Floyd Miles",
    invoiceNo: "-",
    invoiceDate: "06/23/2022",
    dueDate: "07/22/2022",
    amount: "$456.00",
    totalAmount: "$456.00",
    status: "Paid",
    id: 3
  },
  {
    billNo: "PP94275223",
    billingPeriod: "06/23/2022 - 07/22/2022",
    billedUser: "Savannah Nguyen",
    invoiceNo: "#696589",
    invoiceDate: "06/20/2022",
    dueDate: "07/19/2022",
    amount: "$600.00",
    totalAmount: "$600.00",
    status: "Over Due",
    id: 4
  }
];

export const filterForBills = [
  "All Status",
  "Not Sent",
  "Invoiced",
  "Paid",
  "Over Due"
];

export const filterForBillsReport = ["All Services"];

export const billsReportData = [
  {
    date: "06/23/2022",
    servicePeriod: "05/23/2022 - 06/22/2022",
    serviceName: "Graph DB",
    sku: "GDB-PREM-PVT",
    listPrice: "$456.00",
    effectivePrice: "$411.00",
    qty: "1",
    chargesCredits: "$411.00",
    total: "$411.00"
  },
  {
    date: "06/23/2022",
    servicePeriod: "05/23/2022 - 06/22/2022",
    serviceName: "Graph DB",
    sku: "GDB-PREM-PVT",
    listPrice: "$456.00",
    effectivePrice: "$411.00",
    qty: "1",
    chargesCredits: "$411.00",
    total: "$411.00"
  }
];

export const routes = [
  {
    path: "index",
    breadcrumbName: "Customer 360"
  },
  {
    path: "first",
    breadcrumbName: "AppsumsAvi"
  },
  {
    path: "second",
    breadcrumbName: "Bills"
  },
  {
    path: "second",
    breadcrumbName: "Bill No. PP98325223"
  }
];

export const customerHeaderRoutes = [
  {
    path: "index",
    breadcrumbName: "Customer 360"
  },
  {
    path: "first",
    breadcrumbName: "Globex Corporation"
  }
];
