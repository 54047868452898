import React from "react";
import WorkflowForm from "./WorkflowForm";

const CreateWorkflow = (props) => {
  const data = {
    accounts_partner: 'paliq',
    contracts_partner: 'paliq',
    plans_partner: 'paliq',
    invoices_partner: 'paliq',
    payments_partner: 'paliq',
  };

  return <WorkflowForm data={data}/>;
};

export default CreateWorkflow;
