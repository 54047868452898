import { Form, Table } from "antd";
import EditableCell from "../../../common/EditableCell";
import {formatMoney, withTooltip} from "../../../common/table";

const CustomerCreditDetails = (props) => {
  const { credits, setCredits, onFinish, onFinishFailed } = props;
  const form = Form.useFormInstance();

  const setFieldValue = (kvp) => {
    console.log("setFieldValue Fired with = ", kvp);
    form.setFieldsValue(kvp);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "credit_name",
      width: "30%",
      editable: true,
      inputType: "text"
    },
    {
      title: 'Credit Amount',
      dataIndex: 'credit_bought',
      width: '15%',
      editable: true,
      inputType: "number",
      render: (_, record) => formatMoney(record.currency_code, record.credit_bought)
    },
    {
      title: 'Remaining Amount',
      dataIndex: 'credit_amount',
      width: '15%',
      editable: false,
      inputType: "number",
      render: (_, record) => formatMoney(record.currency_code, record.credit_amount)
    },
    {
      title: "Start Date",
      dataIndex: "start_date",
      width: "15%",
      editable: true,
      inputType: "date"
    },
    {
      title: "End Date",
      dataIndex: "end_date",
      width: "15%",
      editable: true,
      inputType: "date"
    }
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.inputType,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: record.uid.length < 22 || col.dataIndex === "end_date", // if uid not there then all is editable
        jsonpath: `${record.uid}.${col.dataIndex}`,
        initValue: record[col.dataIndex],
        setValueInForm: setFieldValue
      })
    };
  });

  return (
    <Form
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      wrapperCol={24}
    >
      <Table
        bordered={true}
        components={{
          body: {
            cell: EditableCell
          }
        }}
        rowKey={(record) => record.uid}
        dataSource={credits}
        columns={withTooltip(mergedColumns)}
        pagination={false}
        size="small"
      />
    </Form>
  );
};

export default CustomerCreditDetails;
