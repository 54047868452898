export const staticMetricValues = {
  external_sku_code: "TESTSKU1234",
  enabled: false,
  is_addon: false,
  is_support: false,
  is_professional_service: true,
  company_id: 15,
  service_attributes: ["numHosts", "dbHosts"]
};

export const BILLING_SCHEDULE_STATUS = {
  EMPTY: "undefined",
  CREATED: "created",
  NONE: "none",
  TERMINATED: "cancelled"
}
export const StatusData = ["Active", "Inactive", "Draft"];

export const VersionData = [1, 2, 3];

export const gutter = { xs: 8, sm: 16, md: 24, lg: 32 };
export const dataPlan = ["dd_host_count", "time_track", "new_monitoring"];

export const multiInputOptions = [
  { value: 1, label: "Option 1" },
  { value: 2, label: "Option 2" },
  { value: 3, label: "Option 3" },
  { value: 4, label: "Option 4" }
];

export const CountryData = ["Please Select", "India", "US", "Canada"];
export const StateData = [
  "Please Select",
  "Uttar Pradesh",
  "California",
  "Toronto"
];
export const CityData = [
  "Please Select",
  "Baghpat",
  "San Francisco",
  "Toronto"
];

export const ChannelData = ["Please Select", "Direct"];

export const secondTable = [
  {
    name: "Customer Pre-Commit",
    amount: `$ ${10000}`,
    expiration: "06 / 21 / 2022"
  },
  {
    name: "Customer Pre-Commit",
    amount: `$ ${10000}`,
    expiration: "08 / 21 / 2022"
  },
  {
    name: "Customer Pre-Commit",
    amount: `$ ${10000}`,
    expiration: "06 / 21 / 2022"
  }
];

export const CURRENCY_DATA = [
  { label: "USD", value: "USD" },
  { label: "EUR", value: "EUR" },
  { label: "GBP", value: "GBP" }
];
export const termData = [
  { label: "Due on Issue", value: "0" },
  { label: "Net 15", value: "15" },
  { label: "Net 30", value: "30" },
  //{ label: "Net 45", value: "45" },
  { label: "Net 60", value: "60" },
  { label: "Net 90", value: "90" },
  { label: "Net 120", value: "120" }
];


export const creditHeader = [
  {
    headerName: "Credit Name",
    span: 7,
  },
  {
    headerName: "Amount",
    span: 7,
  },
  {
    headerName: "Expiration",
    span: 7,
  },
  {
    headerName: "Actions",
    span: 3,
  }
];
