import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { Box, Spinner } from "../../components/common";
import { CodePageTitle, TabContainer } from "../../components/common/page";
import { ACTIONS } from "../../constants";
import { CustomTab } from "../../components/common/Tab";
import {
  AccountInfo,
  AccountBillList,
  AccountPlans,
  AccountContacts,
  AccountUsage,
  AccountPayments,
  AccountContracts,
  AccountCredits,
  AccountDetail
} from "../../components/customers/customer-detail";
import { getCustomerById } from "../../services/customerFormAction";

const withAccountBasicInfo = (Component, accountData) => (props) => {
  return (
    <>
      <TabContainer>
        <Component {...props} accountData={accountData} />
      </TabContainer>
    </>
  );
};

const useCustomSearchParams = () => {
  const [search, setSearch] = useSearchParams();
  const searchAsObject = Object.fromEntries(new URLSearchParams(search));
  return [searchAsObject, setSearch];
};

export default function CreateCustomer() {
  let { id, action } = useParams(); // action can be create, edit or view.
  const [accountData, setAccountData] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [search, setSearch] = useCustomSearchParams();
  const [activeTabKey, setTabKey] = useState("account-info");
  const [isAccountDetail, setIsAccountDetail] = useState(true);

  useEffect(() => {
    setTabKey(search["tab"] ?? "account-info");
    (async () => {
      setLoading(true);
      try {
        if (id) {
          setAccountData((await getCustomerById(id))?.account);
        } else {
          setIsAccountDetail(false);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if(activeTabKey !== "account-info" && id) {
      setIsAccountDetail(true);
    }
  }, [activeTabKey]);

  if (!action) action = ACTIONS.CREATE;
  const externalGUID = search["external-id"];

  const AccountContactsWithBasicInfo = withAccountBasicInfo(
    AccountContacts,
    accountData
  );

  const AccountPlansWithBasicInfo = withAccountBasicInfo(
    AccountPlans,
    accountData
  );

  const AccountBillListWithBasicInfo = withAccountBasicInfo(
    AccountBillList,
    accountData
  );

  const AccountCreditsWithBasicInfo = withAccountBasicInfo(
    AccountCredits,
    accountData
  );

  const AccountPaymentsWithBasicInfo = withAccountBasicInfo(
    AccountPayments,
    accountData
  );



  const AccountContractsWithBasicInfo = withAccountBasicInfo(
    AccountContracts,
    accountData
  );

  const items = [
    {
      label: "Account Info",
      key: "account-info"
    },
    {
      label: "Contacts",
      key: "contacts"
    },
    {
      label: "Plans",
      key: "plans"
    },
    {
      label: "Usage",
      key: "usage"
    },
    {
      label: "Bills",
      key: "bills"
    },
    {
      label: "Credits",
      key: "credits"
    },
    {
      label: "Payments",
      key: "payments"
    }
    ,
    /*{
      label: "Payment",
      key: "contracts"
    }*/
  ];

  const handleTabChange = (activeKey) => {
    setTabKey(activeKey);
    setSearch({ ...search, tab: activeKey });
  };

  const getTabComponent = (activeKey) => {
    switch (activeKey) {
      case "account-info":
        if(isAccountDetail) {
          return <AccountDetail onAccountDetail={setIsAccountDetail}/>
        }
        return (
          <AccountInfo
            id={id}
            action={action}
            accountData={accountData}
            setAccountData={setAccountData}
            onAccountDetail={setIsAccountDetail}
          />
        );
      case "contacts":
        return <AccountContactsWithBasicInfo id={id} />;
      case "plans":
        return <AccountPlansWithBasicInfo id={id} />;
      case "usage":
        return <AccountUsage accountData = {accountData} id={externalGUID} />;
      case "bills":
        return <AccountBillListWithBasicInfo />;
      case "credits":
        return <AccountCreditsWithBasicInfo id={id} />;
      case "payments":
        return <AccountPaymentsWithBasicInfo />;
      case "contracts":
        return <AccountContractsWithBasicInfo />;
    }
  };
  if (isLoading)
    return (
      <Box height="70vh">
        <Spinner />
      </Box>
    );
  return (
    <>
      {/*<CardContainer>*/}
        <CodePageTitle title={accountData?.name ?? "Create Account"} />

        <CustomTab
          items={items}
          size="medium"
          onChange={handleTabChange}
          activeKey={activeTabKey}
        />
        {getTabComponent(activeTabKey)}
      {/*</CardContainer>*/}
    </>
  );
}
