import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
// Note this is the ONE and ONLY place Antd should be imported
// We are using `antd.less` instead of `antd.css` since we are applying customization
// See `craco.config.js` for detail

// Also note this is the only place LESS is used. We standardize on Emotion and Styled System for the rest of the application.

import "antd/dist/antd.less";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
reportWebVitals();
// ReactDOM.render(<App />, document.getElementById("root"));
