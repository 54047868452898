import React, { useEffect, useState } from "react";
import { Card } from "antd";
import { Typography } from "antd";
import { useQuery } from "@tanstack/react-query";
import { Country, State, City } from "country-state-city";
import { getAllOrgs } from "../../services/customerFormAction";
import { FormItem } from "../paliq/input/FormItem";
import { Form, FormRow } from "../common/form";
import {CollapsibleCard} from "../common/CollapsibleCard";

const { Title } = Typography;

const processOrgDataForSelect = (res) => {
  const retVal = res?.orgs?.map((i) => {
    return { label: i.name, value: i.id };
  });
  return [
    { label: "None", value: "00000000-0000-0000-0000-000000000000" },
    ...retVal
  ];
};

const getAccountType = () => {
  return [
    { label: " Customer", value: "customer" },
    { label: " Partner", value: "partner" }
  ];
};

const getStatus = () => {
  return [
    { label: " Active", value: "active" },
    { label: " Inactive", value: "inactive" }
  ];
};

const CustomerBasicInfo = (props) => {
  const { customerData, editable, onFinish, onFinishFailed } = props;
  const { data: orgData } = useQuery(["allorgs"], () => getAllOrgs(), {
    staleTime: Infinity,
    select: processOrgDataForSelect
  });
  const [country, setCountry] = useState(""); // the selected country

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]); // the selected state
  const [cities, setCities] = useState([]); // the selected state

  const form = Form.useFormInstance();

  useEffect(() => {
    setCountry(customerData?.country);
    setStates(
      State.getStatesOfCountry(customerData?.country).map(
        ({ name, isoCode }) => ({
          label: name,
          value: isoCode
        })
      )
    );
    setCities(
      City.getCitiesOfState(customerData?.country, customerData?.state).map(
        ({ name }) => ({
          label: name,
          value: name
        })
      )
    );
    form.setFieldsValue(customerData);

    return () => {};
  }, [customerData]);

  useEffect(() => {
    setCountries(
      Country.getAllCountries().map(({ name, isoCode }) => ({
        label: name,
        value: isoCode
      }))
    );
  }, []);

  const onCountryChange = (country) => {
    setCountry(country);
    form.setFieldValue("state", null);
    form.setFieldValue("city", null);
    setStates(
      State.getStatesOfCountry(country).map(({ name, isoCode }) => ({
        label: name,
        value: isoCode
      }))
    );
  };

  const onStateChange = (state) => {
    form.setFieldValue("city", null);
    setCities(
      City.getCitiesOfState(country, state).map(({ name }) => ({
        label: name,
        value: name
      }))
    );
  };

  return (
    // <Card bordered={false}>
      <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
        <CollapsibleCard title="General">
        <FormRow mb={24} spans={[8, 8, 8]}>
          <FormItem
            name={"name"}
            type="text"
            placeholder="Enter Account Name"
            label="Name"
            disabled={!editable}
            rules={[
              { required: editable, message: "Company Name is required" }
            ]}
          />
          <FormItem
            name={"url"}
            type="text"
            placeholder="Enter Website URL"
            label="Website"
            disabled={!editable}
            //rules={[{ required: editable, message: "Website URL is required" }]}
          />
          <FormItem
            name={"phone"}
            type="text"
            placeholder="Enter Phone Number"
            label="Phone Number"
            disabled={!editable}
            //rules={[{ required: editable, message: "Phone Number is required" }]}
          />
        </FormRow>
          <FormRow mb={24} spans={[8,8,8]}>
            <FormItem
              name={"external_guid"}
              type="text"
              label="SaaS Account ID"
              placeholder="Enter External Account ID"
              tooltip="Tenant Id / Account Id of the application that sends the usage data."
              disabled={!editable}
              rules={[
                {
                  required: editable,
                  message: "External Account Id is required"
                }
              ]}
            />
            <FormItem
              name={"account_type"}
              type="selectkvp"
              placeholder="Select Account Type"
              label="Account Type"
              data={getAccountType()}
              size="middle"
              disabled={!editable}
              /*rules={[
                {
                  required: editable,
                  message: "Account Type is required"
                }
              ]}*/
            />
            <FormItem
              name={"status"}
              type="selectkvp"
              placeholder="Select Account Status"
              label="Account Status"
              data={getStatus()}
              size="middle"
              disabled={!editable}
             /* rules={[
                {
                  required: editable,
                  message: "Account Status is required"
                }
              ]}*/
            />

          </FormRow>
        <FormRow mb={24} spans={[8,8,8]}>
          <FormItem
            name={"account_channel"}
            type="selectkvp"
            label="Account Channel"
            placeholder="Enter Account Channel"
            disabled={!editable}
            data={[
              { label: "PLG", value: "plg" },
              { label: "Sales Led", value: "sales" },
              { label: "Partner", value: "partner" },
              { label: "AWS", value: "aws" },
              { label: "Azure", value: "azure" },
              { label: "GCP", value: "gcp" },
            ]}
          />
          <FormItem
            name={"parent_account_id"}
            type="selectkvp"
            placeholder="Select Parent Account"
            tooltip="Root organization account for this account."
            data={orgData}
            disabled={!editable}
            label="Parent Account"
          />
          <FormItem
            name={"payment_app_cust_id"}
            type="text"
            placeholder="Stripe Account Id"
            tooltip="Stripe account id for payment reconciliation."
            disabled={!editable}
            label="Stripe Account Id"
          />
        </FormRow>
        </CollapsibleCard>
        <CollapsibleCard title="Preferences">

          <FormRow mb = {24} spans={[8, 8, 8]}>
            <FormItem
              name={"billing_alert_email"}
              placeholder="Enter Billing Email"
              type="text"
              label="Billing Email"
              tooltip="Email id where billing and usage alerts will be sent."
              disabled={!editable}
              rules={[
                {
                  required: editable,
                  type: "email",
                  message: "Billing Email is required"
                }
              ]}
            />
            <FormItem
              name={"invoice_email"}
              placeholder="Enter Invoice Email"
              type="text"
              label="Invoice Email"
              disabled={!editable}
              /*rules={[
                {
                  required: editable,
                  type: "email",
                  message: "Invoice Email is required"
                }
              ]}*/
            />
            <FormItem
              name={"overage_alert_email"}
              placeholder="Enter Overages Email"
              type="text"
              label="Overages Email"
              disabled={!editable}
              /*rules={[
                {
                  required: editable,
                  type: "email",
                  message: "Overages Email is required"
                }
              ]}*/
            />
          </FormRow>
          <FormRow>
            <FormItem
              name={"payment_autocollection"}
              type="singlecheckbox"
              label="Payment Auto-Collection"
              size="middle"
              disabled={!editable}
              valuePropName="checked"
            />
          <FormItem
            name={"billing_alerts"}
            type="singlecheckbox"
            label="Billing Alerts"
            size="middle"
            disabled={!editable}
            valuePropName="checked"
          />

          <FormItem
            name={"invoice_by_email"}
            type="singlecheckbox"
            label="Invoice Alerts"
            size="middle"
            disabled={!editable}
            valuePropName="checked"
          />

          <FormItem
            name={"overages_alerts"}
            type="singlecheckbox"
            label="Overages Alerts"
            size="middle"
            disabled={!editable}
            valuePropName="checked"
          />

          </FormRow>

        </CollapsibleCard>
    <CollapsibleCard title="Address" defaultKey={1}>
        <FormRow mb={24} spans={[8, 8, 8]}>
          <FormItem
            name={"address_line1"}
            type="text"
            label="Address Line 1"
            placeholder="Enter Address Line 1"
            disabled={!editable}
            /*rules={[
              {
                required: editable,
                message: "Address Line 1 is required"
              }
            ]}*/
          />
          <FormItem
            name={"address_line2"}
            type="text"
            placeholder="Enter Address Line 2"
            label="Address Line 2"
            disabled={!editable}
          />
          <FormItem
            name={"address_line3"}
            type="text"
            placeholder="Enter Address Line 3"
            label="Address Line 3"
            disabled={!editable}
          />
        </FormRow>
        <FormRow mb={24} spans={[8, 8, 4, 4]}>
          <FormItem
            name={"country"}
            type="selectkvp"
            placeholder="Enter Country Name"
            label="Country"
            data={countries}
            disabled={!editable}
            /*rules={[
              { required: editable, message: "Country Name is required" }
            ]}*/
            onChange={onCountryChange}
            filterOption={(input, option) =>
              option.props.children
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0 ||
              option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          />
          <FormItem
            name={"state"}
            type="selectkvp"
            placeholder="Enter State"
            data={states}
            label="State"
            disabled={!editable}
            //rules={[{ required: editable, message: "State is required" }]}
            onChange={onStateChange}
          />
          <FormItem
            name={"city"}
            type="text"
            placeholder="Enter City"
            //data={cities}
            label="City"
            disabled={!editable}
            //rules={[{ required: editable, message: "City is required" }]}
          />
          <FormItem
            name={"zip"}
            type="text"
            label="Zip"
            placeholder="Enter Zip Code"
            disabled={!editable}
            /*rules={[
              {
                required: editable,
                message: "Zip Code is required"
              }
            ]}*/
          />
        </FormRow>
       </CollapsibleCard>

      </Form>
   // </Card>
  );
};

export default CustomerBasicInfo;
