import { Form } from "antd";
import { Infotip } from "../../common";
import {
  TextInput,
  SelectField,
  TextAreaField,
  InputNumberField,
  CheckboxField,
  PasswordField,
  RadioField,
  SearchSelect,
  TagInputField,
  SingleCheckbox,
  DatePickerField,
  CascaderField,
  SwitchInputField,
  SearchField,
  DateRangeField,
  SelectFieldKVP,
  CopyClipboardField,
  CurrencyField,
  InputWithCurrency
} from "./InputFields";

// FormItem: the default form item component
// size: default to "middle" (changed from large on 9/21/2022)
// width: do not specify width on the compoennt, rather use a <Col span={n}> to contain a form item

export const FormItem = (props) => {
  const formLabel = props.tooltip ? (
    <Infotip t={props.label} tt={props.tooltip} />
  ) : (
    props.label
  );
  return (
    <Form.Item
      name={props.name}
      label={
        !props.noLabel && (
          <label
            style={{
              color: "#6F7378",
              fontSize: "14px",
              fontWeight: "normal"
            }}
          >
            {props.type === "singlecheckbox" ? "" : formLabel}
          </label>
        )
      }
      rules={props.rules}
      key={props.key}
      valuePropName={props.valuePropName}
      style={{
        color: "#6F7378",
        fontSize: "14px",
        marginBottom: props.mb ?? "0px",
        fontWeight: "normal"
      }}
      //className={"rounded-corners"}
      labelCol={props.labelCol}
      wrapperCol={props.wrapperCol}
      labelAlign={props.labelAlign}
    >
      {(() => {
        switch (props.type) {
          case "text":
            return (
              <TextInput
                name={props?.name}
                placeholder={props?.placeholder}
                type={props.type}
                size={props.size || "middle"}
                style={props.style}
                disabled={props.disabled}
                id={props.id}
                value={props?.value}
                onChange={props.onChange}
                className={props?.className}
                defaultValue={props.defaultValue}
                prefix={props.prefix}
                addonBefore={props.addonBefore}
                addonAfter={props.addonAfter}
                bordered={props.bordered}
                readOnly={props.readOnly}
              />
            );
          case "select":
            return (
              <SelectField
                className={props?.className}
                data={props.data}
                name={props?.name}
                label={props.label}
                size={props.size || "middle"}
                style={props.style}
                onChange={props.onChange}
                value={props?.value}
                defaultValue={props.defaultValue}
                placeholder={props?.placeholder}
                text={props.text}
                mode={props.mode}
                readOnly={props.readOnly}
              />
            );
          case "selectkvp":
            return (
              <SelectFieldKVP
                className={props?.className}
                data={props.data}
                name={props?.name}
                label={props.label}
                size={props.size || "middle"}
                style={props.style}
                onChange={props.onChange}
                value={props?.value}
                defaultValue={props.defaultValue}
                placeholder={props?.placeholder}
                text={props.text}
                mode={props.mode}
                disabled={props.disabled || props.readOnly}
                filterOption={props.filterOption}
              />
            );
          case "textarea":
            // the default font size in TextArea is 14px, rather than 16px as in TextInput
            return (
              <TextAreaField
                placeholder={props?.placeholder}
                className={props?.className}
                name={props?.name}
                id={props.id}
                size={props.size || "middle"}
                style={props.style}
                onChange={props.onChange}
                value={props?.value}
                text={props?.text}
                readOnly={props.readOnly}
              />
            );
          case "date":
            return (
              <DatePickerField
                style={{
                  width: "100%",
                  ...props.style
                }}
                size={props.size || "middle"}
                className={props.className}
                onChange={props.onChange}
                defaultValue={props.defaultValue}
                format={props.format}
              />
            );
          case "copyclip":
            return (
              <CopyClipboardField
                name={props?.name}
                placeholder={props?.placeholder}
                type={props.type}
                size={props.size || "middle"}
                style={props.style}
                disabled={props.disabled}
                id={props.id}
                value={props?.value}
                onChange={props.onChange}
                className={props?.className}
                defaultValue={props.defaultValue}
                prefix={props.prefix}
                addonBefore={props.addonBefore}
                addonAfter={props.addonAfter}
                bordered={props.bordered}
                readOnly={props.readOnly}
              />
            );
          case "number":
            return (
              <InputNumberField
                style={{
                  width: "100%", // force the field to fill the grid column
                  ...props.style
                }}
                defaultValue={props.defaultValue}
                size={props.size || "middle"}
                className={props.className}
                min={props.min}
                placeholder={props.placeholder}
                max={props.max}
                suffix={props.suffix}
                onChange={props.onChange}
                value={props.value}
                readOnly={props.readOnly}
                disabled={props.disabled}
                addonBefore={props.addonBefore}
                addonAfter={props.addonAfter}
              />
            );
          case "currency":
            return (
              <CurrencyField
                symbol={props.symbol}
                defaultValue={props.defaultValue}
                min={props.min}
                onChange={props.onChange}
                value={props.value}
                readOnly={props.readOnly}
                disabled={props.disabled}
              />
            );
          case "radio":
            return (
              <RadioField
                type={props.type}
                radioData={props.radioData}
                style={props.style}
                onChange={props.onChange}
                name={props.name}
                value={props?.value}
              />
            );
          case "checkbox":
            return (
              <CheckboxField
                options={props.options}
                name={props.name}
                className={props.className}
                checked={props.checked}
                style={props.style}
                defaultValue={props.defaultValue}
                onChange={props.onChange}
              />
            );
          case "singlecheckbox":
            return (
              <SingleCheckbox
                type={props.type}
                data={props.data}
                defaultChecked={props.defaultChecked}
                key={props.key}
                checked={props.checked}
                name={props.name}
                label={props.label}
                className={props.className}
                onChange={props.onChange}
                disabled={props.disabled}
              />
            );
          case "password":
            return (
              <PasswordField
                name={props?.name}
                placeholder={props?.placeholder}
                type={props.type}
                size={props.size || "middle"}
                style={props.style}
                disabled={props.disabled}
                id={props.id}
                value={props?.value}
                onChange={props?.onChange}
                className={props?.className}
              />
            );
          case "email":
            return (
              <TextInput
                name={props?.name}
                placeholder={props?.placeholder}
                type={props.type}
                size={props.size || "middle"}
                style={props.style}
                disabled={props.disabled}
                id={props.id}
                value={props?.value}
                onChange={props?.onChange}
                className={props?.className}
              />
            );
          case "url":
            return (
              <TextInput
                name={props?.name}
                placeholder={props?.placeholder}
                type={props.type}
                size={props.size || "middle"}
                style={props.style}
                disabled={props.disabled}
                id={props.id}
                value={props?.value}
                onChange={props?.onChange}
                className={props?.className}
              />
            );
          case "searchSelect":
            return (
              <SearchSelect
                data={props.data}
                size={props.size || "middle"}
                style={props.style}
                placeholder={props.placeholder}
                defaultValue={props.defaultValue}
                isdisabled={props.isdisabled}
                prefix={props.prefix}
                bordered={props.bordered}
                name={props.name}
              />
            );

          case "multiInput":
            return (
              <TagInputField
                value={props.value}
                onChange={props.onChange}
                name={props.name}
                placeHolder={props.placeHolder}
              />
            );
          case "radiogroup":
            return (
              <RadioField
                ref={props.ref}
                extraData={props.extraData}
                value={props.value}
                name={props.name}
                onChange={props.onChange}
                className={props.className}
              />
            );
          case "multiselect":
            return (
              <CascaderField
                ref={props.ref}
                extraData={props.extraData}
                className={props.className}
                options={props.options}
                value={props.value}
                name={props.name}
                onChange={props.onChange}
                style={props.style}
                placeholder={props.placeholder}
                defaultValue={props.defaultValue}
              />
            );
          case "search":
            return (
              <SearchField
                name={props.name}
                placeholder={props.placeholder}
                onSearch={props.onSearch}
                className={props.className}
              />
            );
          case "switch":
            return (
              <SwitchInputField
                name={props.name}
                size={props.size || "middle"}
                className={props.className}
                key={props.key}
                disabled={props.disabled}
                checked={props.checked}
                onChange={props.onChange}
                checkedChildren={props.checkedChildren}
                unCheckedChildren={props.unCheckedChildren}
                defaultChecked={props.defaultChecked}
              />
            );
          case "dateRange":
            return (
              <DateRangeField
                defaultValue={props?.defaultValue}
                dateFormat={props?.dateFormat}
                onChange={props?.onChange}
              />
            );
          case "inputWithCurrency":
            return (
              <InputWithCurrency
                style={{
                  width: "100%", // force the field to fill the grid column
                  ...props.style
                }}
                currenyValue={props.currenyValue}
                onCurrencyChange={props.onCurrencyChange}
                defaultValue={props.defaultValue}
                size={props.size || "middle"}
                className={props.className}
                min={props.min}
                placeholder={props.placeholder}
                max={props.max}
                onChange={props.onChange}
                value={props.value}
                readOnly={props.readOnly}
                disabled={props.disabled}
              />
            );
          default:
            return props.children;
        }
      })()}
    </Form.Item>
  );
};
