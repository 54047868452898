import React, { useState, useEffect } from "react";
import { Table } from "antd";
import { Link, useSearchParams } from "react-router-dom";
import { getAllBills } from "../../../billing/billing_helper";
import ListTable from "../../../ListTable";
import {Box, PlusButton, Spinner} from "../../../common";
import { withTooltip, formatDate, formatMoney } from "../../../common/table";
import { StatusTag } from "../../../common/tags";
import { error } from "../../../../utils/commonActions";
import CustomerBillDetailModal from "./CustomerBillDetailModal";
import { getBillingPeriod } from "../../../billing/billing_helper";
import {ArrowUpOutlined} from "@ant-design/icons";

const BiilsList = () => {
  const [searchParams] = useSearchParams();
  const [bills, setBills] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [billId, setBillId] = useState();
  const externalGUID = searchParams.get("external-id") ?? "";

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        if (externalGUID) setBills((await getAllBills(externalGUID)).result);
      } catch (err) {
        console.error(err);
        error("Error fetching bills");
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const handleClick = (id) => () => {
    setBillId(id);
    setModalOpen(true);
  };

  const columns = [
    // column headers for the table
    {
      title: "Bill Number",
      dataIndex: "bill_number",
      width: "15%",
      render: (_, record) => (
        <Link to={`/billing/details/${record.uid}`} className="link-color" >
          Bill No: {record.bill_number}
        </Link>

        // <a onClick={handleClick(record.uid)}>Bill No: {record.bill_number}</a>
      )
    },
    {
      title: "Bill Status",
      dataIndex: "status",
      width: "15%",
      render: (text) => <StatusTag status={text} />
    },
    {
      title: "Bill Amount",
      dataIndex: "charges",
      width: "20%",
      render: (_, record) => formatMoney(record.currency_code, record.total)
    },
    {
      title: "Billing Period",
      dataIndex: "last_update_date",
      width: "20%",
      render: (_, record) => getBillingPeriod(record.start_at, record.end_at)
    },
    {
      title: "Bill Date",
      dataIndex: "last_update_date",
      width: "10%",
      render: (_, record) => formatDate(record.billing_date)
    },
    {
      title: "Due Date",
      dataIndex: "due_date",
      width: "10%",
      render: (_, record) => formatDate(record.due_date)
    }
  ];

  return isLoading ? (
    <Box height="70vh">
      <Spinner />
    </Box>
  ) : (
    <>
      <CustomerBillDetailModal
        id={billId}
        open={isModalOpen}
        onCancel={() => setModalOpen(false)}
      />
      <ListTable
        data={bills}
        columns={withTooltip(columns)}
        pagination="pagination"
      />

    </>
  );
};

export default BiilsList;
