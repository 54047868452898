const STORAGE_KEY = {
  SESSION_DETAILS: "sessionDetails",
  ACCESS_TOKEN: "accessToken",
  REFRESH_TOKEN: "refreshToken",
  ID_TOKEN: "idToken",
  TOKEN_TYPE: "Bearer",
  TOKEN_TIMESTAMP: "tokenTimeStamp", // time of auth received in httpheaders.
  USER_EMAIL: "userEmail", // sign in user's email used to sign in.
  CUSTOMER_ID: "cid",
  SERVICE_ID: "serviceId",
  PLAN_ID: "planId",
  SERVICE: "service",
  PLAN: "plan",
  CUSTOMER_DATA: "customer",
  ROLE: "role",
  USER_INFO: "user_info",
};

export default STORAGE_KEY;
