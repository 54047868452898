import React from "react";
import EmptyPage from "../../components/paliq/pages/emptyPage";

const CreateCustomerStub = () => {
  return (
    <EmptyPage
      heading="Accounts"
      subHeading="Add accounts and assign plans to them"
      url="/customers/create"
      buttonText="Add Account"
    />
  );
};

export default CreateCustomerStub;
