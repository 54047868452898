import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { List, Modal, Upload, Select } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import styled from "@emotion/styled";

import { APICONFIG } from "../../../services/apiConfig";
import { getRequest, postRequest } from "../../../services";
import {
  getAllServices,
  getService
} from "../../../services/serviceFormActions";
import { getRefreshTokenFromLS } from "../../../utils/helper";

import { StyledButton, Box, Center, FlexRow, Title, Text } from "../../common";
import { StatusTag } from "../../common/tags";
import { FormRow } from "../../common/form";

import {
  getMetersAndMeterGroups,
  getCSVSpecsForMeterOrMeterGroup,
  generateSampleCSVData
} from "../../meters/meters-helpers";

function UploadUsageModal({ visible, setVisible }) {
  const [confirmLoading, setConfirmLoading] = useState(false);

  const navigate = useNavigate();

  const { isLoading, data: allServices } = useQuery(
    ["allservices"],
    () => getAllServices(),
    { staleTime: Infinity, select: (res) => res.services }
  );

  // console.log({ allServices });

  const [selectedService, setSelectedService] = useState();
  const [metersAndGroups, setMetersAndGroups] = useState([]);
  const [meterIndex, setMeterIndex] = useState();
  const [csvSpecs, setCsvSpecs] = useState();
  const [sampleCSV, setSampleCSV] = useState();

  const [_uploadStatus, _setUploadStatus] = useState({});

  const renderStatusForFile = (uid) => {
    const fileStatus = _uploadStatus[uid];
    if (!fileStatus) return null;
    return Object.values(fileStatus).join(" ");
  };

  const updateStatusForFile = (uid, key, message) => {
    const fileStatus = _uploadStatus[uid] || {};
    fileStatus[key] = message;
    _setUploadStatus({
      ..._uploadStatus,
      [uid]: fileStatus
    });
  };

  const updateCSVSpecsAndDataAtIndex = (svc, mNmg, index) => {
    const mg = mNmg[index];
    const specs = getCSVSpecsForMeterOrMeterGroup(svc, mg.meter, mg.meterGroup); // only one of mg.meter and mg.meterGroup has value at any given time
    const sampleCode = generateSampleCSVData(specs, 10);
    setCsvSpecs(specs);
    setSampleCSV(sampleCode);
  };

  const handleServiceItemClick = (service) => {
    setSelectedService(service);
    if (
      service.service_meters.meters &&
      service.service_meters.meters.length > 0
    ) {
      const mg = getMetersAndMeterGroups(service);
      setMetersAndGroups(mg);
      setMeterIndex(0);
      updateCSVSpecsAndDataAtIndex(service, mg, 0);
    } else {
      setMetersAndGroups([]);
      setMeterIndex(undefined);
      setCsvSpecs(undefined);
      setSampleCSV(undefined);
    }
  };

  const handleMeterItemClick = (index) => {
    setMeterIndex(index);
    updateCSVSpecsAndDataAtIndex(selectedService, metersAndGroups, index);
  };

  const renderCSV = ({ heading, rows }) => {
    if (!csvSpecs) return null;
    const output = [];
    if (heading) output.push(csvSpecs.fields.map((f) => f.heading).join(","));
    for (let row of (sampleCSV || []).slice(0, rows)) {
      const rowText = row.map((f) => f.value).join(",");
      output.push(rowText);
    }
    return output.join("\n");
  };

  const beforeUpload = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = () => {
        if (!csvSpecs) {
          updateStatusForFile(
            file.uid,
            "field-check",
            `CSV field check FAILED. Please select the service and meter/meter group first.`
          );
          resolve(false); // block uploading
          return;
        }
        const first1k = reader.result.substring(0, 1000);
        const lines = first1k.split("\n");
        const fields = lines[0].split(",").map((f) => f.trim());
        const missingFields = [];
        csvSpecs.fields.forEach((expectedField) => {
          if (!fields.includes(expectedField.heading))
            missingFields.push(expectedField.heading);
        });

        if (missingFields.length > 0) {
          updateStatusForFile(
            file.uid,
            "field-check",
            `CSV field check FAILED. Missing fields: ${missingFields.join(
              ", "
            )}.`
          );
          resolve(false); // block uploading
        } else {
          updateStatusForFile(
            file.uid,
            "field-check",
            "CSV field check PASSED."
          );
          resolve(true); // proceed to upload, might still fail
        }
      };
    });
  };

  const renderUploadListItem = (originalNode, file, fileList, actions) => {
    const statusNode = renderStatusForFile(file.uid);
    return statusNode ? (
      <div>
        {originalNode}
        <Box textAlign="right">
          <Text fontSize="12px" lineHeight="2">
            {statusNode}
          </Text>
        </Box>
      </div>
    ) : (
      <div>{originalNode}</div>
    );
  };

  const uploadAction = async (file) => {
    try {
      const ref_token = getRefreshTokenFromLS();
      const res = await getRequest(APICONFIG.getUsageS3Url + "/" + file.name, {
        refresh_token: ref_token
      });
      console.log("res = ", res);
      if (res?.job_id) {
        console.log("got s3 url successfully ");
        console.log(res?.upload_uri);
        return res?.upload_uri;
      } else {
        console.log(res?.message);
        updateStatusForFile(
          file.uid,
          "upload",
          "Upload FAILED. Check your Internet connection and try again."
        );
        return "";
      }
    } catch (err) {
      console.log("upload failed");
      updateStatusForFile(
        file.uid,
        "upload",
        "Upload FAILED. Check your Internet connection and try again."
      );
      setConfirmLoading(false);
      Promise.reject(err);
    }
  };

  const handleUploaderChange = ({ file }) => {
    switch (file.status) {
      case "uploading":
        updateStatusForFile(file.uid, "upload", "Uploading...");
        break;
      case "done":
        updateStatusForFile(file.uid, "upload", "Upload DONE.");
        break;
      case "error":
        updateStatusForFile(
          file.uid,
          "upload",
          "Upload FAILED. Check your internet connection and try again."
        );
        break;
    }
  };

  const handleHide = () => {
    _setUploadStatus({});
    setVisible(false);
  };

  const footer = (
    <StyledButton type="primary" mr="8px" onClick={handleHide}>
      Hide
    </StyledButton>
  );

  const headers = { "x-amz-server-side-encryption": "aws:kms" };

  return (
    <Modal
      visible={visible}
      onCancel={handleHide}
      maskClosable={false}
      width={1200}
      footer={footer}
    >
      <Box>
        <FormRow spans={[12, 12]} mb={2}>
          {/* 
          <TitledScrollableList
            title="Select a service"
            dataSource={allServices || []}
            renderItem={(service) => (
              <StyledListItem
                selected={service.uid === selectedService?.uid}
                onClick={() => handleServiceItemClick(service)}
              >
                {service.name}
              </StyledListItem>
            )}
            loading={isLoading}
          />
          */}
          <SelectService
            title="Select a service"
            dataSource={allServices || []}
            onSelect={handleServiceItemClick}
            loading={isLoading}
          />
          <TitledScrollableList
            title="Select a service meter or meter group"
            dataSource={metersAndGroups}
            renderItem={(item, index) => (
              <MeterListItem
                selected={index === meterIndex}
                onClick={() => handleMeterItemClick(index)}
                meter={item.meter}
                meterGroup={item.meterGroup}
              />
            )}
          />
        </FormRow>
        <Box>
          <Title level={5}>CSV Fields</Title>
          <CodeBox>
            <pre>{renderCSV({ heading: true, rows: 0 })}</pre>
          </CodeBox>
        </Box>
        <Box>
          <Title level={5}>Sample Data</Title>
          <CodeBox>
            <pre>{renderCSV({ heading: false, rows: 5 })}</pre>
          </CodeBox>
        </Box>

        <DraggerWrap showList={Object.keys(_uploadStatus).length > 0}>
          <Upload.Dragger
            accept=".csv"
            action={uploadAction}
            maxCount={5}
            method="PUT"
            headers={headers}
            listType="text"
            beforeUpload={beforeUpload}
            itemRender={renderUploadListItem}
            onChange={handleUploaderChange}
            disabled={!csvSpecs}
          >
            {/*  <StyledButton type="primary">Select File</StyledButton> */}
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
            <p className="ant-upload-hint">
              You can select one or multiple files. Make sure you select the
              files from the same service meter, with the same fields.
            </p>
          </Upload.Dragger>
        </DraggerWrap>
      </Box>
    </Modal>
  );
}

const TitledScrollableList = ({ title, dataSource, renderItem, ...props }) => (
  <Box>
    <Title level={5}>{title}</Title>
    <Box
      height="160px"
      overflow="auto"
      border="1px solid rgba(140, 140, 140, 0.35)"
    >
      <List dataSource={dataSource} renderItem={renderItem} {...props} />
    </Box>
  </Box>
);

const SelectService = ({ title, dataSource, onSelect, ...props }) => {
  return (
    <Box>
      <Title level={5}>{title}</Title>
      <Select
        options={dataSource.map((item) => ({
          label: item.name,
          value: item.uid,
          item
        }))}
        onChange={(value, option) => onSelect(option.item)}
        style={{ width: "100%" }}
        {...props}
      />
    </Box>
  );
};

const StyledListItem = styled(List.Item)`
  padding: 8px 16px;
  cursor: pointer;
  background-color: ${(props) => (props.selected ? "#f0f0f0" : "white")};
`;

const MeterListItem = ({ meter, meterGroup, ...props }) => (
  <StyledListItem {...props}>
    {meter}
    {meter && <StatusTag status="meter" marginLeft={2} />}
    {meterGroup}
    {meterGroup && <StatusTag status="meter group" marginLeft={2} />}
  </StyledListItem>
);

const CodeBox = styled(Box)`
  border: 1px solid rgba(140, 140, 140, 0.35);
  padding: 8px;
  margin-bottom: 16px;
  min-height: 35px;
  & pre {
    font-size: 12px;
    margin-bottom: 0;
  }
`

const DraggerWrap = styled(Box)`
  margin: 16px 0 0 0;
  height: 200px;
  overflow: auto;
  border: 1px solid rgba(140, 140, 140, 0.35);
  & .ant-upload.ant-upload-drag {
    height: ${(props) => (props.showList ? "60%" : "100%")};
    // before any file is uploaded, the drop area is full height
    // after at least one file is uploaded (or attempted), the drop area is reduced to half the height to make room for the list
    border: none;
  }
  & .ant-upload-drag-icon.ant-upload-drag-icon.ant-upload-drag-icon {
    margin-bottom: 8px;
  }
  & .ant-upload-list {
    padding: 0 16px;
  }
`;

export default UploadUsageModal;
