import React, { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { ArrowUpOutlined, WalletOutlined } from "@ant-design/icons";
import { ActionGroup, ActionItem, withTooltip } from "../common/table";
import ListTable from "../ListTable";
import { Spinner, PlusButton } from "../common";
import { getAllCustomers } from "../../services/customerFormAction";
import { getCustomerCreditsViewEditKey } from "../menus/menuKeys.constants";
import CreateCustomerStub from "../../pages/customers/CreateCustomerStub";

const formatAccountType = (account_type) => {
  if (account_type == "customer") return "Customer";
  else if (account_type == "partner") return "Partner";
};

const CustomerList = ({ customerList }) => {
  const navigate = useNavigate();
  const {
    isLoading,
    error,
    data: dataSource,
    isFetching,
    refetch
  } = useQuery(["allcustomers"], () => getAllCustomers(), {
    staleTime: Infinity,
    select: ({ accounts }) => accounts
  });
  useEffect(() => {
    refetch();
  }, []);

  if (isLoading || isFetching) return <Spinner />;

  if (error) return "An error has occurred: " + error.message;

  const columns = [
    {
      title: "Company Name",
      dataIndex: "name",
      width: "30%",
      render: (_, record) => (
        <Link
          to={`/customers/${record?.id}/edit?external-id=${record.external_guid}`}
          className="link-color"
        >
          {record?.name}
        </Link>
      )
    },
    {
      title: "Billing Email",
      dataIndex: "billing_alert_email",
      width: "20%"
    },
    {
      title: "SaaS Tenant Id",
      dataIndex: "external_guid",
      width: "20%"
    },
    {
      title: "Account Type",
      dataIndex: "account_type",
      width: "15%",
      render: (_, record) => formatAccountType(record.account_type)
    },
    {
      title: "Root Account",
      dataIndex: "is_root",
      width: "10%",
      render: (_, record) =>
        record?.is_root_account ? <span>Yes</span> : <span>No</span>
    }
    /* {
      title: "Actions",
      align: "center",
      dataIndex: "operation",
      width: "5%",
      render: (_, record) =>
        dataSource.length >= 1 ? (
          <ActionGroup>
            <ActionItem
              tooltip="Account Credits"
              icon={<WalletOutlined />}
              onClick={() => {
                navigate(getCustomerCreditsViewEditKey(record?.id));
              }}
            />
          </ActionGroup>
        ) : null
    }*/
  ];

  const onSearch = (value) => console.log(value);
  const onChange = (value) => console.log(value);
  const onButtonClick = () => navigate("/customers/create");
  const onExtraButtonClick = () => navigate("/customers/upload");

  if (dataSource && dataSource.length >= 1) {
    return (
      <ListTable
        title="Accounts"
        titleButtons={[
          <PlusButton
            key="1"
            type="default"
            size="large"
            icon={<ArrowUpOutlined />}
            title="Upload Accounts"
            onClick={onExtraButtonClick}
            mr={3}
          />,
          <PlusButton
            key="2"
            size="large"
            title="Create New Account"
            onClick={onButtonClick}
          />
        ]}
        data={dataSource}
        columns={withTooltip(columns)}
        onSearch={onSearch}
        onChange={onChange}
        pagination="pagination"
        rowKey={(record) => record.id}
      />
    );
  } else {
    return <CreateCustomerStub />;
  }
};

export default CustomerList;
