/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Box, FlexRow, PlusButton, Title } from "../common";
import { Button, Tooltip as AntTooltip } from "antd";
import { InfoCircleOutlined, PlusOutlined } from "@ant-design/icons";
import "../../assets/scss/_vars.scss";
import { CardContainer } from "./CardContainer";
import React from "react";

// Page-level Building blocks
// Use them for simplicity

// PageTitle: standard page title, with an optional `+ Entity` button
// note it is supposed to be placed at [0,0] of the page, since it handles spacing internally
// TODO: themify the spacing constants
export const PageTitle = ({ title, buttons }) => (
  <FlexRow mt={1} mb={16}>
    {/* grow the title box so it takes all available space, thus pushing the button to the very end */}
    <Box
      flex={1}
      css={css`
        // things with css can go crazy very fast, no matter how hard I try to remain elegant
        // Antd has a rule with higher specifity on line-height and margin
        // In order to overrule, I have to put these rules here, instead of directly on the <Title> tag
        & h2 {
          line-height: 40px;
          margin-bottom: 0;
          text-transform: capitalize;
          font-weight: 800;
          font-size: 25px;
          //color: #009879;
        }
      `}
    >
      <Title level={2}>{title || ""}</Title>
    </Box>
    {buttons}
  </FlexRow>
);

export const CodePageTitle = ({ title, buttons }) => (
  <FlexRow my={10} mb={20}>
    {/* grow the title box so it takes all available space, thus pushing the button to the very end */}
    <Box
      flex={1}
      css={css`
        // things with css can go crazy very fast, no matter how hard I try to remain elegant
        // Antd has a rule with higher specifity on line-height and margin
        // In order to overrule, I have to put these rules here, instead of directly on the <Title> tag
        & h2 {
          line-height: 40px;
          margin-bottom: 0;
          font-weight: 600;
          color: #0b0705;
          font-size: 25px;
        }
      `}
    >
      <Title level={2}>{title || ""}</Title>
    </Box>
    {buttons}
  </FlexRow>
);

export const SubTitle = ({ title, buttons }) => (
  <FlexRow>
    {/* grow the title box so it takes all available space, thus pushing the button to the very end */}
    <Box
      flex={1}
      css={css`
        // things with css can go crazy very fast, no matter how hard I try to remain elegant
        // Antd has a rule with higher specifity on line-height and margin
        // In order to overrule, I have to put these rules here, instead of directly on the <Title> tag
        & h5 {
          line-height: 25px;
          margin-bottom: 0;
          text-transform: capitalize;
        }
      `}
    >
      <Title level={5}>{title || ""}</Title>
    </Box>
    {buttons}
  </FlexRow>
);

// SectionTitle: a title for a section or embedded table, with an optional `+ Sub Entity` button
export const SectionTitle = ({
  title,
  onButtonClick,
  buttonText,
  disabled,
  tooltip,
  ...props
}) => (
  <FlexRow mb={3}>
    <Box
      css={css`
        margin-right: 16px;
        & h5 {
          line-height: 32px;
          margin-bottom: 1;
          font-weight: 600;
          font-size: 16px;
        }
      `}
    >
      {tooltip ? (
        <AntTooltip title={tooltip} placement="top">
          <>
            <Title
              level={5}
              {...props}
              css={css`
                display: inline-block;
              `}
            >
              {title || ""}
            </Title>
            <InfoCircleOutlined
              css={css`
                margin-left: 6px;
                vertical-align: super;
                & svg {
                  fill: rgba(0, 0, 0, 0.45);
                  width: 12px;
                  height: 12px;
                }
              `}
            />
          </>
        </AntTooltip>
      ) : (
        <Title
          level={5}
          {...props}
          css={css`
            height: 10px;
          `}
        >
          {title || ""}
        </Title>
      )}
      {onButtonClick ? (
        <PlusButton
          onClick={onButtonClick}
          title={buttonText}
          disabled={disabled}
        />
      ) : null}
    </Box>
  </FlexRow>
);

// TabContainer
export const TabContainer = ({ children }) => (
  <Box
    mx={10}
    my={10}
    css={css`
      // remove the extra padding on antd Card
      & .ant-card-head {
        font-size: 20px;
        padding: 0;
      }
      & .ant-card-body {
        padding: 10px 0;
      }

      // expand number input to fill all avaiable space
      // this helps the input inside editable tables, which is not using our standard FormItem component
      & div.ant-input-number {
        width: 100%;
      }

      // sub-table tweaks
      // we want sub-tables to center under the main table, instead of pushing to once side or another
      & .ant-table.ant-table-small .ant-table.ant-table.ant-table {
        margin-left: 80px;
        margin-right: 80px;
      }

      // remove the right padding for the last table cell in a row
      // & .ant-table-cell.ant-table-cell:last-child {
      //   padding-right: 0;
      // }
      //
    `}
  >
    {children}
  </Box>
);
