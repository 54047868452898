import React, { useEffect, useState } from "react";
import moment from "moment";
import { getRequest } from "../../services";
import { APICONFIG } from "../../services/apiConfig";
import { useParams } from "react-router";
import { Divider, Button } from "antd";
import { Box, Title, FlexCol, FlexRow, Spinner } from "../common";
import { EditOutlined } from "@ant-design/icons";
import { CURRENCY_DATA } from "../../constants/globalStaticConstants";
import { useNavigate } from "react-router-dom";
import { PAYMENT_METHOD_TYPE } from "../../constants/globalConstants";
import { LeftAlignedCardPanel } from "../common/InfoPanel";
import { PageTitle } from "../common/page";

const PaymentDetail = ({ uid, onBack }) => {
  const { id: id } = useParams();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [payment, setPayment] = useState({});
  const [paymentMethod, setPaymentMethod] = useState({});

  useEffect(() => {
    setLoading(true);
    (async function getPayment() {
      try {
        let paymentId = null;
        if (uid) {
          paymentId = uid;
        } else if (id) {
          paymentId = id;
        }
        if (paymentId) {
          const _payment = (
            await getRequest(`${APICONFIG.getPaymentsUrl}/${paymentId}`)
          ).data;
          if (_payment.transaction_date) {
            _payment.transaction_date = moment(_payment.transaction_date);
          }
          setPayment(_payment);
          const _paymentMethod = (
            await getRequest(
              `${APICONFIG.accountPaymentMethodUrl}/${_payment.payment_method_uid}`
            )
          ).data;
          setPaymentMethod(_paymentMethod);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  if (isLoading) return <Spinner />;

  return (
    <Box>
      <PageTitle title={"Payment details"} />
      <Divider />
      <LeftAlignedCardPanel
        cols={2}
        attrs={[
          [
            "Amount",
            payment?.payment_currency &&
              CURRENCY_DATA.find(
                ({ value }) => payment?.payment_currency == value
              )?.symbol + payment?.payment_amount
          ],
          ["Transaction Number", payment?.transaction_id],
          ["Status", ""],
          ["Comments", payment?.comments ? payment?.comments : "No comments"]
        ]}
      />
      <PageTitle title={"Payment method details"} />
      <Divider />
      <LeftAlignedCardPanel
        cols={2}
        attrs={[
          ["Name", paymentMethod?.name],
          [
            "Type",
            paymentMethod?.type &&
              PAYMENT_METHOD_TYPE.find(
                ({ value }) => value === paymentMethod?.type
              ).label
          ],
          ["Active", paymentMethod?.is_active ? "Yes" : "No"],
          ["Primary", paymentMethod?.is_primary ? "Yes" : "No"]
        ]}
      />
      <Button
        size="large"
        onClick={() => {
          if (uid) {
            onBack();
          } else {
            navigate("/payments");
          }
        }}
        style={{ float: "right", marginTop: "24px", width: "120px" }}
      >
        Back
      </Button>
    </Box>
  );
};

export default PaymentDetail;
