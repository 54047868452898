import { Breadcrumb } from "antd";
import { Link } from "react-router-dom";

const CustomBreadcrumb = ({ items }) => {
  return (
    <Breadcrumb separator=">" style={{ marginBottom: "24px" }}>
      {items.map(({ route, label }) => (
        <Breadcrumb.Item key={route}>
          <Link to={route}>{label}</Link>
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default CustomBreadcrumb;
