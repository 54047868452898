import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { Box, Spinner, Title } from "../../../common";
import { withTooltip, formatDate, formatMoney } from "../../../common/table";
import { Table, Button } from "antd";
import { useQuery } from "@tanstack/react-query";
import { getRequest } from "../../../../services";
import { getAccountPaymentsByExternalId } from "../../../../services/apiConfig";
import { info } from "../../../../utils/commonActions";
import CustomerPaymentMethodsList from "./CutomerPaymentMethodsList";
import { SectionTitle } from "../../../common/page";
import Sidebar from "../../../common/Sidebar";
import PaymentDetail from "../../../payments/PaymentDetail";
import { DollarCircleOutlined } from "@ant-design/icons";

const PaymentsList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const externalGUID = searchParams.get("external-id") ?? "";
  const tab = searchParams.get("tab") ?? "";
  const key = searchParams.get("key") ?? "payments";
  const transactionID = searchParams.get("transaction-id") ?? "";

  const { isLoading, error, data } = useQuery({
    queryKey: ["payments", externalGUID],
    queryFn: () =>
      getRequest(getAccountPaymentsByExternalId(externalGUID)).then(
        (r) => r["data"]
      )
  });

  if (isLoading)
    return (
      <Box height="70vh">
        <Spinner />
      </Box>
    );

  if (error)
    return <Box height="70vh">{"An error has occurred: " + error.message}</Box>;

  const onMenuClick = ({ key }) => {
    setSearchParams({ "external-id": externalGUID, tab, key });
  };

  let map = new Map();
  map.set("pre-pay", "Pre-Payment");
  map.set("partially_paid", "Partially Paid");

  const columns = [
    {
      title: "Transaction Id",
      dataIndex: "transaction_id",
      width: "25%",
      render: (_, record) => (
        <Button
          onClick={() => {
            setSearchParams({
              "external-id": externalGUID,
              tab,
              "transaction-id": record.uid
            });
          }}
          type="link"
        >
          {record.transaction_id}
        </Button>
      )
    },
    {
      title: "Amount",
      dataIndex: "payment_amount",
      width: "20%",
      render: (_, record) =>
        formatMoney(record.currency_code, record.payment_amount)
    },
    {
      title: "Payment Date",
      dataIndex: "transaction_date",
      width: "20%",
      render: (_, record) => formatDate(record.transaction_date)
    },
    {
      title: "Payment Method",
      dataIndex: "payment_method",
      width: "20%"
    },
    {
      title: "Invoice Number",
      dataIndex: "invoice_uid",
      width: "20%"
    }
  ];

  const sidebarData = [
    {
      route: "payments",
      label: "Payments",
      icon: <DollarCircleOutlined />,
      component: (
        <>
          <SectionTitle title={"Payments"} />
          <Table
            dataSource={data}
            columns={withTooltip(columns)}
            rowKey={(record) => record.uid}
            pagination="pagination"
          />
        </>
      )
    },
    {
      route: "payment_methods",
      label: "Payment Methods",
      icon: <DollarCircleOutlined />,
      component: <CustomerPaymentMethodsList />
    }
  ];

  const selectedComponent = (key) => {
    if (transactionID) {
      return (
        <PaymentDetail
          uid={transactionID}
          onBack={() => {
            setSearchParams({ "external-id": externalGUID, tab });
          }}
        />
      );
    }
    return sidebarData.find(({ route }) => route === key)?.component;
  };

  return isLoading ? (
    <Box height="70vh">
      <Spinner />
    </Box>
  ) : (
    <Box >
      <Sidebar data={sidebarData} selectedKey={key} onMenuClick={onMenuClick} />
      <Box style={{ marginLeft: "224px" }}>{selectedComponent(key)}</Box>
    </Box>
  );
};

export default PaymentsList;
