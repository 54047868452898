/** @jsxImportSource @emotion/react */
import { css, useTheme } from "@emotion/react";
import { Tag } from "antd";
import styled from "@emotion/styled";
import {
  background,
  border,
  color,
  flexbox,
  layout,
  position,
  space,
  typography
} from "styled-system";
import { Title } from "./index";

// StatusTag: tags that indicate status of a service (active, inactive, draft)

export const StatusTag = ({ status, ...props }) => {
  const theme = useTheme();
  if (!status) return null; // do not display tag if status is undefined
  const tagColors = theme.tags[status] || theme.tags.default;
  return (
    <StyledTag
      {...props}
      css={css`
        color: ${tagColors.text};
        background-color: ${tagColors.background};
        border-color: ${tagColors.border};
        &::first-letter {
          text-transform: capitalize;
        }
      `}
    >
      {status}
    </StyledTag>
  );
};

export const PageTitleStatus = ({ status, ...props }) => {
  const theme = useTheme();
  if (!status) return null; // do not display tag if status is undefined
  const tagColors = theme.tags[status] || theme.tags.default;
  return (
    <StyledTag
      {...props}
      css={css`
        color: ${tagColors.text};
        background-color: ${tagColors.background};
        border-color: ${tagColors.border};
        padding: 7px 20px 0px 20px;
        margin-bottom: 0px;
        & h5 {
          margin-bottom: 0;
          text-transform: capitalize;
          font-weight: bold;
        }
      `}
    >
      <Title level={5}>{status}</Title>
    </StyledTag>
  );
};

export const BillStatusTag = ({ status, ...props }) => {
  const theme = useTheme();
  if (!status) return null; // do not display tag if status is undefined
  const tagColors = theme.tags[status] || theme.tags.default;
  return (
    <StyledTag
      {...props}
      css={css`
        color: ${tagColors.text};
        background-color: ${tagColors.background};
        border-color: ${tagColors.border};
        &::first-letter {
          text-transform: capitalize;
        }
      `}
    >
      {status}
    </StyledTag>
  );
};

export const StyledTag = styled(Tag)(space);
