export const CREATE_SERVICE_LABELS = {
  CREATE_SERVICE: "Create service"
};

export const EDIT_SERVICE_LABELS = {
  CREATE_SERVICE: "Update Service",
  UPDATE_METERS: "Update Service Meters"
};

export const getServiceLabel = (id) => {
  if (id) {
    return EDIT_SERVICE_LABELS;
  } else {
    return CREATE_SERVICE_LABELS;
  }
};

export const CREATE_CUSTOMER_LABELS = {
  CREATE_CUSTOMER: "Create Account"
};

export const UPLOAD_CUSTOMER_LABELS = {
  UPLOAD_CUSTOMERS: "Account Uploads"
};

export const IMPORT_EXPORT_LABELS = {
  PAGE_TITLE: "Import & Export",
  IMPORT_HEADER:"Import",
  EXPORT_HEADER:'Export'
};

export const UPLOAD_USAGE_LABELS = {
  PAGE_TITLE: "Usage Upload",

};

export const DASHBOARD_LABELS = {
  PAGE_TITLE: "Usage Dashboard",

};


export const EDIT_CUSTOMER_LABELS = {
  CREATE_CUSTOMER: "Update Account"
};

export const getCustomersLabel = (id) => {
  if (id) {
    return EDIT_CUSTOMER_LABELS;
  } else {
    return CREATE_CUSTOMER_LABELS;
  }
}

export const CREATE_PLANS_LABELS = {
  CREATE_PLAN: "Create plan"
}

export const EDIT_PLAN_LABELS = {
  CREATE_PLAN: "Update plan"
}

export const PRICING_ANALYSIS_LABELS = {
  PRICING_MODELER: "Pricing Modeler",
  PRICING_ANALYZER: "Compare Plans"
}



export const getPlansLabel = (id) => {
  if(id){
    return EDIT_PLAN_LABELS;
  }
  else
  {
    return CREATE_PLANS_LABELS    ;
  }
}
