import { Card, Input, Table } from "antd";
import React from "react";
import {
  filterForBills,
  filterForBillsReport
} from "../../constants/globalStaticConstants";
import { Box, FlexRow } from "../common";
import { PageTitle, SectionTitle } from "../common/page";
import { FormItem } from "../paliq/input/FormItem";
import "./style.scss";

import { css } from "@emotion/react";
import { CardContainer } from "../common/CardContainer";

const dateFormat = "M/D/YYYY";
const customFormat = (value) => `${value.format(dateFormat)}`;
const default_page_size = 10;

const { Search } = Input;

const ListTable = (props) => {
  const {
    title,
    titleButtons,
    data,
    columns,
    onSearch,
    onChange,
    pagination,
    statusBillFilter,
    statusBillReport,
    onChangeBillDateRange,
    isService,
    rowKey,
    showHeader,
    components
  } = props;

  const renderFilterRow = () => (
    <FlexRow mb={16}>
      <Box width="18rem" mr={16}>
        <Search
          placeholder="Search"
          size="large"
          onSearch={onSearch}
          allowClear
        />
      </Box>
      {/* Customer Filters */}
      {statusBillFilter ? (
        <>
          <Box mr={16}>
            <FormItem
              name={props.name}
              type="select"
              data={filterForBills}
              defaultValue="All Status"
            />
          </Box>
          <Box mr={16}>
            <FormItem
              type="dateRange"
              defaultValue=""
              dateFormat={customFormat}
              onChange={onChangeBillDateRange}
            />
          </Box>
        </>
      ) : null}
      {statusBillReport ? (
        <>
          <Box mr={16}>
            <FormItem
              name={props.name}
              type="select"
              data={filterForBillsReport}
              defaultValue="All Services"
            />
          </Box>
          <Box mr={16}>
            <FormItem
              type="dateRange"
              defaultValue=""
              dateFormat={customFormat}
              onChange={onChangeBillDateRange}
            />
          </Box>
        </>
      ) : null}
    </FlexRow>
  );

  return (
    <>
      <div className="site-card-wrapper">
        <PageTitle title={title || ""} buttons={titleButtons} />
        <Table
          className="paliq-table"
          columns={columns}
          dataSource={data}
          // rowClassName={() => "rowClassName1"}

          /*rowSelection={{
            type: "checkbox"
            // onChange
            // getCheckboxProps
          }}*/
          pagination={
            !pagination
              ? pagination
              : {
                  position: [pagination]
                }
          }
          rowKey={rowKey}
          components={components}
          showHeader={showHeader}
        />
      </div>
    </>
  );
};

export default ListTable;
