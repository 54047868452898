// The theme definition below must be kept in sync with `emotion.cjm.js`
// This file must use the ESM syntax (`export` and `export default` etc)

const baseTheme = {
  fontFamily: {
    // default: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
    default: "Helvetica",
    fixed: "Verdana, source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace"
  },
  layout: {
    maxWidth: "100vw",
    sideNavClosedWidth: "64px",
    sideNavOpenWidth: "224px",
    bodyMarginTop: "80px",
    bodyMarginX: "24px"
  },
};

export const lightTheme = {
  ...baseTheme,
  colors: {
    primary: "#009879",
    layoutBodyBg: "white",
    sideBarWhite: "#BFBFBF",
    sideBarBlack: "#2A2A2D",
    textSubdued: "#8C8C8C",
    label: "rgba(0, 0, 0, 0.45)",
    text: "rgba(0, 0, 0, 0.85)",
  },
  tags: {
    active: {
      text: "#52C41A",
      background: "#F6FFED",
      border: "#B7EB8F"
    },
    inactive: {
      text: "#F5222D",
      background: "#FFF1F0",
      border: "#FFA39E"
    },
    terminated: {
      text: "#F5222D",
      background: "#FFF1F0",
      border: "#FFA39E"
    },
    expired: {
      text: "#FA8C16",
      background: "#FFF7E6",
      border: "#FFD591"
    },
    draft: {
      text: "#FA8C16",
      background: "#FFF7E6",
      border: "#FFD591"
    },
    test: {
      text: "#FA8C16",
      background: "#FFF7E6",
      border: "#FFD591"
    },
    inuse: {
      text: "#52C41A",
      background: "#F6FFED",
      border: "#B7EB8F"
    },
    default: {
      text: "#8C8C8C",
      background: "#FFF",
      border: "#BFBFBF"
    },
    paid: {
      text: "#52C41A",
      background: "#F6FFED",
      border: "#B7EB8F"
    },
    Paid: {
      text: "#52C41A",
      background: "#F6FFED",
      border: "#B7EB8F"
    },
    Prepaid: {
      text: "#52C41A",
      background: "#F6FFED",
      border: "#B7EB8F"
    },
    prepaid: {
      text: "#52C41A",
      background: "#F6FFED",
      border: "#B7EB8F"
    },
    "Partially Paid": {
      text: "#FA8C16",
      background: "#FFF7E6",
      border: "#FFD591"
    },
    late: {
      text: "#F5222D",
      background: "#FFF1F0",
      border: "#FFA39E"
    },
    due: {
      text: "#FA8C16",
      background: "#FFF7E6",
      border: "#FFD591"
    },
    overdue: {
      text: "#F5222D",
      background: "#FFF1F0",
      border: "#FFA39E"
    },
    "Pre-Payment": {
      text: "#52C41A",
      background: "#F6FFED",
      border: "#B7EB8F"
    },
    unpaid: {
      text: "#FA8C16",
      background: "#FFF7E6",
      border: "#FFD591"
    },
    Available: {
      text: "#52C41A",
      background: "#F6FFED",
      border: "#B7EB8F"
    },
    Development: {
      text: "#FA8C16",
      background: "#FFF7E6",
      border: "#FFD591"
    },
    Roadmap: {
      text: "#0000FF",
      background: "#e7feff",
      border: "#87cefa"
    },"true": {
      text: "#52C41A",
      background: "#F6FFED",
      border: "#B7EB8F"
    }, "false": {
      text: "#FA8C16",
      background: "#FFF7E6",
      border: "#FFD591"
    },
  },
  // gaps among <Col> in an Antd <Row>
  // moved from globalStaticConstants
  gutter: { xs: 8, sm: 16, md: 24, lg: 24 },
};
