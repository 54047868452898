import React from "react";
import { FormItem } from "../paliq/input/FormItem";
import { FormRow } from "../common/form";
import "../../components/paliq/cards/styles.scss";
import { Button, DatePicker, Space } from "antd";
import { CardContainer } from "../common/CardContainer";
import {SubTitle} from "../common/page";
import {Title} from "../common";

const PricingFilters = ({
  form,
  isServiceFetching,
  isCustomerFetching,
  servicesData,
  planKVList,
  customersData,
  onApplyButton,
  onServiceChange
}) => {
  return (
    <>
      <Space direction="vertical" size="middle" style={{ display: "flex" }}>
        <CardContainer>
          <FormRow spans={[4, 5, 5, 4, 4, 2]}>
            <FormItem
              name="service_id"
              size="medium"
              label="Select a Service"
              placeholder="Please Select"
              data={!isServiceFetching ? servicesData : []}
              onChange={onServiceChange}
              type="selectkvp"
              rules={[{ required: true }]}
            />
            <FormItem
              name="plan_ids"
              size="large"
              label="Select Plans"
              placeholder="Please Select"
              options={planKVList}
              // value={selectedState}
              type="multiselect"
              rules={[{ required: true }]}
            />
            <FormItem
              name="customer_id"
              size="medium"
              label="Select Accounts"
              placeholder="Please Select"
              data={!isCustomerFetching ? customersData : []}
              type="selectkvp"
            />
            <FormItem
              name="start_date"
              label="Start Date"
              rules={[{ required: true, message: "Start Date is required" }]}
            >
              <DatePicker format="M/D/YYYY" style={{ width: "100%" }} />
            </FormItem>
            <FormItem
              name="end_date"
              label="End Date"
              rules={[{ required: true, message: "End Date is required" }]}
            >
              <DatePicker format="M/D/YYYY" style={{ width: "100%" }} />
            </FormItem>
            <Button
              style={{
                width: "100%",
                marginTop: "30px"
              }}
              size="middle"
              type="primary"
              htmlType="submit"
              onClick={() => {
                form.validateFields().then((values) => {
                  onApplyButton(values);
                });
              }}
            >
              Apply
            </Button>
          </FormRow>
        </CardContainer>
      </Space>
    </>
  );
};


export default PricingFilters;
