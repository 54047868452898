//First trial with customer credits table. Trying to refactor out of all table renders.
import { useState } from "react";
import moment from "moment";
import { Form, Select, Input, DatePicker, InputNumber, Row, Col } from "antd";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  children,
  jsonpath,
  ...restProps
}) => {
  const [isRequired, setIsRequired] = useState(true);
  const handleChange = (value) => {};

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={jsonpath}
          style={{ margin: 0 }}
          rules={[
            {
              required: isRequired,
              message: `Please Input ${title}!`
            }
          ]}
        >
          {inputType === "number" ? (
            <InputNumber style={{ width: "100%" }} />
          ) : inputType === "text" ? (
            <Input />
          ) : (
            <DatePicker style={{ width: "100%" }} />
          )}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default EditableCell;
