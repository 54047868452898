import React, { useEffect, useState } from "react";
import { Form } from "antd";
import { useQuery } from "@tanstack/react-query";
import { PageTitle, SubTitle } from "../../components/common/page";
import PricingFilters from "../../components/pricing/PricingFilters";
import { PRICING_ANALYSIS_LABELS } from "../../constants/labels";
import PricingResults from "../../components/pricing/PricingResults";
import {
  processServiceDataForSelect,
  getCustomersLOV,
  getServicesLOV,
  getPlansByServiceLOV,
  getInputJson,
  getPlanComparisons
} from "../../components/pricing/pricing-helper";
import { Title } from "../../components/common";


const PricingAnalysis = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const {
    isFetching: isServiceFetching,
    data: servicesData,
    refetch: serviceRefetch
  } = useQuery(["allservices"], () => getServicesLOV(), {
    staleTime: Infinity,
    select: processServiceDataForSelect
  });

  console.log(servicesData);

  const {
    isFetching: isCustomerFetching,
    data: customersData,
    refetch: customerRefetch
  } = useQuery(["allcustomers"], () => getCustomersLOV());

  useEffect(() => {
    serviceRefetch();
    customerRefetch();
  }, []);

  const [planKVList, setPlanKVList] = useState([]);

  const onServiceChange = async (value) => {
    const plans_kvlist = await getPlansByServiceLOV(value);
    setPlanKVList(plans_kvlist);
  };

  const [planDetails, setPlanDetails] = useState([]);
  const [diffMonth, setDiffMonth] = useState();

  const onApplyButton = async () => {
    setLoading(true);
    const inputJson = getInputJson(form);
    try {
      const response = await getPlanComparisons(inputJson);
      const st = new Date(inputJson.from);
      const en = new Date(inputJson.to);
      let diffMonth = (parseInt((en - st) / (1000 * 60 * 60 * 24 * 30), 10));
      if (diffMonth < 1) {
        diffMonth = 1;
      }
      setDiffMonth(diffMonth);
      setPlanDetails(response?.result?.customers_plans_comparisons ?? []);
    } catch (err) {
      console.log("Error occured");
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <PageTitle title={PRICING_ANALYSIS_LABELS.PRICING_ANALYZER} />
      <Form layout="vertical" autoComplete="off" form={form}>
        <PricingFilters
          form={form}
          isServiceFetching={isServiceFetching}
          isCustomerFetching={isCustomerFetching}
          servicesData={servicesData}
          planKVList={planKVList}
          customersData={customersData}
          onServiceChange={onServiceChange}
          onApplyButton={onApplyButton}
        />
        <PricingResults planDetails={planDetails} loading={loading} diffMonth={diffMonth} />
      </Form>
    </>
  );
};

export default PricingAnalysis;
