import CONSTANTS from "../constants";
import { getRequest, postRequest, putRequest, deleteRequest } from ".";
import {
  APICONFIG,
  getCustomerByIdUrl,
  getPlansForCustomerId,
  postNewPlanForCustomerUrl,
  putUpdatePlanForCustomerUrl,
  getCustomerCreditsUrl,
  postNewCustomerCreditsUrl,
  customerContactsUrl,
  customerContactUrl, getPlansForExternalCustomerId
} from "./apiConfig";

export const getAllCustomers = async () => {
  const res = await getRequest(APICONFIG.getCustomersUrl);
  return res;
};

// get customer info by id
export const getCustomerById = async (id) => {
  if (id) {
    const res = await getRequest(getCustomerByIdUrl(id));
    return res;
  } else {
    return null;
  }
};

// get customer info by id
export const getCustomerPlans = async (customer_id) => {
  if (customer_id) {
    const res = await getRequest(getPlansForCustomerId(customer_id));//getPlansForCustomerId(customer_id));
    // getRequest(getPlansForExternalCustomerId(customer_id));//getPlansForCustomerId(customer_id));
    return res;
  } else {
    return null;
  }
};

export const getAllOrgs = async () => {
  const res = await getRequest(APICONFIG.getAllOrgsUrl);
  return res;
};

export const createNewCustomer = async ({ data }) => {
  const res = await postRequest(APICONFIG.getCustomersUrl, data);
  return res;
};

// update customer info
export const updateCustomerInfo = async ({ data, id }) => {
  console.log(data);
  const res = await putRequest(getCustomerByIdUrl(id), data);
  return res;
};

export const assignNewPlanToCustomer = async ({ cust_id, data }) => {
  const res = await postRequest(postNewPlanForCustomerUrl(cust_id), data);
  return res;
};

export const updatePlanOfCustomer = async ({ cust_id, cust_plan_id, data }) => {
  const res = await putRequest(
    putUpdatePlanForCustomerUrl(cust_id, cust_plan_id),
    data
  );
  return res;
};

export const getCustomerCredits = async (cust_id) => {
  const res = await getRequest(getCustomerCreditsUrl(cust_id));
  console.log(res);
  return res;
};

export const addNewCreditsToCustomer = async ({ cust_id, data }) => {
  const res = await postRequest(postNewCustomerCreditsUrl(cust_id), data);
  return res;
};

// export const
// for the customer contacts
export const postCustomerContacts = (data, id) => {};
/*
export const postCustomerContacts = (data, id) => async (dispatch) => {
  dispatch({ type: CONSTANTS.CUSTOMER_CONTACTS_REQUEST });
  try {
    const res = await postRequest(customerContactsUrl(id), data);
    dispatch({ type: CONSTANTS.CUSTOMER_CONTACTS_SUCCESS, data: res });
    return res;
  } catch {
    dispatch({ type: CONSTANTS.CUSTOMER_CONTACTS_FAILURE });
  }
};
*/

//  get all contacts
export const getCustomerConatactsId = async (id) => {
  try {
    const res = await getRequest(customerContactsUrl(id));
    const contacts = res?.contacts ?? [];
    return contacts.map((contact) => ({
      ...contact,
      isActive: contact.status === "active"
    }));
  } catch (err) {
    return err;
  }
};
/*
export const getCustomerConatactsId = (id) => async (dispatch) => {
  dispatch({ type: CONSTANTS.GET_CUSTOMER_BY_ID_REQUEST });
  try {
    const res = await getRequest(getCustomerContactsIdUrl(id));
    dispatch({ type: CONSTANTS.GET_CUSTOMER_BY_ID_SUCCESS, data: res });
    return res;
  } catch (err) {
    dispatch({ type: CONSTANTS.GET_CUSTOMER_BY_ID_FAILURE });
    return err;
  }
};
*/

//  contacts update
// export const updateCustomerContacts = (data, id) => { }

//Update Service Meter PUT
export const updateCustomerContacts = async ({ data, id }) => {
  const res = await putRequest(customerContactsUrl(id), data);
  return res;
};

export const deleteCustomerContact = async (id, cid) => {
  const res = await deleteRequest(customerContactUrl(id, cid));
  return res;
};

/*
export const updateCustomerContacts = (data, id) => async (dispatch) => {
  dispatch({ type: CONSTANTS.UPDATE_SERVICE_REQUEST });
  try {
    const res = await putRequest(putCustomerContacts(id), data);
    dispatch({ type: CONSTANTS.UPDATE_CUSTOMER_SUCCESS, data: res });
    return res;
  } catch (err) {
    dispatch({ type: CONSTANTS.UPDATE_CUSTOMER_FAILURE });
  }
};
*/
