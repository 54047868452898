import { getRequest } from "../../services";
import { APICONFIG, getCustomerUsage } from "../../services/apiConfig";
import { formatDate } from "../common/table";

export const getAllUsageData = async () => {
  const res = await getRequest(`${APICONFIG.getAllUsageDataUrl}`);
  return res;
};

export const getUploadJobStatus = async () => {
  const res = await getRequest(`${APICONFIG.getUploadJobStatusUrl}`);
  return res;
};

export const getUsageDataForCustomer = async (id, mo) => {
  const res = await getRequest(getCustomerUsage(id, mo));
  return res;
};

/* servicesUsage coming in is like this:

[
  {
      "service_window": {
          "start_at": "2022-10-01T00:00:00Z",
          "end_at": "2022-10-08T10:34:19.02321153Z"
      },
      "total": "$1,082.52",
      "status": "",
      "pre_discount_total": "$1,116.00",
      "customer_service_usages": [
          {
              "service_name": "Programmable Voice",
              "service_sku": "TWLOSKU2349",
              "plan_name": "AshTest2",
              "plan_start_date": "2022-09-20T00:00:00Z",
              "plan_end_date": "2022-09-28T00:00:00Z",
              "pre_discount_total": "$1,116.00",
              "total": "$1,082.52",
              "meter_usage_costs": [
                  {
                      "meter_name": "Receive a call",
                      "meter_key": "pv_outbound_key",
                      "usage_qty": 744,
                      "pre_discount_total": "$1,116.00",
                      "total": "$1,082.52"
                  }
              ]
          }
      ]
  },
  {
      "service_window": {
          "start_at": "2022-09-01T00:00:00Z",
          "end_at": "2022-10-01T00:00:00Z"
      },
      "total": "$259,798.25",
      "status": "Bill Paid",
      "pre_discount_total": "$267,833.25",
      "customer_service_usages": [
          {
              "service_name": "Programmable Voice",
              "service_sku": "TWLOSKU2349",
              "plan_name": "AshTest2",
              "plan_start_date": "2022-09-20T00:00:00Z",
              "plan_end_date": "2022-09-28T00:00:00Z",
              "pre_discount_total": "$267,833.25",
              "total": "$259,798.25",
              "meter_usage_costs": [
                  {
                      "meter_name": "Receive a call",
                      "meter_key": "pv_outbound_key",
                      "usage_qty": 165648,
                      "pre_discount_total": "$248,472.00",
                      "total": "$241,017.84"
                  },
                  {
                      "meter_name": "Make a call",
                      "meter_key": "pv_inbound_key",
                      "usage_qty": 15489,
                      "pre_discount_total": "$19,361.25",
                      "total": "$18,780.41"
                  }
              ]
          }
      ]
  },
  {
      "service_window": {
          "start_at": "2022-08-01T00:00:00Z",
          "end_at": "2022-09-01T00:00:00Z"
      },
      "total": "$267,590.01",
      "status": "Bill Paid",
      "pre_discount_total": "$275,866.00",
      "customer_service_usages": [
          {
              "service_name": "Programmable Voice",
              "service_sku": "TWLOSKU2349",
              "plan_name": "AshTest2",
              "plan_start_date": "2022-09-20T00:00:00Z",
              "plan_end_date": "2022-09-28T00:00:00Z",
              "pre_discount_total": "$275,866.00",
              "total": "$267,590.01",
              "meter_usage_costs": [
                  {
                      "meter_name": "Make a call",
                      "meter_key": "pv_inbound_key",
                      "usage_qty": 8432,
                      "pre_discount_total": "$10,540.00",
                      "total": "$10,223.79"
                  },
                  {
                      "meter_name": "Receive a call",
                      "meter_key": "pv_outbound_key",
                      "usage_qty": 176884,
                      "pre_discount_total": "$265,326.00",
                      "total": "$257,366.22"
                  }
              ]
          }
      ]
  },
  {
      "service_window": {
          "start_at": "2022-06-01T00:00:00Z",
          "end_at": "2022-07-01T00:00:00Z"
      },
      "total": "$6,512.59",
      "status": "Bill Paid",
      "pre_discount_total": "$6,714.00",
      "customer_service_usages": [
          {
              "service_name": "Programmable Voice",
              "service_sku": "TWLOSKU2349",
              "plan_name": "AshTest2",
              "plan_start_date": "2022-09-20T00:00:00Z",
              "plan_end_date": "2022-09-28T00:00:00Z",
              "pre_discount_total": "$6,714.00",
              "total": "$6,512.59",
              "meter_usage_costs": [
                  {
                      "meter_name": "Make a call",
                      "meter_key": "pv_inbound_key",
                      "usage_qty": 2418,
                      "pre_discount_total": "$3,022.50",
                      "total": "$2,931.83"
                  },
                  {
                      "meter_name": "Receive a call",
                      "meter_key": "pv_outbound_key",
                      "usage_qty": 2461,
                      "pre_discount_total": "$3,691.50",
                      "total": "$3,580.76"
                  }
              ]
          }
      ]
  }
]
*/

export const flattenDataForTable = (servicesUsage, currency) => {
  const retVals = [];
  if (servicesUsage?.map != null) {
    servicesUsage?.map((s) => {
      const start_at = s.service_window.start_at;
      const end_at = s.service_window.end_at;
      s.customer_service_usages?.map((csu) => {
        const service_name = csu.service_name;
        csu.meter_usage_costs?.map((muc) => {
          retVals.push({
            start_at: start_at,
            end_at: end_at,
            service_name: service_name,
            meter_name: muc.meter_name,
            usage_qty: muc.usage_qty,
            pre_discount_total: muc.charges,
            discount: muc.discount,
            total: muc.charges - muc.discount,
            currency: currency,
            allowance: muc.allowance,
            grouped_allowances: muc.grouped_allowances
          });
        });
      });
    });
    return retVals;
  }
};

/*
  Return a dataset in the form:
  data = { labels, datasets:[]}
  where
  labels = ["label 1","label 2"]
  datasets = [{ type: 'line/bar etc', label: 'Dataset label', data: labels.map(() => data))}]
*/
export const setDataForCharts = (servicesUsage) => {
  const servicesDataMap = new Map();
  let labels = [];
  // Arrange data by period & service
  if (servicesUsage?.map != null) {
    servicesUsage?.map((s) => {
      const start_at = formatDate(s.service_window.start_at);
      const end_at = formatDate(s.service_window.end_at);
      const one_ds_label = start_at + " - " + end_at;
      labels.push(one_ds_label);

      s.customer_service_usages?.map((usage) => {
        let total_charges = 0;
        let serviceDataMap = servicesDataMap.get(usage.service_name);
        if (!serviceDataMap) {
          serviceDataMap = new Map();
          servicesDataMap.set(usage.service_name, serviceDataMap);
        }
        usage.meter_usage_costs?.map((it) => {
          total_charges += it.charges - it.discount;
        });
        serviceDataMap.set(one_ds_label, total_charges);
      });
    });
  }

  /* Make labels and dataSet for charts */
  for (const label of labels) {
    for (const serviceDataMap of servicesDataMap.values()) {
      const windowCharges = serviceDataMap.get(label);
      if (!windowCharges) {
        serviceDataMap.set(label, 0);
      }
    }
  }

  const servicesData = [];
  for (const service of servicesDataMap.keys()) {
    const serviceNameAndData = new Map();
    const serviceData = [];
    serviceNameAndData.set("label", service);
    const serviceDataMap = servicesDataMap.get(service);
    for (const label of labels) {
      serviceData.push(serviceDataMap.get(label));
    }
    serviceNameAndData.set("data", serviceData);
    servicesData.push(Object.fromEntries(serviceNameAndData));
  }
  const chartData = {
    labels: labels,
    datasets: servicesData
  };

  return chartData;
};
