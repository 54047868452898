import dayjs from 'dayjs';
import Chance from 'chance';
const chance = new Chance();

export const nonMeterableTypes = ["fixed", "one-time"];

export const getFormValuesFromMetricsData = (metrics) => {

  /* Metrics is like this:
  [
    {
        "uid": "97ce9c04-f1f0-4ecd-a831-71fb6423dc55",
        "service_uid": "4d1fd9b9-fc3d-44ea-a30d-d580bdbba71d",
        "name": "Make a call",
        "meter_key": "pv_inbound_key",
        "meter_group": "programmable_voice",
        "status": null,
        "description": "Meter to capture outbound calls",
        "aggregation": "max",
        "aggregate_on": "ddd",
        "group_by": [
            {
                "name": "ccc",
                "type": null
            },
            {
                "name": "hhg",
                "type": null
            }
        ],
        "charge_type": "usage",
        "charge_interval_month": 1,
        "charge_json": [],
        "date_updated": "2022-09-15 22:33:08.439358+00:00",
        "date_created": "2022-09-15 18:55:09.940645+00:00"
    },
    {
        "uid": "e30c21cf-3fd8-4ea6-9b51-6ceff55ad1ef",
        "service_uid": "4d1fd9b9-fc3d-44ea-a30d-d580bdbba71d",
        "name": "Receive a call",
        "meter_key": "pv_outbound_key",
        "meter_group": "programmable_voice",
        "status": null,
        "description": "Meter to capture inbound calls",
        "aggregation": "sum",
        "aggregate_on": "sa",
        "group_by": [
            {
                "name": "vcd",
                "type": null
            },
            {
                "name": "klj",
                "type": null
            },
            {
                "name": "abc,def",
                "type": null
            }
        ],
        "charge_type": "usage",
        "charge_interval_month": 1,
        "charge_json": [],
        "date_updated": "2022-09-15 22:33:08.439371+00:00",
        "date_created": "2022-09-15 18:55:09.940460+00:00"
    }
  ]
retVals is a flat object of type:
{<uid>.<fieldName}: fieldValue,....}
fieldNames are name, meter_key, meter_group, aggregation, aggregate_on, group_by
  */

  //let retKeys = [];
  const fieldNames = ["name", "meter_key", "meter_group", "aggregation", "aggregate_on", "group_by"];
  let retVals = {};

  metrics?.map((m, i) => {
    fieldNames.map((fname, j) => {
      if (fname === "group_by") {
        retVals[`${m.uid}.${fname}`] = m[fname]?.map(g => g.name) ?? [];
      } else {
        retVals[`${m.uid}.${fname}`] = m[fname];
      }
    })
  });
  //console.log("retKeys = ", retKeys);
  console.log("retVals = ", retVals);
  return retVals;
};



export const updateMetricsFromFormValues = (values, metrics) => {
  const newMetrics = [...metrics];
  const newValues = values;
  delete newValues.service;
  /*
Form Values = 
  {
  "service": {
      "name": "Programmable Voice",
      "version": 1,
      "external_sku_code": "TWLOSKU2349",
      "description": null
  },
  "97ce9c04-f1f0-4ecd-a831-71fb6423dc55.aggregation": "max",
  "97ce9c04-f1f0-4ecd-a831-71fb6423dc55.aggregate_on": "ddd",
  "97ce9c04-f1f0-4ecd-a831-71fb6423dc55.group_by": [
      "ccc",
      "hhg"
  ],
  "e30c21cf-3fd8-4ea6-9b51-6ceff55ad1ef.aggregation": "sum",
  "e30c21cf-3fd8-4ea6-9b51-6ceff55ad1ef.aggregate_on": "sa",
  "e30c21cf-3fd8-4ea6-9b51-6ceff55ad1ef.group_by": [
      "vcd",
      "klj"
  ]
}
Metrics
[
  {
      "uid": "97ce9c04-f1f0-4ecd-a831-71fb6423dc55",
      "service_uid": "4d1fd9b9-fc3d-44ea-a30d-d580bdbba71d",
      "name": "Make a call",
      "meter_key": "pv_inbound_key",
      "meter_group": "programmable_voice",
      "status": null,
      "description": "Meter to capture outbound calls",
      "aggregation": "sum",
      "aggregate_on": "inbound_mins",
      "group_by": [
          {
              "name": "user_id",
              "type": null
          }
      ],
      "charge_type": "usage",
      "charge_interval_month": 1,
      "charge_json": [],
      "date_updated": "2022-09-15 22:33:08.439358+00:00",
      "date_created": "2022-09-15 18:55:09.940645+00:00"
  },
  {
      "uid": "e30c21cf-3fd8-4ea6-9b51-6ceff55ad1ef",
      "service_uid": "4d1fd9b9-fc3d-44ea-a30d-d580bdbba71d",
      "name": "Receive a call",
      "meter_key": "pv_outbound_key",
      "meter_group": "programmable_voice",
      "status": null,
      "description": "Meter to capture inbound calls",
      "aggregation": "sum",
      "aggregate_on": "outbound_mins",
      "group_by": [
          {
              "name": "user_id",
              "type": null
          }
      ],
      "charge_type": "usage",
      "charge_interval_month": 1,
      "charge_json": [],
      "date_updated": "2022-09-15 22:33:08.439371+00:00",
      "date_created": "2022-09-15 18:55:09.940460+00:00"
  }
]
 */
  Object.keys(newValues).map(k => { // k is the iterator of keys inside form values
    const [uid, fieldName] = k.split('.');
    // ["b57f1...", "aggregation"];
    const metric = newMetrics.find((m) => m.uid === uid)
    if (fieldName === "group_by") {
      metric[fieldName] = newValues[k].map(gb => {
        return { "name": gb, "type": null }
      })
    } else {
      metric[fieldName] = newValues[k];
    }
  });
  return newMetrics;
}

/* MeterIngestJSON should be like this:
[
  {
    "timestamp":"2022-09-09T09:45:26-07:00",
    "event_id":"f8b3415d-7bbe-4af1-a1b1-6409bdc539f0",
    "external_customer_id":"external_customer_account_id",
    "meter_group”:”gp1”,
    "meter_key”:”key1,
    "attributes":
      {
        "agr1":1,
        "agr3":"enter a value",
        "agr4":"enter a value"
      }
  },
  {
    "timestamp":"2022-09-09T09:45:26-07:00",
    "event_id”:”valid event_id”,
    "external_customer_id":"external_customer_account_id",
    "meter_group”:”gp1”,
    "meter_key":"key2”,
    "attributes":
      {
        "agr2”:1,
        "agr3”:”value",
        "agr5”:”value"
      }
  }
]
From Metrics:
[
    {
        "uid": "0c7e71b9-7ec8-422e-98d1-7e62f88f2f7c",
        "service_uid": "2d900eef-af93-4451-b771-e18a4865ed59",
        "name": "Inbound Text",
        "meter_key": "Key1",
        "meter_group": "gp1",
        "status": "active",
        "description": "OneTimeFeeForFacility",
        "aggregation": "sum",
        "aggregate_on": "agr1",
        "group_by": [
            {
                "name": "agr3",
                "type": null
            },
            {
                "name": "agr4",
                "type": null
            }
        ],
        "charge_type": "one-time",
        "charge_interval_month": 1,
        "charge_json": [],
        "date_updated": "2022-09-28 16:31:23.508385+00:00",
        "date_created": "2022-09-16 09:18:50.779221+00:00"
    },
    {
        "uid": "e792193f-d69f-4a87-9ff3-c1249388ff45",
        "service_uid": "2d900eef-af93-4451-b771-e18a4865ed59",
        "name": "Outbound Text",
        "meter_key": "Key2",
        "meter_group": "gp1",
        "status": "active",
        "description": "Brand New",
        "aggregation": "max",
        "aggregate_on": "agr2",
        "group_by": [
            {
                "name": "agr3",
                "type": null
            },
            {
                "name": "agr5",
                "type": null
            }
        ],
        "charge_type": "tier",
        "charge_interval_month": 1,
        "charge_json": [
            {
                "end": 100,
                "name": "Walk",
                "start": 0
            },
            {
                "end": 250,
                "name": "Jog",
                "start": 101
            },
            {
                "end": 500,
                "name": "Run",
                "start": 251
            },
            {
                "end": 1000,
                "name": "Sprint",
                "start": 501
            }
        ],
        "date_updated": "2022-09-28 16:31:23.508398+00:00",
        "date_created": "2022-09-16 09:18:50.779360+00:00"
    }
]

*/

const baseIngestJSON = {
  "timestamp": "2022-10-15T09:45:26-07:00",
  "external_customer_id": "customer account id for the usage event. (External customer id of customer record)"
};

export const computeMeterIngestJSON = (metrics) => {
  let retJSON = [];
  metrics?.forEach((m, idx) => {
    let entry = {};
    entry = Object.assign({}, baseIngestJSON); // very important otherwise shallow
    entry.event_id = m.uid;
    entry.meter_group = m.meter_group ?? null;
    entry.meter_key = m.meter_key ?? null;
    let entryAttributes = {};
    entryAttributes[m.aggregate_on] = 1;
    m.group_by?.map(g => {
      entryAttributes[g.name] = "value"
    });
    entry.attributes = entryAttributes;
    retJSON[idx] = entry;
  });
  return retJSON;
};

export const checkMeterKeysForUniqueness = (values, metrics) => {
  const meterKeys = [];
  metrics.map(m => {
    // we have to filter the nonMeterableTypes' values since they will be undefined. 
    // And 2 undefs make it non-unique
    if (!nonMeterableTypes.includes(m.charge_type)) meterKeys.push(values[`${m.uid}.meter_key`]);
  });
  const hasDuplicates = meterKeys.length !== new Set(meterKeys).size; // will be boolean
  return hasDuplicates;
};

export const checkAggregateOnForUniqueness = (values, metrics) => {
  const AggOns = [];
  metrics.map(m => {
        // we have to filter the nonMeterableTypes' values since they will be undefined.
    if (!nonMeterableTypes.includes(m.charge_type)) AggOns.push(values[`${m.uid}.aggregate_on`]);
  });
  const hasDuplicates = AggOns.length !== new Set(AggOns).size; // will be boolean
  return hasDuplicates;
};

export const getMetersAndMeterGroups = (service) => {
  const meterGroupSet = new Set();
  const meterSet = new Set();
  (service.service_meters.meters || []).forEach(meter => {
    meterSet.add(meter.meter_key || meter.name);
    if (meter.meter_group) meterGroupSet.add(meter.meter_group)
  })
  return [
    ...Array.from(meterGroupSet).map(mg => ({ meterGroup: mg })),
    ...Array.from(meterSet).map(m => ({ meter: m })),
  ]
}

export const getCSVSpecsForMeterOrMeterGroup = (service, meterName, meterGroupName) => {
  const fields = [
    {
      heading: 'timestamp',
      tooltip: 'Event timestamp',
      valueFn: () => dayjs().format(),
    },
    {
      heading: 'event_id',
      tooltip: 'Unqiue ID for the event',
      valueFn: () => chance.guid(),
    },
    {
      heading: 'customer_account_id',
      valueFn: () => chance.string({ length: 8, casing: 'upper', alpha: true, numeric: true })
    },
    {
      heading: 'meter_group',
      value: '',
    },
    {
      heading: 'meter_key',
      value: '',
    }
  ];

  let meters = meterName
    ? (service.service_meters.meters || []).filter(m => m.meter_key === meterName || m.name === meterName)
    : (service.service_meters.meters || []).filter(m => m.meter_group === meterGroupName);

  // collect all the aggregate_on fields from all meters under the same group
  for (let meter of meters) {
    if (meter.aggregate_on) fields.push({
      heading: meter.aggregate_on,
      value: '',  // value default to '' for all meters
    })
  }

  // followed by the group_by fields
  for (let meter of meters) {
    meter.group_by?.forEach(g => {
      const existing = fields.find(f => f.heading === g.name);
      if (!existing) {
        fields.push({
          heading: g.name,
          value: '',
        })
      }
    })
  }

  // now build a template for each meter
  const templates = [];
  for (let meter of meters) {
    const groupByNames = meter.group_by?.map(grp => grp.name) || [];

    const template = fields.map(field => {
      if (field.heading === 'meter_group') return {
        ...field,
        // meter specific tooltip can be added here
        value: meter.meter_group
      }
      if (field.heading === 'meter_key') return {
        ...field,
        // and here
        value: meter.meter_key
      }
      if (field.heading === meter.aggregate_on) return {
        // this template will generate a random value for the field this meter cares
        ...field,
        valueFn: () => chance.integer({ min: 0, max: 99 })
      }
      if (groupByNames.includes(field.heading)) return {
        ...field,
        valueFn: () => chance.integer({ min: 100, max: 999 })
      }
      return {
        ...field,  // we'll have a deeply cloned field, so we can modify independently
      }
    });

    templates.push(template);
  }

  return {
    fields,
    templates,
  }
}

export const generateSampleCSVData = (specs, numberOfRows) => {
  if (specs.templates.length === 0) return [];
  const data = [];
  while (true) {
    for (let template of specs.templates) {
      const row = [];
      for (let field of template) {
        const { valueFn, ...rest } = field;
        const fieldClone = { ...rest };
        if (valueFn) fieldClone.value = valueFn();
        row.push(fieldClone);
      }
      data.push(row);
      if (--numberOfRows <= 0) return data;
    }
  }
}
