import React from "react";
import { ArrowDownOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { PageTitle } from "../../../common/page";
import CustomerBillDetail from "./CustomerBillDetail";
import { PrimaryButton } from "../../../paliq/buttons";
import { getBillPdf } from "../../../billing/billing_helper";

const CustomerBillDetailModal = ({ id, open, onCancel }) => {
  const onDownloadButton = () => {
    getBillPdf(id);
  };
  return (
    <Modal
      open={open}
      width={1000}
      onCancel={onCancel}
      onOk={onCancel}
      closable={false}
      cancelButtonProps={{ style: { display: "none" } }}
    >
      <PageTitle title="Bill Details"
        buttons={[
          <PrimaryButton
            key="1"
            type="default"
            size="large"
            icon={<ArrowDownOutlined />}
            onClick={onDownloadButton}
            mr={3}
          >
            Download PDF{" "}
          </PrimaryButton>
        ]}
      />
      <CustomerBillDetail id={id} />
    </Modal>
  );
};

// export it separately from the definition to avoid tripping lazy load in routes.
export default CustomerBillDetailModal;
