import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import ListTable from "../ListTable";
import { Box, PlusButton, Spinner } from "../common";
import { withTooltip } from "../common/table";
import { getRequest } from "../../services";
import { APICONFIG } from "../../services/apiConfig";
import { itemKeys as l, menuKeys as m } from "../menus/menuKeys.constants";
import CreateWorkflowStub from "./CreateWorkflowStub";
import { Col, Divider, Image, Row, Space, Steps, Tag } from "antd";
import Icon, {
  AuditOutlined,
  DollarCircleOutlined, FileProtectOutlined,
  UserOutlined
} from "@ant-design/icons";
import { Blank, PlanOutlined } from "../../assets/img/icons/sideBarIcons";
import stripe_logo from "../../assets/img/logos/stripe_logo.png";
import ns_logo from "../../assets/img/logos/netsuite_logo.png";
import sf_logo from "../../assets/img/logos/salesforce_logo.png";
import paliq_logo from "../../assets/img/paliq-color-logo.svg";
import aws_mp_logo from "../../assets/img/logos/aws-marketplace-logo.png";
import { StatusTag } from "../common/tags";

const WorkflowList = () => {
  const navigate = useNavigate();

  const {isLoading, error, data} = useQuery({
    queryKey: ["workflows"],
    queryFn: () => getRequest(APICONFIG.getWorkflowsUrl).then(r => r['data'])
  });

  if (isLoading) return <Spinner />;

  if (error) return "An error has occurred: " + error.message;

  if (data && data.length === 0) return <CreateWorkflowStub />;

  const partnerLabels = {
    "aws_marketplace": "AWS Marketplace",
    "netsuite": "Netsuite",
    "salesforce": "Salesforce",
    "stripe": "Stripe",
    "paliq": "Paliq",
  }
  const partnerImageLabels = {
    "aws_marketplace": "",
    "netsuite": "",
    "salesforce": "Salesforce",
    "stripe": "",
    "paliq": "",
  }
  const partnerIcons = {
    "aws_marketplace": aws_mp_logo,
    "netsuite": ns_logo,
    "salesforce": sf_logo,
    "stripe": stripe_logo,
    "paliq": paliq_logo,
  }
  const partnerConnectionLink = {
    "aws_marketplace": undefined,
    "netsuite": undefined,
    "salesforce": undefined,
    "stripe": "/integrations/preferences",
    "paliq": undefined,
  }
  const salesChannelLabels = {
    "plg": "PLG",
    "sales": "Sales Led",
    "partner": "Partner",
    "aws": "AWS",
    "azure": "Azure",
    "gcp": "GCP",
  }
  const Partner = ({ name }) => {
    return <Row style={{paddingTop: 10}}>
      {!partnerConnectionLink[name] &&
        <Space>
          <Image
            preview={false}
            alt={partnerLabels[name] ?? name}
            fallback={partnerLabels[name] ?? name}
            src={partnerIcons[name]}
            style={{ maxHeight: 32 }}
          />
          <h3>{partnerImageLabels[name] ?? ""}</h3>
        </Space>
      }
      {partnerConnectionLink[name] &&
        <Link to={partnerConnectionLink[name]}>
          <Space>
            <Image
              preview={false}
              alt={partnerLabels[name] ?? name}
              fallback={partnerLabels[name] ?? name}
              src={partnerIcons[name]}
              style={{ maxHeight: 32 }}
            />
            <h3>{partnerImageLabels[name] ?? ""}</h3>
          </Space>
        </Link>
      }
    </Row>;
  };

  const columns = [
    {
      title: "",
      dataIndex: "workflow_name",
      width: "10%",
      render: (_, record) => (
        <>
          <Row style={{paddingBottom: 6}}>
            <Link to={l.workflowDetail.getRoute({id: record['uid']})} className="link-color">
              {record['workflow_name']}
            </Link>
          </Row>
          <Row style={{paddingBottom: 6}}>
            {salesChannelLabels[record['sales_channel']] ?? record['sales_channel']}
          </Row>
          {record['is_default'] && <Row>
            <Tag color="gold">Default Workflow</Tag>
          </Row>}
        </>
      )
    },
    {
      title: "",
      dataIndex: "accounts_partner",
      width: "90%",
      render: (_, record) => (
        <Steps style={{paddingTop: 64, paddingBottom: 64}}
          items={[
            {
              title: 'Accounts',
              description: <Partner name={record['accounts_partner']}/>,
              status: 'finish',
              icon: <UserOutlined />,
            },
            {
              title: 'Contracts',
              description: <Partner name={record['contracts_partner']}/>,
              status: 'finish',
              icon: <FileProtectOutlined />,
            },
            {
              title: 'Pricing Plans',
              description: <Partner name={record['accounts_partner']}/>,
              status: 'finish',
              icon: <Icon component={PlanOutlined} />,
            },
            {
              title: 'Invoices',
              description: <Partner name={record['invoices_partner']}/>,
              status: 'finish',
              icon: <AuditOutlined />,
            },
            {
              title: 'Payments',
              description: <Partner name={record['payments_partner']}/>,
              status: 'finish',
              icon: <DollarCircleOutlined />,
            },
          ]}
        />
      )
    },
  ];

  return <ListTable
      title="Workflows"
      titleButtons={[
        <PlusButton
          key="1"
          size="large"
          title={m.workflowCreate.label}
          onClick={() => navigate(m.workflowCreate.route)}
        />
      ]}
      onButtonClick={() => navigate(m.workflowCreate.route)}
      buttonText={m.workflowCreate.label}
      data={data}
      rowKey={(record) => record["uid"]}
      columns={withTooltip(columns)}
      pagination="pagination"
      showHeader={false}
    />;
};

export default WorkflowList;
