import React, { useEffect, useState } from "react";
import { Tag } from "antd";
import { useNavigate } from "react-router-dom";
import { UPLOAD_CUSTOMER_LABELS } from "../../../constants/labels";
import UploadCustomersModal from "./uploadCustomersModal";
import ListTable from "../../ListTable";
import { PlusButton } from "../../common";
import { ArrowUpOutlined } from "@ant-design/icons";

const UploadsList = (props) => {
  const { uploadsList } = props;
  const [modalVisible, setModalVisible] = useState(false);

  const navigate = useNavigate();
  const [dataSource, setDataSource] = useState(uploadsList?.uploads);
  console.log(dataSource);

  const columns = [
    {
      title: "File",
      dataIndex: "fileName",
      render: (_, record) => record?.fileName
    },
    {
      title: "Import Job",
      dataIndex: "jobID",
      render: (_, record) => record?.jobID
    },
    {
      title: "Upload Time",
      dataIndex: "startedAt"
    },
    {
      title: "Last activity Time",
      dataIndex: "updatedAt"
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text) => (
        <Tag
          color={
            text === "done" ? "green" : text === "active" ? "orange" : "red"
          }
        >
          {text}
        </Tag>
      )
    }
  ];

  const onUploadButtonClick = () => {
    setVisible(true);
  };
  const onSearch = (value) => console.log(value);
  const onChange = (value) => console.log(value);
  const setVisible = (value) => {
    console.log("Setting visibility to " + value);
    setModalVisible(value);
  };

  return (
    <>
      <ListTable
        title={UPLOAD_CUSTOMER_LABELS.UPLOAD_CUSTOMERS}
        titleButtons={[
          <PlusButton key="1" size="large" icon={<ArrowUpOutlined />} title="Upload CSV Files" onClick={onUploadButtonClick} />
        ]}
        data={dataSource}
        columns={columns}
        onSearch={onSearch}
        onChange={onChange}
        pagination="none"
      />
      <UploadCustomersModal
        visible={modalVisible}
        setVisible={setModalVisible}
      />
    </>
  );
};

export default UploadsList;
