export const EMPTY_ARRAY = Object.freeze([]);
export const EMPTY_OBJECT = Object.freeze({});

export const CURRENCY = {
  usd: "USD",
  euro: "EURO",
  inr: "INR"
};

export const STATUS = {
  active: "Active",
  inactive: "Inactive"
};

export const ChargeKVpairs = {
  // "non-billable": "Non-billable",
  fixed: "Fixed",
  usage: "Pay As You Go",
  tier: "Tier",
  volume: "Volume",
  "one-time": "One Time Fee"
};

export const ChargeOptions = [
  { value: "fixed", label: "Fixed" },
  { value: "usage", label: "Pay As You Go" },
  { value: "tier", label: "Tier" },
  { value: "volume", label: "Volume" },
  { value: "one-time", label: "One Time Fee" }
];

export const IMPORT_DATA_FREQ_OPTIONS = [
  { value: "asap", label: "Real Time" },
  { value: "four", label: "Every 4 hours" },
  { value: "everyday", label: "Everyday" }
];

export const SEND_CHARGE_FREQ_OPTIONS = [
  { value: "asap", label: "As soon as the bills is generated" },
  { value: "one_day", label: "Wait for a day" },
  { value: "manual", label: "Wait to send manually" }
];

export const PAYMENT_METHOD_TYPE = [
  { value: "cc", label: "Credit Card" },
  { value: "bank", label: "Bank" },
  { value: "manual", label: "Manual" }
];

export const BOOLEAN_OPTIONS = [
  {
    value: true,
    label: "YES"
  },
  {
    value: false,
    label: "NO"
  }
];

export const STRIPE_2_PALIQ_CUSTOMER_MAPPING_DEFAULT = {
  name: "name",
  address_line1: "address.line1",
  address_line2: "address.line2",
  country: "address.country",
  city: "address.city",
  state: "address.state",
  zip: "address.postal_code",
  invoice_email: "email",
  billing_alert_email: "email",
  phone: "phone",
  external_guid: "metadata.external_customer_id"
};
