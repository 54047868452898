import { getRequest, postRequest, putRequest } from "../../services";
import {
  APICONFIG,
  getPlansByServiceId,
  showCustomersPlansComparison
} from "../../services/apiConfig";
import { formatDateToUTC } from "../common/table";

export const getServicesLOV = async () => {
  return getRequest(`${APICONFIG.getServiceUrl}?page_size=50`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const getPlansByServiceLOV = async (service_id) => {
  return getRequest(`${getPlansByServiceId(service_id)}`)
    .then((res) => {
      const retVal = res?.pricing_plans?.map((i) => {
        return { label: i.name, value: i.id };
      });
      return retVal;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const getCustomersLOV = async () => {
  return getRequest(`${APICONFIG.getCustomersUrl}`)
    .then((res) => {
      const retVal = res?.accounts?.map((i) => {
        return { label: i.name, value: i.external_guid };
      });
      return retVal;
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

export const getPlanComparisons = async (inuputJson) => {
  try {
    const response = await postRequest(
      showCustomersPlansComparison(),
      inuputJson
    );
    return response;
  } catch (err) {
    return Promise.reject(err);
  }
};

export const getInputJson = (form) => {
  try {
    const service_id = form.getFieldValue("service_id");
    const plan_ids = form.getFieldValue("plan_ids");
    const customer_id = form.getFieldValue("customer_id");
    const start_date = formatDateToUTC(form.getFieldValue("start_date"));
    const end_date = formatDateToUTC(form.getFieldValue("end_date"));
    const inputJson = {};
    if (service_id) {
      inputJson.service_id = service_id;
    }
    if (plan_ids) {
      inputJson.plan_ids = plan_ids.map((plan) => plan[0]);
    }
    if (customer_id) {
      inputJson.external_customer_id = customer_id;
    }
    if (start_date) {
      inputJson.from = start_date;
    }
    if (end_date) {
      inputJson.to = end_date;
    }
    return inputJson;
  } catch (err) {
    console.log(err);
  }
};

export const processServiceDataForSelect = (res) => {
  console.log(res);
  if (res) {
    const retVal = res?.services?.map((i) => {
      return { label: i.name, value: i.uid };
    });
    return retVal; //[{ label: "None", value: "00000000-0000-0000-0000-000000000000" }, ...retVal];
  } else {
    return [];
  }
};

export const customerPlansComparisons = () => {};

/*export const parseServiceNames = async(servicesList) => {
  const serviceNameIdMap = {};
  servicesList?.map(s => {
    // Any service that already exists in the plan will be greyed out in the dropdown.
    // Any service that doesn't have meter_key defined in all its meters will be greyed out as well.
    serviceNameIdMap
  })

}*/
