import styled from "@emotion/styled";
import React from "react";
import { Box, Center, FlexRow, Left, Text } from "./index";
import { Button, Card, Typography, Space, Col } from "antd";
import { PrimaryButton } from "../paliq/buttons";
import {CardContainer} from "./CardContainer";

export const AttributesPanel = ({ attrs, cols, height, props }) => (
  //<Space direction="vertical" size="middle" style={{ display: 'flex' }}>

  <CardContainer
    bordered={true}

  >
    <InnerFrame>
      {attrs.map((attr) => (
        <Attribute
          key={attr[0]}
          label={attr[0]}
          value={attr[1]}
          width={`${cols ? 100 / cols : 100}%`}
        />
      ))}
    </InnerFrame>
  </CardContainer>
  //</Space>
);

export const LeftAlignedCardPanel = ({
  attrs,
  cols,
  bordered = false,
  height,
  props
}) => (
  //<Space direction="vertical" size="middle" style={{ display: 'flex' }}>
  <Card bordered={bordered}>
    <InnerFrame>
      {attrs.map((attr) => (
        <LeftAttribute
          key={attr[0]}
          label={attr[0]}
          value={attr[1]}
          width={`${cols ? 100 / cols : 100}%`}
        />
      ))}
    </InnerFrame>
  </Card>
  //</Space>
);

export const AmountBreakdownPanel = ({ props }) => (
  <CardContainer
  >
    <TitleRow
      title="Amount Breakdown"
      //auxillary={<PrimaryButton type="text">Add Credits</PrimaryButton>}
    />
    <BreakdownRow label="Charges" value={props[0]} />
    <BreakdownRow label="Credits" value={props[1]} />
    <BreakdownRow label="Discounts" value={props[2]} />
    <Divider />
    <BreakdownRow label="Subtotal" value={props[3]} />
    <BreakdownRow label="Tax" value={props[4]} />
    <Divider />
    <FooterRow label="Total" value={props[5]} />
  </CardContainer>
);

export const OuterFrame = styled(Box)`
  margin-bottom: 24px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 16px 16px 0;
  height: 100%;
`;

export const InnerFrame = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  aligncontent: center;
  flex: 1;
  align-items: center;

`;

export const InnerLeftFrame = styled(Box)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  aligncontent: left;
  flex: 1;
  align-items: left;
`;

const Label = styled.div`
  font-size: 15px;
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 5px;
`;

const Value = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
`;

const Attribute = ({ label, value, width }) => (
  <Center mb="21px" width={width}>
    <Label style={{ color: "#6F7378", fontWeight: "normal", fontSize:"14px" }}>{label}</Label>
    <Value>{value}</Value>
  </Center>
);

const LeftAttribute = ({ label, value, width }) => (
  <Left mb="50px" my="25px" width={width}>
    <Label
      style={{ color: "#6F7378", fontWeight: "normal", fontSize: "14px" }}
    >
      {label}
    </Label>
    <Value style={{ color:"black", fontWeight: "normal"}}>{value}</Value>
  </Left>
);

const BreakdownRow = ({ label, value, ...props }) => (
  <FlexRow mb="10px" {...props}>
    <Col flex="none">
      <div style={{ padding: "0 4px" }}></div>
    </Col>
    <Box style={{ fontWeight: "normal" }} flex="1">
      {label}
    </Box>
    <Box style={{ fontSize: "medium" }}>{value}</Box>
    <Col flex="none">
      <div style={{ padding: "0 4px" }}></div>
    </Col>
  </FlexRow>
);

const TitleRow = ({ title, auxillary, ...props }) => (
  <BreakdownRow
    mb="16px"
    label={
      <Text fontSize="16px" fontWeight="800">
        {title}
      </Text>
    }
    value={auxillary}
    {...props}
  />
);

const FooterRow = ({ label, value, ...props }) => (
  <BreakdownRow
    mt="8px"
    mb="0"
    label={
      <Text fontSize="15px" fontWeight="500">
        {label}
      </Text>
    }
    value={
      <Text fontSize="15px" fontWeight="800">
        {value}
      </Text>
    }
    {...props}
  />
);

const Divider = styled.div`
  border-top: 1px solid #d9d9d9;
  height: 10px;
`;
