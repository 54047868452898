import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { postRequest, putRequest } from "../../services";
import { APICONFIG } from "../../services/apiConfig";
import { menuKeys as m } from "../menus/menuKeys.constants";
import { Form, FormRow } from "../common/form";
import { Box, FlexRight } from "../common";
import { CardContainer } from "../common/CardContainer";
import { CodePageTitle } from "../common/page";
import { FormItem } from "../paliq/input/FormItem";
import { PageTitleStatus } from "../common/tags";
import { Button, Input, message, Steps } from "antd";
import { AuthContext } from "../../App";
import Icon, {
  AuditOutlined, CheckOutlined,
  DollarCircleOutlined,
  FileProtectOutlined,
  MinusOutlined,
  UserOutlined
} from "@ant-design/icons";
import { Blank, PlanOutlined } from "../../assets/img/icons/sideBarIcons";



const WorkflowForm = (props) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [heading, setHeading] = React.useState("");
  const [isDefault, setIsDefault] = useState(false);


  const { data } = props;
  const { uid, status } = data;

  useEffect(() => setHeading(data['workflow_name'] ?? ''), [data]);
  useEffect(() => {
    form.setFieldsValue(data);
    setIsDefault(data['is_default'])
  }, [data]);

  const onFinish = async () => {
    try {
      const row = await form.validateFields();
      row['is_default'] = isDefault;
      let response;
      if (uid) {
        response = await putRequest(`${APICONFIG.getWorkflowsUrl}/${uid}`, row)
      } else {
        response = await postRequest(`${APICONFIG.getWorkflowsUrl}`, row)
      }
      if (response['data']) {
        message.success(`Workflow is updated`);
        await queryClient.invalidateQueries(['workflows']);
        navigate(m.workflows.route);
        return;
      }
      message.error("An error occurred while processing your request. Please try again.");
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const onFinishFailed = async (values) => {
    console.log("Form onFinishFailed = ", values);
  }

  const onValuesChange = (change, all) => {
    setHeading(all['workflow_name']);
  }

  const partnerLabels = {
    "aws_marketplace": "AWS Marketplace",
    "netsuite": "Netsuite",
    "salesforce": "Salesforce",
    "stripe": "Stripe",
    "paliq": "Paliq",
  }

  return (
    <>
      <CodePageTitle title={uid ? heading : "Create Workflow"}  buttons={<PageTitleStatus status={uid ? status: 'new'} />}/>
      <CardContainer>
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          onValuesChange={onValuesChange}
        >
          <Box my={12}>
            <FormRow mb={72} spans={[8, 4, 4]}>
              <FormItem
                name="workflow_name"
                label="Workflow Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter the workflow name"
                  }
                ]}
                placeholder="Enter workflow name"
                type="text"
              />
              <FormItem
                name="sales_channel"
                label="Sales Channel"
                rules={[
                  {
                    required: true,
                    message: "Please enter the sales channel"
                  }
                ]}
                placeholder="Enter sales channel"
                type="selectkvp"
                data={[
                  { label: "PLG", value: "plg" },
                  { label: "Sales Led", value: "sales" },
                  { label: "Partner", value: "partner" },
                  { label: "AWS", value: "aws" },
                  { label: "Azure", value: "azure" },
                  { label: "GCP", value: "gcp" },
                ]}
              />
              <FormItem
                name="is_default"
                type="switch"
                label="Is Default Workflow"
                checkedChildren="&nbsp;Yes&nbsp;"
                unCheckedChildren="&nbsp;No&nbsp;"
                onChange={(d) => setIsDefault(d)}
                checked={isDefault}
              />
            </FormRow>
            <FormRow mb={72} spans={[24]}>
              <Steps direction="horizontal"
                     items={[
                       {
                         title: 'Accounts',
                         description: <FormItem style={{minWidth: 200}}
                           name="accounts_partner"
                           type="selectkvp"
                           data={[
                             { label: "Netsuite", value: "netsuite" },
                             { label: "Paliq", value: "paliq" },
                             { label: "Salesforce", value: "salesforce" },
                             { label: "Stripe", value: "stripe" },
                           ]}
                         />,
                         status: 'finish',
                         icon: <UserOutlined />,
                       },
                       {
                         title: 'Contracts',
                         description: <FormItem style={{minWidth: 200}}
                           name="contracts_partner"
                           type="selectkvp"
                           data={[
                             { label: "Netsuite", value: "netsuite" },
                             { label: "Paliq", value: "paliq" },
                             { label: "Salesforce", value: "salesforce" },
                             { label: "Stripe", value: "stripe" },
                           ]}
                         />,
                         status: 'finish',
                         icon: <FileProtectOutlined />,
                       },
                       {
                         title: 'Pricing Plans',
                         description: <FormItem style={{minWidth: 200}}
                           name="plans_partner"
                           type="selectkvp"
                           data={[
                             { label: "Netsuite", value: "netsuite" },
                             { label: "Paliq", value: "paliq" },
                             { label: "Salesforce", value: "salesforce" },
                             { label: "Stripe", value: "stripe" },
                           ]}
                         />,
                         status: 'finish',
                         icon: <Icon component={PlanOutlined} />,
                       },
                       {
                         title: 'Invoices',
                         description: <FormItem style={{minWidth: 200}}
                           name="invoices_partner"
                           type="selectkvp"
                           data={[
                             { label: "Netsuite", value: "netsuite" },
                             { label: "Paliq", value: "paliq" },
                             { label: "Salesforce", value: "salesforce" },
                             { label: "Stripe", value: "stripe" },
                           ]}
                         />,
                         status: 'finish',
                         icon: <AuditOutlined />,
                       },
                       {
                         title: 'Payments',
                         description: <FormItem style={{minWidth: 200}}
                           name="payments_partner"
                           type="selectkvp"
                           data={[
                             { label: "Netsuite", value: "netsuite" },
                             { label: "Paliq", value: "paliq" },
                             { label: "Stripe", value: "stripe" },
                           ]}
                         />,
                         status: 'finish',
                         icon: <DollarCircleOutlined />,
                       },
                       {
                         title: '',
                         status: 'finish',
                         icon: <Icon component={Blank} />
                       },
                     ]}
              />
            </FormRow>
          </Box>

          <FlexRight>
            <Box mr={2}>
              <Button
                size="large"
                onClick={() => navigate(m.workflows.route)}
              >
                Cancel
              </Button>
            </Box>
            <Button size="large" type="primary" htmlType="submit">
              Submit
            </Button>
          </FlexRight>
        </Form>
      </CardContainer>
    </>
  );
};

export default WorkflowForm;
