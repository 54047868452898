import React from "react";
import { Button, Collapse, Popconfirm, Space } from "antd";
import styled from "@emotion/styled";
import { CloseIcon } from "./icons";
import { getCustomerCreditsViewEditKey } from "../menus/menuKeys.constants";

export const CollapsibleCard = ({ title, onDelete, deleteIcon, cardId, children, accessories,defaultKey }) => {
  // TODO: might want to pass in the pop confirm title as a prop
  let dAkey = defaultKey ? undefined: 1 ;
  let deleteButton = onDelete ? (
    <>
      {accessories}
      <Popconfirm
      title="Are you sure to delete this Service from the Plan?"
      onClick={e => e.stopPropagation()}
      onCancel={e => e.stopPropagation()}
      onConfirm={(e) => {
        onDelete && onDelete(cardId);
      }}
      okText="Yes"
      cancelText="No"
      placement="leftBottom"
    >{deleteIcon}</Popconfirm>
      </>
  ) : undefined;
  return (
    <StyledCollapse expandIconPosition="start" defaultActiveKey={dAkey}>
      <Collapse.Panel header={title} extra={deleteButton} key={1}>
        {children}
      </Collapse.Panel>
    </StyledCollapse>
  );
};

// Since the delete icon is located inside the panel header, it by default toggles the collapse/uncollapse of the panel
// We need to control the event's propagation to prevent this from happening
const DeleteTrigger = ({ onClick, ...props }) => (
  <CloseIcon
    onClick={(e) => {
      e.stopPropagation();
      onClick(e);
    }}
    {...props}
  />
);

const StyledCollapse = styled(Collapse)`
  width: 100%;
  margin-bottom: 8px;
  //border-radius: 10px;
  //overflow: hidden;
  border-radius: 5px;
  border-bottom:0px;
  //box-shadow: #009879;
  //border-color:white;
  
  // Collapse
  & .ant-collapse-header.ant-collapse-header {
    // increase the specifity of our rule by doubling the class names
    background-color: #F2F3F4; ////rgba(20, 166, 71, 0.1); ////#ECF6F4;
    font-size: 14px;
    color: #009879;///#009879;
    font-weight: 500;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-right: 8px; // push the delete button a bit away from the collapse button
  }
  & .ant-collapse-expand-icon {
    color: rgba(0, 0, 0, 0.45);
  }
  & .ant-collapse-content {
    border-top-color: #f8f8f8; // make the border between header and content very light
  }
  & .ant-collapse-content-box {
    padding: 16px 16px;
  }

  // Metrics table
  // & table {
  //   margin-top: 8px;
  //   margin-left: -8px;
  //   compensate for the 8px left padding on td
  //   there is a separator between two adjacent tables, which needs to be as wide as the table content (not the table)
  //   therefore we are making this weird push and pull
  //   width: calc(100% + 8px);
  // }
  // & th.ant-table-cell.ant-table-cell.ant-table-cell {
  //   background-color: #fff;
  //   font-size: 15px;
  //   font-weight: 400;
  //   border-bottom-width: 0;
  //   padding: 0 8px;
  // }
  // // remove the verticale border between table column headings
  // &
  //   th.ant-table-cell.ant-table-cell.ant-table-cell.ant-table-cell.ant-table-cell::before {
  //   width: 0;
  // }
  // & td.ant-table-cell.ant-table-cell.ant-table-cell {
  //   font-size: 14px;
  //   font-weight: 500;
  //   padding: 2px 8px;
  //   border-bottom-width: 0;
  // }
  // & .ant-table-wrapper {
  //   padding-bottom: 8px;
  //   padding-top: 12px;
  // }
  // & .ant-table-wrapper + .ant-table-wrapper {
  //   // a line between two adjacent metric tables
  //   border-top: 1px solid rgb(217, 217, 217);
  // }
`;
