import Icon, {
  ApiOutlined,
  AuditOutlined,
  BankOutlined,
  BarChartOutlined,
  CloudSyncOutlined,
  CommentOutlined,
  CreditCardOutlined,
  DashboardOutlined,
  DeploymentUnitOutlined,
  DollarOutlined,
  DollarCircleOutlined,
  FundOutlined,
  LineChartOutlined,
  LinkOutlined,
  LoadingOutlined,
  LogoutOutlined,
  MessageOutlined,
  ProfileOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
  ShopOutlined,
  SwapOutlined,
  TeamOutlined,
  UserOutlined,
  TrademarkCircleOutlined
} from "@ant-design/icons";

import {
  PaliqOutlined,
  PartnerOutline,
  PlanOutlined,
  ServicesOutlined
} from "../../assets/img/icons/sideBarIcons";

// [path, label, icon]
class MenuKey {
  constructor(route, label, icon, type, style) {
    this.route = route;
    this.label = label;
    this.icon = icon;
    this.type = type;
    this.style = style;
  }
}

export const menuKeys = {
  admin: new MenuKey("/admin"),
  api: new MenuKey("/api", "API", <CloudSyncOutlined />),
  chat: new MenuKey("/chat", "Chat", <MessageOutlined />),
  billing: new MenuKey("/billing", "Bills", <AuditOutlined />),
  connected_apps: new MenuKey("/connected", "Connections", <LinkOutlined />),
  credits: new MenuKey("/credits", "Credits", <CreditCardOutlined />),
  customer: new MenuKey("/customers", "Accounts", <UserOutlined />),
  customerCredits: new MenuKey("/customercredits"),
  dashboard: new MenuKey("/dashboard", "Dashboard", <BarChartOutlined />),

  home: new MenuKey(
    "/how-it-works",
    "Using Paliq",
    <Icon component={PaliqOutlined} />
  ),
  imexport: new MenuKey("/imexport", "Import/Export", <SwapOutlined />),
  integrations: new MenuKey("/integrations", "Apps", <ApiOutlined />),
  org: new MenuKey("/organizations", "Organizations", <ShopOutlined />),
  partner: new MenuKey(
    "/partner",
    "Partners",
    <Icon component={PartnerOutline} />
  ),
  plansCreate: new MenuKey("/plans/create"),
  pricing: new MenuKey("/pricing", "Modeler", <LineChartOutlined />),
  acct: new MenuKey("/profile", "Account Details", <ProfileOutlined />),
  reports: new MenuKey("/reports", "Reports", <FundOutlined />),
  company: new MenuKey("/company", "Company", <BankOutlined />),

  meters: new MenuKey("/meters", "Meters", <DashboardOutlined />),
  meterCreate: new MenuKey("/meters/create", "Create New Meter"),
  services: new MenuKey(
    "/services",
    "Services",
    <Icon component={ServicesOutlined} />
  ),
  serviceCreate: new MenuKey("/services/create", "Create New Service"),
  plans: new MenuKey("/plans", "Pricing", <Icon component={PlanOutlined} />),
  planCreate: new MenuKey("/plan/create", "Create New Plan"),

  team: new MenuKey("/team", "Team", <TeamOutlined />),
  paymentMethods: new MenuKey(
    "/payment-methods",
    "Payment Accounts",
    <DollarCircleOutlined />
  ),
  paymentMethodCreate: new MenuKey("/payment-methods/create", "Create New"),
  workflows: new MenuKey("/workflows", "Workflows", <DeploymentUnitOutlined />),
  workflowCreate: new MenuKey("/workflows/create", "Create New Workflow"),
  usage: new MenuKey("/usage", "Usage", <DollarCircleOutlined />),
  contact: new MenuKey("contact", "Contact", <CommentOutlined />),
  more: new MenuKey("more", "More", <SettingOutlined />),
  help: new MenuKey("help", "Help", <QuestionCircleOutlined />),

  payments: new MenuKey(
    "/payments",
    "Payments",
    <Icon component={DollarOutlined} />
  ),
  paymentCreate: new MenuKey("/payment/create", "Create New Payment"),

  coupons: new MenuKey("/coupons", "Coupons", <TrademarkCircleOutlined />),
  couponCreate: new MenuKey("/coupons/create", "Create New Coupon", ""),

  // Menu Right Part
  settings: new MenuKey(
    "/settings",
    "",
    <SettingOutlined style={{ marginLeft: "8px", marginTop: "16px" }} />
  ),
  me: new MenuKey(
    "me",
    "My Profile",
    <UserOutlined />
  ),
  userinfo: new MenuKey("userinfo"),
  divider: new MenuKey("divider", null, null, "divider"),
  logout: new MenuKey("logout", "Logout", null, null, {
    height: "30px",
    fontSize: "12px",
    lineHeight: "30px"
  }),
  password: new MenuKey("password", "Change Password", null, null, {
    height: "30px",
    fontSize: "12px",
    lineHeight: "30px",
    marginBottom: "-6px"
  })
};

class ItemKey {
  constructor(route, label) {
    this.route = route;
    this.label = label;
  }

  getRoute = (values) => {
    let r = this.route;
    for (const [key, value] of Object.entries(values)) {
      r = r.replace(`:${key}`, value);
    }
    return r;
  };
}
export const itemKeys = {
  meterDetail: new ItemKey("/meters/:id/detail", "Details"),
  serviceDetail: new ItemKey("/services/:id/detail", "Details"),
  planDetail: new ItemKey("/plan/:id/detail", "Details"),
  paymentMethodDetail: new ItemKey("/payment-methods/:id/detail", "Details"),
  workflowDetail: new ItemKey("/workflows/:id/detail", "Details"),
  paymentDetail: new ItemKey("/payment/:id/detail", "Details"),
  couponDetail: new ItemKey("/coupons/:id/detail", "Details")
};

export const getPlanEditKey = (id) => `${menuKeys.plans.route}/${id}/edit`;
export const getServiceEditKey = (id) =>
  `${menuKeys.services.route}/${id}/edit`;
export const getServiceMetersViewEditKey = (id) =>
  `${menuKeys.old_serviceMeters.route}/${id}/edit`;
export const getCustomerCreditsViewEditKey = (id) =>
  `${menuKeys.customerCredits.route}/${id}/edit`;
