import moment from 'moment';
import { info } from "../../../utils/commonActions";

/* Credits res is like:
{
    "credits": [
        {
            "uid": "2281a562-c622-4e28-ae08-1f3e8086e252",
            "account_id": "d9edb5ce-e437-423c-ac9c-47ca7c3665ac",
            "credit_amount": 1000.0,
            "credit_name": "Refund",
            "start_date": "2022-09-01",
            "end_date": "2022-10-01",
            "date_created": "2022-09-23 05:44:29.876243+00:00",
            "date_updated": "2022-09-23 05:44:29.876240+00:00"
        },
        {
            "uid": "7012d388-9bad-4020-9c87-665f2e5c63ac",
            "account_id": "d9edb5ce-e437-423c-ac9c-47ca7c3665ac",
            "credit_amount": 500.0,
            "credit_name": "Trial",
            "start_date": "2022-09-01",
            "end_date": "2022-10-01",
            "date_created": "2022-09-23 05:44:53.177254+00:00",
            "date_updated": "2022-09-23 05:44:53.177252+00:00"
        }
    ]
}
*/

// positions 0 and 1 are non dates. 2 and 3 are dates (moment handling).
const tfNonDated = ["credit_name", "credit_amount","credit_bought"];
const tfDated = [ "start_date", "end_date"];
const tableFields = [...tfNonDated, ...tfDated];

export const createNewCreditRow = () => {
  const retVal = {};
  tableFields.map(i => {
    retVal[i] = null;
  })
  retVal.uid = null;
  return retVal;
};

export const getFieldsValueFromCreditsList = (credits) => {
  const retVals = {};
  credits.map(c => { // since only end_date is editable for credits list.
    //const monentObj = moment(c.end_date);
    //console.log("moment obj = ", monentObj);
    //retVals[`${c.uid}.end_date`] = moment(c.end_date); // will be used when we implement datepicker properly
    if (c.uid.length < 22) {
      tableFields.map(t => {
        retVals[`${c.uid}.${t}`] = c[t];
      });
    } else {
      retVals[`${c.uid}.end_date`] = moment(c.end_date);
    };
  });
  return retVals;
}

export const getPayloadsFromTouchedFormValues = (formValues, credits) => {
  const newCreditsJSON = []; // single call to add many credit entries.
  const updateCreditJSONs = []; // calc for now. Don't know the endpoint

  credits.map(v => {
    if (v.uid.length < 22) { // new entry
      let newEntry = {};
      tfNonDated.map(t => {
        newEntry[t] = formValues[`${v.uid}.${t}`];
      });
      tfDated.map(t=>{ // formvalue is a moment object
        newEntry[t] = formValues[`${v.uid}.${t}`].format('YYYY-MM-DD'); 
      });
      newCreditsJSON.push(newEntry);
    } else { // old credit 
      const newEndDate = formValues[`${v.uid}.end_date`]; // undefined if not touched. its a moment object now
      if (newEndDate) updateCreditJSONs.push({ uid: v.uid, end_date: newEndDate.format('YYYY-MM-DD')}); // just need uid and end_date for update.
    }
  });
  return [newCreditsJSON, updateCreditJSONs];
}

// RQ helpers

export const onAddCreditSuccess = () => {
  console.log('New Credits Added to Customer')
  const message = "Success: New Credit(s) Assigned to Customer"
  info(message);

};

export const onNewCreditsError = err => {
  console.log("Error = ", err);
  const message = "Error: New Credits Assignment Failed "
  info(message);
};
