import { CheckCircleOutlined, CheckSquareOutlined, EditOutlined, ExperimentOutlined } from "@ant-design/icons";
import React from "react";

class Status {
  static isDraft = (s) => s === "draft";
  static isTest = (s) => s === "test";
  static isActive = (s) => s === "active";

  static toNumber = (s) => {
    switch (s) {
      case "draft":
        return 1;
      case "test":
        return 2;
      case "active":
        return 3;
      default:
        return 0;
    }
  }

  static toStatus = (i) => {
    switch (i) {
      case 1:
        return "draft";
      case 2:
        return "test";
      case 3:
        return "active";
      default:
        return "";
    }
  }

  static icon = (s) => {
    switch (s) {
      case "draft": return <EditOutlined/>;
      case "test": return <ExperimentOutlined/>;
      case "active": return <CheckCircleOutlined/>;
      default: return <EditOutlined/>;
    }
  }

  static getOverallStatus = (ss) => {
    let ms = 3;
    for (let i in ss) {
      let n = Status.toNumber(ss[i]);
      ms = n < ms ? n : ms
    }
    ms = ss.length > 0 ? ms : 1;
    return Status.toStatus(ms);
  }
}

export default Status;