import { useEffect, useState } from "react";
import { Form, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import CustomerPlansDetails from "./CustomerPlansDetails";
import { Box, FlexRight, Spinner } from "../../../common";
import {
  getCustomerPlans,
  assignNewPlanToCustomer,
  updatePlanOfCustomer
} from "../../../../services/customerFormAction";
import {
  updateCustomerPlanJSONsWithFieldValues,
  prepPayloadForCustomerPlan,
  areStartEndDatesDifferent,
  onAddNewCustomerPlanSuccess,
  onAddNewCustomerPlanError,
  onCustomerPlanUpdateSuccess,
  onCustomerPlanUpdateError
} from "../../customer-helper";
import { info } from "../../../../utils/commonActions";
import { getRequest } from "../../../../services";
import { APICONFIG } from "../../../../services/apiConfig";

const CustomerPlans = ({ accountData, id }) => {

  console.log(accountData);
  console.log(id);

  const [form] = Form.useForm();

  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const onSettled = () => {
    queryClient.invalidateQueries(["allcustomerplans"]);
  };

  const {
    isLoading: isCustPlansLoading,
    error: custPlanLoadError,
    data: plansData
  //} = useQuery(["allcustomerplans", accountData.external_guid, "plans"], () => getCustomerPlans(accountData.external_guid), {
  } = useQuery(["allcustomerplans", accountData.id, "plans"], () => getCustomerPlans(id), {
    staleTime: Infinity
  }); // planData is the JSON for this service coming in from the server.

  // get all plans in play currently
  const {isLoading: isPlansListLoading, error: allPlansLoadError, data: pricingPlans} = useQuery({
    queryKey: ["plans"],
    queryFn: () => getRequest(APICONFIG.getPlansUrl).then(r => r['data'])
  });

  const newCustomerPlanMutation = useMutation(assignNewPlanToCustomer, {
    onSuccess: onAddNewCustomerPlanSuccess,
    onError: onAddNewCustomerPlanError,
    onSettled: onSettled
  });
  const updateCustomerPlanMutation = useMutation(updatePlanOfCustomer, {
    onSuccess: onCustomerPlanUpdateSuccess,
    onError: onCustomerPlanUpdateError,
    onSettled: onSettled
  });

  const [accountPlans, setAccountPlans] = useState([]);

  useEffect(() => {
    setAccountPlans(plansData?.plans ?? []);
    return () => {};
  }, [plansData]); // we need to store it as a state var since we will mutate it by adding plans for a customer.

  if (isCustPlansLoading || isPlansListLoading)
    return (
      <Box height="70vh">
        <Spinner />
      </Box>
    );
 if (custPlanLoadError)
    return (
      "An error has occurred in laoding this customer's plans: " +
      custPlanLoadError.message
    );
  if (allPlansLoadError)
    return (
      "An error has occurred in loading Plans's list: " +
      allPlansLoadError.message
    );

  const onFinish = async (values) => {
    // values are valid.
    // we may want to check for start dates and end dates not being same here.
    const areDiff = areStartEndDatesDifferent(values, accountPlans);
    if (!areDiff) {
      info("Start and End Dates should be different for plans");
      return;
    }

    const newPlanJSONs = updateCustomerPlanJSONsWithFieldValues(
      accountPlans,
      values
    );

    newPlanJSONs.map(async (pj) => {
      const payload = prepPayloadForCustomerPlan(pj);
      if (pj.id) {
        // id exists so its an existing customer assigned plan
        console.log("updating CP = ", payload);
        await updateCustomerPlanMutation.mutateAsync({
          cust_id: id,
          cust_plan_id: pj.id,
          data: payload
        });
      } else {
        // no id. So its a new assignment of a plan to the customer
        console.log("adding CP = ", payload);
        await newCustomerPlanMutation.mutateAsync({
          cust_id: id,
          data: payload
        });
      }
    });
    //navigate("/customers");
  };

  const onFinishFailed = () => {
    // Call when service Fails
  };

  return (
    <Form
      layout="vertical"
      autoComplete="off"
      form={form}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <CustomerPlansDetails
        accountPlans={accountPlans}
        setAccountPlans={setAccountPlans}
        pricingPlans={pricingPlans}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        customerId={id}
        external_guid = {accountData?.external_guid}
      />
      {
        <FlexRight>
          <Box mr={2}>
            <Button
              size="large"
              onClick={() => {
                navigate("/customers");
              }}
            >
              Cancel
            </Button>
          </Box>
          <Button
            size="large"
            type="primary"
            htmlType="submit"
            disabled={!!!id}
          >
            Submit
          </Button>
        </FlexRight>
      }
    </Form>
  );
};

export default CustomerPlans;
