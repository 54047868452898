import { getRequest } from "../../services";
import {
  APICONFIG,
  getAllBillsUrl,
  getBillDetails,
  getTodateBillUrl,
  getBillDownloadPresignedURL
} from "../../services/apiConfig";
import { concatStrings, formatDate, formatQty } from "../common/table";

export const getAllBills = async (
  externalGUID = "",
  from_mo = 6,
  to_mo = 1
) => {
  const res = await getRequest(
    `${getAllBillsUrl(externalGUID, from_mo, to_mo)}`
  );
  console.log("getAllBills res = ", res);
  return res;
};

export const getBillingDetails = async (id) => {
  const res = await getRequest(`${getBillDetails(id)}`);
  console.log("getAllBills res = ", res);
  return res;
};

export const getBillingDownloadUrl = async (id) => {
  const res = await getRequest(`${getBillDownloadPresignedURL(id)}`);
  const url = res.result.download_uri;
  return url;
};

export const getBillPdf = async (id) => {
  const url = await getBillingDownloadUrl(id);
  let a = document.createElement("a");
  a.href = url;
  a.download = "sample";
  a.click();
};

export const getTodateBill = async (cust_id, plan_id) => {
  console.log("getTodateBill cust_id = ", cust_id);
  console.log("getTodateBill plan_id = ", plan_id);
  const res = await getRequest(`${getTodateBillUrl(cust_id, plan_id)}`);
  console.log("getTodateBill res = ", res);
  return res;
};

export const prepareBillServiceData = (billedServices, currency) => {
  const retVals = [];
  console.log("prepareService Data req = ", billedServices);
  billedServices.map((bs) => {
    const service_name = bs.service_name;
    const effective_price = bs.charges;
    var usage_details = "";
    bs.billed_meters?.map((bms) => {
      usage_details =
        usage_details +
        bms.meter_name +
        " Qty:" +
        formatQty(bms.meter_usage) +
        " @ " +
        concatStrings(currency, formatQty(bms.charges)) +
        "\r\n" +
        ",";
      console.log("usage detais = " + usage_details);
    });

    retVals.push({
      service_name: service_name,
      usage_details: usage_details.substring(0, usage_details.length - 1),
      effective_price: effective_price
    });
  });
  console.log("values for service table = ", retVals);
  return retVals;
};

export const getBillingPeriod = (start_date, end_date) => {
  return formatDate(start_date) + " - " + formatDate(end_date);
};
