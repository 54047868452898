import { Form, Button } from "antd";
import { useNavigate } from "react-router-dom";
import CustomerBasicInfo from "../../CustomerBasicInfo";
import { Box, FlexRight } from "../../../common";
import { TabContainer } from "../../../common/page";
import {
  createNewCustomer,
  updateCustomerInfo
} from "../../../../services/customerFormAction";
import { prepPayloadForCustomerInfo } from "../../customer-helper";
import { ACTIONS } from "../../../../constants";
import { error, info } from "../../../../utils/commonActions";

const AccountInfo = ({ id, action, accountData, setAccountData, onAccountDetail }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const onFinish = async (values) => {
    try {
      const customerInfoPayload = prepPayloadForCustomerInfo(
        values,
        action === ACTIONS.CREATE
      );
      console.log(customerInfoPayload);

      if (action === ACTIONS.CREATE) {
        // new customer creation
        await createNewCustomer({ data: customerInfoPayload });
      } else {
        await updateCustomerInfo({ data: customerInfoPayload, id });
      }
      info("Successfully updated Account info");
      navigate("/customers");
    } catch (err) {
      console.error(err);
      error("Error updating Account info");
    }
  };

  return (
    <Form layout="vertical" autoComplete="off" form={form} onFinish={onFinish}>
      {/*<TabContainer>*/}
        <CustomerBasicInfo
          customerData={accountData}
          action={action}
          editable={true}
          onFinish={onFinish}
        />

        {
          <FlexRight>
            <Box mr={2}>
              <Button
                size="large"
                onClick={() => {
                  if(id) {
                    onAccountDetail(true);
                  } else {
                    navigate("/customers");
                  }
                }}
              >
                Cancel
              </Button>
            </Box>
            <Button size="large" type="primary" htmlType="submit">
              Submit
            </Button>
          </FlexRight>
        }
      {/*</TabContainer>*/}
    </Form>
  );
};

export default AccountInfo;
