import React from "react";
import EmptyPage from "../../components/paliq/pages";
import { menuKeys as m } from "../../components/menus/menuKeys.constants";

const CreatePaymentMethodStub = () => {
  const { route, label } = m.paymentMethodCreate;

  return (
    <EmptyPage
      heading="Payment Methods"
      subHeading="Manage your payment methods."
      url={route}
      buttonText={label}
    />
  );
};

export default CreatePaymentMethodStub;
