import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Table, Form } from "antd";
import { useNavigate } from "react-router-dom";
import { Box, FlexRight, Spinner } from "../../../common";
import React, { useEffect, useState, createContext, useContext } from "react";
import {
  getCustomerConatactsId,
  updateCustomerContacts,
  deleteCustomerContact
} from "../../../../services/customerFormAction";
import { info, error } from "../../../../utils/commonActions";
import { ActionGroup, ActionItem } from "../../../common/table";
import { FormItem } from "../../../paliq/input/FormItem";

const EditableContext = createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false} layout="horizontal">
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  type,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const form = useContext(EditableContext);
  useEffect(() => {
    form.setFieldsValue(record);
    form.validateFields();
  }, []);

  const save = async () => {
    try {
      const values = form.getFieldsValue();
      handleSave({ ...record, ...values });
      form.validateFields();
    } catch (err) {}
  };

  let childNode = children;
  if (editable) {
    childNode = (
      <FormItem
        type={type}
        noLabel
        name={dataIndex}
        onChange={save}
        checked={record[dataIndex]}
        size="large"
        rules={[{ required: true, message: "This field is required" }]}
      />
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

const ContactField = ({ id }) => {
  const [contacts, setContacts] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        if (id) setContacts(await getCustomerConatactsId(id));
      } catch (err) {
        error("Error getting contracts");
        console.error(err);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      width: "20%",
      type: "text",
      editable: true
      // inputType: "text"
    },
    {
      title: "Email Id",
      dataIndex: "email",
      width: "20%",
      type: "text",
      editable: true
      // inputType: "text"
    },
    {
      title: "Phone No.",
      dataIndex: "phone",
      width: "20%",
      editable: true,
      type: "text"
    },
    {
      title: "Status",
      dataIndex: "isActive",
      width: "10%",
      editable: true,
      type: "switch"
    },

    {
      title: "Primary",
      dataIndex: "is_primary",
      width: "10%",
      editable: true,
      type: "switch"
    },
    {
      title: "Billing",
      dataIndex: "is_billing",
      width: "10%",
      editable: true,
      type: "switch"
    },
    {
      title: "Actions",
      dataIndex: "operation",
      width: "10%",
      editable: false,
      render: (_, record) => {
        return (
          <ActionGroup>
            <ActionItem
              icon={<DeleteOutlined />}
              confirm="Sure to delete?"
              onConfirm={() => handleDelete(record.id)}
              tooltip="Delete Metric"
            />
          </ActionGroup>
        );
      }
    }
  ].map((col) => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        type: col.type,
        handleSave
      })
    };
  });

  const handleDelete = async (id) => {
    try {
      if (id) {
        await deleteCustomerContact(contacts[0].customer_uid, id);
        info("Contacts Deleted");
      }
      setContacts(contacts.filter((item) => item.id !== id));
    } catch (err) {
      error("Error Deleting Contact");
      console.error(err);
    }
  };

  const handleSave = (row) => {
    const newContacts = [...contacts];
    const index = newContacts.findIndex((item) => row.id === item.id);
    const item = newContacts[index];
    newContacts.splice(index, 1, { ...item, ...row });
    setContacts(newContacts);
  };

  const addContact = () => {
    const newContact = {
      name: "",
      email: "",
      phone: "",
      is_billing: true,
      is_primary: true,
      isActive: true,
      status: "active"
    };
    setContacts([...contacts, newContact]);
  };

  const validateContacts = () => {
    for (const contact of contacts) {
      console.log(contact);
      if (!contact.name || !contact.email || !contact.phone) {
        return false;
      }
    }
    return true;
  };

  const onFinish = async () => {
    if (!validateContacts()) {
      error("Please fill out all the inputs");
      return;
    }
    const newContacts = contacts.map((contact) => {
      contact.status = contact.isActive ? "active" : "inactive";
      return contact;
    });
    if (newContacts.length > 0) {
      try {
        await updateCustomerContacts({
          data: newContacts,
          id
        });
        info("Contacts Updated");
      } catch (err) {
        console.log(err);
        error("Error Updating Contact");
      }
    }
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell
    }
  };

  return isLoading ? (
    <Box height="70vh">
      <Spinner />
    </Box>
  ) : (
    <>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={addContact}
        style={{ marginBottom: "16px" }}
        disabled={!!!id}
      >
        Add Another Contact
      </Button>

      {contacts && contacts.length > 0 && (
        <Table
          components={components}
          rowClassName={() => "editable-row"}
          dataSource={contacts}
          columns={columns}
          rowKey={"id"}
        />
      )}
      <FlexRight mt={4}>
        <Box mr={2}>
          <Button
            size="large"
            onClick={() => {
              navigate("/customers");
            }}
          >
            Cancel
          </Button>
        </Box>
        <Button size="large" type="primary" onClick={onFinish} disabled={!!!id}>
          Submit
        </Button>
      </FlexRight>
    </>
  );
};

export default ContactField;
