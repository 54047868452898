import axios from "axios";
import { responseCodes } from "../constants/index";
import { logoutFromLS, getIdTokenfromLS } from "../utils/helper";
import { refreshIdToken } from "./auth";

const axiosClient = axios.create();

// Intercept request
axiosClient.interceptors.request.use(
  (request) => {
    request.headers["Content-Type"] = "application/json";
    return request;
  },
  null,
  { synchronous: true }
);


axiosClient.defaults.headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
  Authorization: getIdTokenfromLS()
};

// All request will wait 1 min before timeout
axiosClient.defaults.timeout = 20000;

// axiosClient.defaults.withCredentials = true;
export default axiosClient;
