import React, { useState, useEffect } from "react";
import { Table } from "antd";
import { useQuery } from "@tanstack/react-query";
import {
  getBillingDetails,
  getBillingPeriod,
  prepareBillServiceData
} from "../../../billing/billing_helper.js";
import { Spinner, Box } from "../../../common";
import {
  withTooltip,
  formatDate,
  concatStrings,
  formatQty,
  formatMoney
} from "../../../common/table";
import { SectionTitle, TabContainer } from "../../../common/page";
import { StatusTag } from "../../../common/tags";
import { FormRow } from "../../../common/form";
import {
  AmountBreakdownPanel,
  AttributesPanel
} from "../../../common/InfoPanel";
import { CardContainer } from "../../../common/CardContainer.jsx";

// for cascader

const CustomerBillDetail = ({ id }) => {
  const [billDetails, billServiceDetails] = useState([]);
  const {
    isLoading,
    error,
    data: dataSource
  } = useQuery(["allbilldetails", id], () => getBillingDetails(id), {
    select: ({ result }) => result
  });

  useEffect(() => {
    billServiceDetails(
      prepareBillServiceData(
        dataSource?.billed_services ?? [],
        dataSource?.currency_symbol
      )
    );
    return () => {};
  }, [dataSource]);

  if (isLoading)
    return (
      <Box height="70vh">
        <Spinner />
      </Box>
    );
  if (error) return "An error has occurred: " + error.message;

  const columns = [
    {
      title: "Service Name",
      dataIndex: "service_name",
      width: "15%"
    },
    {
      title: "Usage Details",
      dataIndex: "usage_details",
      width: "30%"
    },
    {
      title: "Service Price",
      dataIndex: "effective_price",
      width: "15%",
      render: (_, record) =>
        concatStrings(
          dataSource.currency_symbol,
          formatQty(record.effective_price)
        )
    }
  ];

  return !dataSource ? (
    ""
  ) : (
    <>
      <FormRow spans={[14, 10]} mb="20px">
        <AttributesPanel
          bordered={false}
          cols={2}
          attrs={[
            ["Customer Name", dataSource.customer_name],
            ["External Customer ID", dataSource.external_customer_id],
            [
              "Billing Period",
              getBillingPeriod(dataSource.start_at, dataSource.end_at)
            ],
            ["Bill To", dataSource.billing_email],
            ["Due Date", formatDate(dataSource.due_date)],
            ["Status", <StatusTag status={dataSource.status} />],
            ["Discount %", concatStrings(dataSource.discount_percent, "%")],
            [
              "Tax Rate %",
              concatStrings(dataSource.sales_tax_percent * 100, "%")
            ]
          ]}
        />
        <AmountBreakdownPanel
          props={[
            [formatMoney(dataSource.currency_code, dataSource.charges)],
            [formatMoney(dataSource.currency_code, dataSource.credits)],
            [formatMoney(dataSource.currency_code, dataSource.discount)],
            [formatMoney(dataSource.currency_code, dataSource.sub_total)],
            [formatMoney(dataSource.currency_code, dataSource.tax)],
            [formatMoney(dataSource.currency_code, dataSource.total)]
          ]}
        />
      </FormRow>
      <SectionTitle title="Line Items" />
      <Table
        bordered={true}
        scroll={{ y: 300 }}
        dataSource={billDetails}
        columns={withTooltip(columns)}
        childrenColumnName="meter_usage_costs"
        rowKey={(record) => record.service_name}
        pagination="pagination"
      />
    </>
  );
};

export default CustomerBillDetail;
