import React, { useEffect, useState } from "react";
import { Link, useSearchParams, useParams } from "react-router-dom";
import { Box, Spinner } from "../../../common";
import {
  withTooltip,
  formatDate,
  formatMoney,
  concatStrings
} from "../../../common/table";
import { StatusTag } from "../../../common/tags";
import {
  Table,
  Button,
  Typography,
  InputNumber,
  Input,
  Form,
  Popconfirm
} from "antd";
import {
  DeleteOutlined,
  PlusOutlined,
  CheckOutlined,
  CloseOutlined,
  EditOutlined
} from "@ant-design/icons";
import { useQuery } from "@tanstack/react-query";
import {
  getRequest,
  postRequest,
  putRequest,
  deleteRequest
} from "../../../../services";
import {
  APICONFIG,
  getAccountPaymentMethods,
  getAccountPaymentMethodsByExternalId
} from "../../../../services/apiConfig";
import { info } from "../../../../utils/commonActions";
import { FormItem } from "../../../paliq/input/FormItem";
import { PAYMENT_METHOD_TYPE } from "../../../../constants/globalConstants";
import { SubTitle, SectionTitle } from "../../../common/page";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  type,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {editing ? (
        <FormItem
          name={dataIndex}
          noLabel
          type={type}
          data={PAYMENT_METHOD_TYPE}
          size="large"
          rules={[{ required: true, message: "This field is required" }]}
          defaultChecked={dataIndex === "is_active"}
        />
      ) : (
        children
      )}
    </td>
  );
};

const CustomerPaymentMethodsList = () => {
  const [searchParams] = useSearchParams();
  const account_id = searchParams.get("external-id") ?? "";
  const { id } = useParams(); // action can be create, edit or view.
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [editingKey, setEditingKey] = useState("");
  const [newItem, setNewItem] = useState(0);

  useEffect(() => {
    setLoading(true);
    getRequest(getAccountPaymentMethodsByExternalId(account_id))
      .then(({ data }) => {
        setPaymentMethods(data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
    setEditingKey("");
  }, [newItem]);

  const isEditing = (record) => {
    return record.uid === editingKey;
  };

  const edit = (record) => {
    form.setFieldsValue({
      name: record.name,
      type: record.type,
      is_active: true,
      is_primary: false,
      ...record
    });
    setEditingKey(record.uid);
  };

  const cancel = (record) => {
    if (record.uid === "adding") {
      const newPaymentMethods = [...paymentMethods];
      newPaymentMethods.pop();
      setPaymentMethods(newPaymentMethods);
    }
    setEditingKey("");
  };

  const save = async (record) => {
    try {
      const row = await form.validateFields();
      if (record.uid === "adding") {
        const result = await postRequest(
          `${APICONFIG.accountPaymentMethodUrl}`,
          {
            account_uid: id,
            ...row
          }
        );
      } else {
        console.log(id);
        console.log(record);
        const result = await putRequest(
          `${APICONFIG.accountPaymentMethodUrl}/${record.uid}`,
          {
            account_uid: id,
            ...row,
            external_account_id: record.external_account_id,
            uid: record.uid
          }
        );
      }
      setNewItem(newItem + 1);
      setEditingKey("");
    } catch (errInfo) {
      console.error(errInfo);
    }
  };

  const remove = async (record) => {
    try {
      const result = deleteRequest(
        `${APICONFIG.accountPaymentMethodUrl}/${record.id}`
      );
      getRequest(getAccountPaymentMethodsByExternalId(account_id))
        .then((res) => {
          console.log(res["data"]);
        })
        .catch((err) => {
          console.error(err);
        });
      if (result) {
        setNewItem(newItem + 1);
        setEditingKey("");
      }
    } catch (err) {
      console.error(err);
    }
  };

  if (isLoading)
    return (
      <Box height="70vh">
        <Spinner />
      </Box>
    );

  const addPaymentMethod = () => {
    if (editingKey !== "adding") {
      form.setFieldsValue({
        name: "",
        type: "cc",
        is_active: true,
        is_primary: false
      });
      form.setFieldValue("is_active", true);
      const newPaymentMethod = {
        uid: "adding",
        name: "",
        type: "cc",
        is_active: true,
        is_primary: false,
        payment_methods_app:'paliq'
      };
      setEditingKey("adding");
      setPaymentMethods([...paymentMethods, newPaymentMethod]);
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      width: "25%",
      type: "text",
      editable: true
    },
    {
      title: "Type",
      dataIndex: "type",
      width: "20%",
      type: "select",
      editable: true,
      render: (_, record) =>
        PAYMENT_METHOD_TYPE.find(({ value }) => value === record.type).label
    },
    {
      title: "Active",
      dataIndex: "is_active",
      width: "20%",
      type: "switch",
      editable: true,
      render: (_, record) => (
        <StatusTag status={record.is_active ? "Yes" : "No"} />
      )
    },
    {
      title: "Default",
      dataIndex: "is_primary",
      width: "20%",
      type: "switch",
      editable: true,
      render: (_, record) => (
        <StatusTag status={record.is_primary ? "Default" : "No"} />
      )
    },
    {
      title: "Actions",
      dataIndex: "action",
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <>
            <Button
              onClick={() => save(record)}
              type="link"
              icon={<CheckOutlined />}
            />
            &nbsp;
            <Button
              type="link"
              icon={<CloseOutlined />}
              onClick={() => {
                cancel(record);
              }}
            />
          </>
        ) : (
          <>
            <Button
              onClick={() => edit(record)}
              type="link"
              icon={<EditOutlined />}
            />
            &nbsp;
            <Popconfirm
              title="Sure to delete?"
              onConfirm={() => {
                remove(record);
              }}
            >
              <Button type="link" icon={<DeleteOutlined />} />
            </Popconfirm>
          </>
        );
      }
    }
  ];

  const mergedColumns = withTooltip(columns).map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        dataIndex: col.dataIndex,
        title: col.title,
        type: col.type,
        editing: isEditing(record)
      })
    };
  });

  return isLoading ? (
    <Box height="70vh">
      <Spinner />
    </Box>
  ) : (
    <>
      <SectionTitle title={"Payment Methods"} />
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={addPaymentMethod}
        style={{ marginBottom: "16px", float: "right" }}
        disabled={editingKey === "adding"}
      >
        Add New Payment Method
      </Button>
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell
            }
          }}
          dataSource={paymentMethods}
          columns={mergedColumns}
          rowKey={(record) => record.uid}
          pagination="pagination"
        />
      </Form>
    </>
  );
};

export default CustomerPaymentMethodsList;
