import { Card } from "antd";
import styled from "@emotion/styled";

export const CardBody = styled(Card)`
  border-radius: 5px;
  border-width: 1px;
  box-shadow: #009879;
  overflow: hidden;
  margin-bottom: 24px;
  background-color: white;
`;

export const CardContainer = ({ children, ...props }) => {
  return <CardBody {...props}>{children}</CardBody>;
};
