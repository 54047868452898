import React, { useState } from "react";
import { Button, message, Modal } from "antd";

import { getUserChangePasswordUrl } from "../../services/apiConfig";
import { putRequest } from "../../services";
import { Form, FormRow } from "../../components/common/form";
import { FormItem } from "../../components/paliq/input/FormItem";
import { info } from "../../utils/commonActions";
import { getAccessToken } from "../../utils/helper";

const ChangePasswordModal = ({ show, setShow }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    try {
      if (values["old_password"] === values["new_password"]) {
        info("Old password and new password can not be same");
        return;
      }
      if (values["new_password"] !== values["confirm_password"]) {
        info("New password and confirm password must be same");
        return;
      }
      if (!values["access_token"]) {
        values["access_token"] = getAccessToken();
      }
      setLoading(true);
      const response = await putRequest(getUserChangePasswordUrl(), values);
      console.log(response);

      if (response['error']) {
        message.error(response['error']['message']);
      } else {
        message.success(response['message']);
        form.resetFields();
        setShow(false);
      }
    } catch (err) {
      console.log(err);
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Modal
      title="Change Password"
      centered
      open={show}
      onOk={onFinish}
      onCancel={() => setShow(false)}
      footer={[
        <Button onClick={() => setShow(false)}>I'll do this later</Button>,
        <Button
          loading={loading}
          type="primary"
          key="submit"
          htmlType="submit"
          onClick={() => {
            form.validateFields().then(onFinish);
          }}
        >
          Change Password
        </Button>
      ]}
    >
      <Form layout="vertical" form={form} name="show"
            scrollToFirstError
            requiredMark={false}
      >
        <FormRow mb={24}>
          <FormItem
            name="old_password"
            placeholder="Enter old password"
            type="password"
            label="Old Password"
            rules={[
              {
                required: true,
                message: "Please enter a valid password"
              }
            ]}
          />
        </FormRow>
        <FormRow mb={24}>
          <FormItem
            name="new_password"
            placeholder="Enter new password"
            type="password"
            label="New Password"
            rules={[
              {
                required: true,
                message: "Please enter a valid password"
              }
            ]}
          />
        </FormRow>
        <FormRow mb={24}>
          <FormItem
            name="confirm_password"
            placeholder="Confirm password"
            type="password"
            label="Confirm Password"
            rules={[
              {
                required: true,
                message: "Please enter a valid password"
              }
            ]}
          />
        </FormRow>
      </Form>
    </Modal>
  );
};

export default ChangePasswordModal;
