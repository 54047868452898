const { REACT_APP_LOGIN_URL, REACT_APP_BASE_URL } = process.env;
const loginURL = REACT_APP_LOGIN_URL;
const baseURL = REACT_APP_BASE_URL;

export const APICONFIG = {
  getUserLoginUrl: `${loginURL}/login`,
  getUserLogoutUrl: `${loginURL}/logout`,
  getRefreshTokenUrl: `${loginURL}/refresh-token`,
  getRegisterURL: `${loginURL}/signup/register`,
  getreCaptchaVerificationURL: `${loginURL}/signup/verify-reCaptcha-token`,
  getConfirmSignUpURL: `${loginURL}/signup/confirm`,
  getResendConfirmCodeURL: `${loginURL}/signup/resend`,
  getForgotPswdURL: `${loginURL}/forgot-password`,
  getConfirmForgotPswdURL: `${loginURL}/confirm-forgot-password`,
  getSetPasswordURL: `${loginURL}/set-password`,

  getMetersUrl: `${baseURL}/meter`,
  getServicesUrl: `${baseURL}/service`,
  getPlansUrl: `${baseURL}/plan`,

  getServiceUrl: `${baseURL}/services`,
  getCustomersUrl: `${baseURL}/account?page_size=100`, // for mvp sake
  getPricingPlansUrl: `${baseURL}/pricing-plan`,
  getCustomersS3Url: `${baseURL}/usage/generate-customers-upload-url`,
  getAllOrgsUrl: `${baseURL}/org`,
  getAllUsageDataUrl: `${baseURL}/usage/get-all-usages`,
  getUsageS3Url: `${baseURL}/usage/generate-metrics-upload-url`,
  getTokensUrl: `${baseURL}/access_mgmt/get-api-keys`,
  generateTokensUrl: `${baseURL}/access_mgmt/generate-api-key`,
  getUploadJobStatusUrl: `${baseURL}/usage/get-upload-job-statuses/upload_metrics`,
  getConnectedAppsUrl: `${baseURL}/connected_app`,
  getTeamUrl: `${baseURL}/team/list`,
  getUserInfoUrl: `${baseURL}/users/info`,
  getCompanyInfoUrl: `${baseURL}/company`,
  getUserUpdateUrl: `${baseURL}/team/update`,
  getPaymentMethodsUrl: `${baseURL}/payment-method`,
  getWorkflowsUrl: `${baseURL}/workflow`,
  getPaymentsUrl:`${baseURL}/payment`,

  couponUrl:`${baseURL}/coupon`,
  accountPaymentMethodUrl: `${baseURL}/account_payment_method`,
};

// Old service end points

export const getServiceMeterUrl = (id) =>
  `${baseURL}/services/${id}/service-meters`;

export const getServicePricingUrl = (id) =>
  `${baseURL}/services/${id}/service-pricings`;

export const getUpdateServiceUrl = (id) => `${baseURL}/services/${id}`;


// Old service end points

export const customerContactsUrl = (id) => `${baseURL}/account/${id}/contacts`;

export const customerContactUrl = (id, cid) =>
  `${baseURL}/account/${id}/contacts/${cid}`;


//export const getCompanyServiceListUrl = (id) =>`${baseURL}/comapnies/${id}/services`;

export const getCustomerByIdUrl = (id) => `${baseURL}/account/${id}`;

export const getPlansForCustomerId = (id) => `${baseURL}/account/${id}/plans`;

export const getPlansForExternalCustomerId = (id) => `${baseURL}/account/external/${id}/plans`;

export const getCustomerContactsIdUrl = (id) =>
  `${baseURL}/account/${id}/contacts`;

export const putCustomerContacts = (id) => `${baseURL}/account/${id}/contacts`;

export const postNewPlanForCustomerUrl = (cust_id) =>
  `${baseURL}/account/${cust_id}/plans`;

export const putUpdatePlanForCustomerUrl = (cust_id, plan_id) =>
  `${baseURL}/account/${cust_id}/plans/${plan_id}`;

export const getCustomerCreditsUrl = (cust_id) =>
  `${baseURL}/account/${cust_id}/credits`;

export const postNewCustomerCreditsUrl = (cust_id) =>
  `${baseURL}/account/${cust_id}/credits`;

export const putActivatePlan = (plan_id) =>
  `${baseURL}/pricing-plan/${plan_id}`;

export const getDeletePlan = (plan_id) => `${baseURL}/pricing-plan/${plan_id}`;

export const putBillingSchedule = (customer_id, plan_id) =>
  `${baseURL}/billing/set-billing-schedule/${customer_id}/${plan_id}`;


export const terminateAccountPlan = (plan_id) =>
  `${baseURL}/account/plans/terminate/${plan_id}`;

export const deleteAPIKey = (key_id) =>
  `${baseURL}/access_mgmt/delete-api-key/${key_id}`;

export const getCustomerUsage = (ext_cust_id, duration_in_months = 6) => {
  return `${baseURL}/usage/get-usages-by-customer/${ext_cust_id}/${duration_in_months}`;
};
export const getEventsUrl = (uid) => `${baseURL}/meter/${uid}/events`;
export const getEventsUrl1 = (meterGroup, meterKey, from) =>
  `${baseURL}/usage/get-events?meter_group=${encodeURIComponent(
    meterGroup
  )}&meter_key=${encodeURIComponent(meterKey)}&from=${encodeURIComponent(
    from
  )}`;

export const getUOMsURL = () => `${baseURL}/utils/uoms`;

export const getAllBillsUrl = (
  externalGUID,
  from_duration_in_months,
  to_duration_in_months
) => {
  return `${baseURL}/billing/get-summary-bills/${from_duration_in_months}/${to_duration_in_months}?page_size=20&page_number=1&customer_id=${externalGUID}`;
};

export const getBillDetails = (bill_id) => {
  return `${baseURL}/billing/get-bill-detail/${bill_id}`;
};

export const approveBill = (bill_id) => {
  return `${baseURL}/billing/approve-bill/${bill_id}`;
};

export const getTodateBillUrl = (cust_id, plan_id) => {
  return `${baseURL}/billing/get-latest-bill/${cust_id}/${plan_id}`;
};

export const getBillDownloadPresignedURL = (bill_id) => {
  return `${baseURL}/billing/get-bill-pdf-presigned-url/${bill_id}`;
};

export const getUserChangePasswordUrl = () => {
  return `${baseURL}/users/change-password`;
};

// Endpoints for Integration Preferences
export const postStripeConnectedAccount = (token) => {
  return `${baseURL}/stripe/connect-account/${token}`;
};

export const getStriptePreferences = () =>
  `${baseURL}/integration-preference?app=stripe`;

export const postStripePreferences = () => `${baseURL}/integration-preference`;

export const getPaliqCustomerFields = () =>
  `${baseURL}/integration-preference/customer-fields?app=paliq&src-app=stripe`;

export const getStripeCustomerFields = () =>
  `${baseURL}/integration-preference/customer-fields?app=stripe`;

export const getPaliqProductFields = () =>
  `${baseURL}/integration-preference/product-fields?app=paliq`;

export const getStripeProductFields = () =>
  `${baseURL}/integration-preference/product-fields?app=stripe`;

export const postStripeIntegrationMapping = () =>
  `${baseURL}/integration-preference/mapping`;

export const getStripeIntegrationMapping = () =>
  `${baseURL}/integration-preference/mapping?app=stripe`;

// End of Endpoints for Integration Preferences

export const getPlansByServiceId = (service_id) => {
  return `${baseURL}/pricing-plan/service/${service_id}`;
};

export const showCustomersPlansComparison = () => {
  return `${baseURL}/plan_comparisons/show-customers-plans-comparisons`;
};

export const getMonthlyAnalyticsByCustomer = () => {
  return `${baseURL}/analytics/get-monthly-by-customer`;
};

export const getDailyAnalyticsByCustomer = (pageNum) => {
  return `${baseURL}/analytics/get-monthly-by-customer?page_size=10&page_number=${pageNum}`;
};

export const getMonthlyAnalyticsByService = () => {
  return `${baseURL}/analytics/get-monthly-by-service`;
};

export const getDailyAnalyticsByService = (pageNum) => {
  return `${baseURL}/analytics/get-monthly-by-service?page_size=10&page_number=${pageNum}`;
};

export const getUsageTrends = () => {
  return `${baseURL}/analytics/get-usage-patterns`;
};

export const getDailyAnalyticsByUsage = (pageNum) => {
  return `${baseURL}/analytics/get-usage-patterns?page_size=10&page_number=${pageNum}`;
};

export const getAccountPaymentsByExternalId = (external_id) => {
  return `${baseURL}/payment/account/external/${external_id}`;
};

export const getAccountPaymentMethodsByExternalId = (account_id) => {
  return `${baseURL}/account_payment_method/external_account/${account_id}`;
};

export const getAccountPaymentMethodsByAccountId = (account_id) => {
  return `${baseURL}/account_payment_method/account/${account_id}`;
};


export const putConnectedApp = (uid) => `${baseURL}/connected_app/${uid}`;

export const postUserStatus = () => `${baseURL}/team/set_status`;

export const postUserUpdate = (user_id) => `${baseURL}/team/update/${user_id}`;

export const postUserInvite = () => `${baseURL}/team/invite`;

export const putPlan = (uid) => `${baseURL}/plan/${uid}`;
