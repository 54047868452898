import { menuKeys as m } from "./menuKeys.constants";
import Icon, { UserOutlined } from "@ant-design/icons";

import { ServicesOutlined } from "../../assets/img/icons/sideBarIcons";

// help, documentation, tips
const bottom = [m.contact, m.help, m.more, m.me];

const me = [m.userinfo, m.divider, m.password, m.logout];

export const SETTINGS = [
  {
    label: "Company Settings",
    children: [m.company, m.team]
  },
  {
    label: "Invoice & Payment Settings",
    children: [m.paymentMethods, m.workflows]
  },
  {
    label: "API & Integrations",
    children: [m.api, m.integrations, m.connected_apps]
  },
  {
    label: "Data Import/ Export",
    children: [m.imexport]
  }
];

// Menus: [top, more, bottom, me] menu items
export const Menus = {
  admin: [
    [
      m.dashboard,
      m.customer,
      m.billing,
      m.payments,
      m.plans,
      {
        /* For this one add the side Menu as we discussed on the call.*/
        label: "Products",
        route: "products",
        icon: <Icon component={ServicesOutlined} />,
        children: [m.services, m.meters, m.coupons]
      }
    ],
    [
      m.settings,
      {
        route: "my-profile",
        icon: <UserOutlined style={{ marginLeft: "8px", marginTop: "16px" }} />,
        children: [m.userinfo, m.divider, m.password, m.logout]
      }
    ],
    me
  ],
  finops: [
    [
      m.dashboard,
      m.customer,
      m.billing,
      m.plans,
      m.services,
      m.meters,
      m.payments,
      m.pricing
    ],
    [
      m.company,
      m.team,
      m.paymentMethods,
      m.workflows,
      m.imexport,
      m.connected_apps,
      m.integrations,
      m.api
    ],
    bottom,
    me
  ],
  revops: [
    [
      m.home,
      m.dashboard,
      m.customer,
      m.billing,
      m.plans,
      m.services,
      m.meters,
      m.payments,
      m.pricing
    ],
    [
      m.company,
      m.team,
      m.paymentMethods,
      m.workflows,
      m.imexport,
      m.connected_apps,
      m.integrations,
      m.api
    ],
    bottom,
    me
  ],
  pm: [
    [
      m.dashboard,
      m.customer,
      m.billing,
      m.plans,
      m.services,
      m.meters,
      m.pricing
    ],
    [
      m.company,
      m.team,
      m.paymentMethods,
      m.workflows,
      m.imexport,
      m.connected_apps,
      m.integrations,
      m.api
    ],
    bottom,
    me
  ],
  dev: [
    [
      m.dashboard,
      m.meters,
      m.services,
      m.plans,
      m.imexport,
      m.connected_apps,
      m.integrations,
      m.api
    ],
    [
      m.company,
      m.team,
      m.paymentMethods,
      m.workflows,
      m.customer,
      m.billing,
      m.plans,
      m.services,
      m.pricing
    ],
    bottom,
    me
  ],
  partner: [
    [
      m.dashboard,
      m.customer,
      m.billing,
      m.plans,
      m.services,
      m.meters,
      m.pricing
    ],
    [
      m.company,
      m.team,
      m.paymentMethods,
      m.workflows,
      m.imexport,
      m.connected_apps,
      m.integrations,
      m.api
    ],
    bottom,
    me
  ],
  partadmin: [
    [
      m.home,
      m.dashboard,
      m.customer,
      m.billing,
      m.plans,
      m.services,
      m.meters,
      m.pricing
    ],
    [
      m.company,
      m.team,
      m.paymentMethods,
      m.workflows,
      m.imexport,
      m.connected_apps,
      m.integrations,
      m.api
    ],
    bottom,
    me
  ],
  customer: [
    [
      m.home,
      m.dashboard,
      m.customer,
      m.billing,
      m.plans,
      m.services,
      m.meters,
      m.pricing
    ],
    [
      m.company,
      m.team,
      m.paymentMethods,
      m.workflows,
      m.imexport,
      m.connected_apps,
      m.integrations,
      m.api
    ],
    bottom,
    me
  ],
  custadmin: [
    [
      m.home,
      m.dashboard,
      m.customer,
      m.billing,
      m.plans,
      m.services,
      m.meters,
      m.pricing
    ],
    [
      m.company,
      m.team,
      m.paymentMethods,
      m.workflows,
      m.imexport,
      m.connected_apps,
      m.integrations,
      m.api
    ],
    bottom,
    me
  ]
};
