import React, { useState } from "react";
import {Modal, Upload} from 'antd';
import { APICONFIG } from '../../../services/apiConfig';
import {getRequest, postRequest} from "../../../services";
import { getRefreshTokenFromLS } from "../../../utils/helper";
import { useNavigate } from "react-router-dom";

import {StyledButton} from "../../common";

function UploadCustomersModal({visible, setVisible}) {

    const [confirmLoading, setConfirmLoading] = useState(false);
    const [modalText, setModalText] = useState("");

    const navigate = useNavigate();

    const uploadAction = async (file) => {
        try {
            const ref_token = getRefreshTokenFromLS();
            const res = await getRequest(APICONFIG.getCustomersS3Url, { refresh_token: ref_token });
            console.log("res = ", res);
            if (res?.job_id) {
                console.log('got s3 url successfully ' );
                console.log(res?.upload_uri);
                return res?.upload_uri
            } else {
                console.log(res?.message);
                setModalText('Upload Failed. Check your internet access and Try Again');
                return ''
            }
        } catch (err) {
            console.log('upload failed');
            setModalText('Upload Failed. Check your internet access and Try Again');
            setConfirmLoading(false);
            Promise.reject(err);
        }
    };

    const footer = () => {
        return (
            <div>
                <StyledButton type="primary" onClick={handleHide}>Hide</StyledButton>
            </div>
        );
    }

    const handleHide = () => {
        console.log('Clicked cancel button');
        setVisible(false);
    };
    const headers = {'x-amz-server-side-encryption':'aws:kms'}
    return (
        <Modal
            visible={visible}
            onCancel={handleHide}
            maskClosable={false}
            footer={footer()}
        >
            <p>{modalText}</p>
            <Upload
                accept=".csv"
                action={uploadAction}
                maxCount={5}
                method={"PUT"}
                headers={headers}
                listType={"picture"}
            >
                <StyledButton type="primary">
                    Select File
                </StyledButton>
            </Upload>

        </Modal>
    );
};


export default UploadCustomersModal;