import { postRequest } from ".";
import { APICONFIG } from "./apiConfig";
import {
  setLoginDetailsinLS,
  getRefreshTokenFromLS,
  setIdTokeninLS,
  setAccessTokeninLS,
  getUserEmailfromLS
} from "../utils/helper";

import axios from "axios";

export const logoutRequest = () => {};

export const refreshIdToken = async () => {
  try {
    const newAxios = axios.create();
    newAxios.defaults.headers = {
      "Content-Type": "application/json"
    };
    const refToken = getRefreshTokenFromLS();
    const userEmail = getUserEmailfromLS();
    const data = JSON.stringify({
      refresh_token: refToken,
      user_name: userEmail
    });
    const res = await newAxios.post(APICONFIG.getRefreshTokenUrl, data);
    if (res.status === 200) {
      const idToken = res?.data.AuthenticationResult?.IdToken;
      const accessToken = res?.data.AuthenticationResult?.AccessToken;
      setIdTokeninLS(idToken);
      setAccessTokeninLS(accessToken); // you don't get a new refresh token from this endpoint
      return [userEmail, idToken];
    } else {
      console.log("status returned not 200. It is = ");
      return [null, null];
    }
  } catch (err) {
    console.log(
      "some error occured in getting ref token via axios. Error = ",
      err
    );
    //Promise.reject(err);
    return [null, null];
  }
};

export const userLogin = async (values) => {
  try {
    const res = await postRequest(APICONFIG.getUserLoginUrl, values);
    if (res['ChallengeName']) {
      return res;
    }
    if (res['AuthenticationResult']) {
      setLoginDetailsinLS(res, values.user_name);
      return res;
    } else {
      throw new Error(`Login did not return IdToken, res = ${res}`);
    }
  } catch (err) {
    console.log("login failed");
    throw err;
  }
};
