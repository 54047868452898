import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import ListTable from "../ListTable";
import { PlusButton, Spinner } from "../common";
import { formatDate, withTooltip } from "../common/table";
import { getRequest } from "../../services";
import { APICONFIG } from "../../services/apiConfig";
import { itemKeys as l, menuKeys as m } from "../menus/menuKeys.constants";
import CreatePaymentMethodStub from "./CreatePaymentMethodStub";

const PaymentMethodList = () => {
  const navigate = useNavigate();

  const {isLoading, error, data} = useQuery({
    queryKey: ["payment_methods"],
    queryFn: () => getRequest(APICONFIG.getPaymentMethodsUrl).then(r => r['data'])
  });

  if (isLoading) return <Spinner />;

  if (error) return "An error has occurred: " + error.message;

  if (data && data.length === 0) return <CreatePaymentMethodStub />;

  const paymentTypes = {
    "cc": "Credit Card",
    "bank": "Bank",
    "stripe": "Stripe"
  }

  const columns = [
    {
      title: "Account Name",
      dataIndex: "account_name",
      width: "10%",
      render: (_, record) => (
        <Link to={l.paymentMethodDetail.getRoute({id: record['uid']})} className="link-color">
          {record['account_name']}
        </Link>
      )
    },
    {
      title: "Description",
      dataIndex: "description",
      width: "20%",
    },
    {
      title: "Payment Type",
      dataIndex: "payment_method",
      width: "10%",
      render: (p) => paymentTypes[p] ?? p
    },
    {
      title: "Created By",
      dataIndex: "created_by_user",
      width: "10%",
      render: (created_by_user) => created_by_user?.name ?? 'API User'
    },
    {
      title: "Updated By",
      dataIndex: "updated_by_user",
      width: "10%",
      render: (updated_by_user) => updated_by_user?.name ?? 'API User'
    },
    {
      title: "Created On",
      dataIndex: "date_created",
      width: "10%",
      render: (dt) => formatDate(dt)
    },
    {
      title: "Updated On",
      dataIndex: "date_updated",
      width: "10%",
      render: (dt) => formatDate(dt)
    }
  ];

  return <ListTable
      title="Payment Accounts"
      titleButtons={[
        <PlusButton
          key="1"
          size="large"
          title={m.paymentMethodCreate.label}
          onClick={() => navigate(m.paymentMethodCreate.route)}
        />
      ]}
      onButtonClick={() => navigate(m.paymentMethodCreate.route)}
      buttonText={m.paymentMethodCreate.label}
      data={data}
      rowKey={(record) => record["uid"]}
      columns={withTooltip(columns)}
      pagination="pagination"
    />;
};

export default PaymentMethodList;
