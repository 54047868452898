import { useState, useEffect } from "react";
import { Select, Button, DatePicker, Space } from "antd";
import { FormItem } from "../../../paliq/input/FormItem";
import { Form, FormRow } from "../../../common/form";
import { CheckCircleFilled, PercentageOutlined } from "@ant-design/icons";
import { Box } from "../../../common";
import { SectionTitle } from "../../../common/page";
import { CollapsibleCard } from "../../../common/CollapsibleCard";
import {
  CURRENCY_DATA,
  termData,
  BILLING_SCHEDULE_STATUS
} from "../../../../constants/globalStaticConstacts";
import {
  createNewCustomerPlanJSONitemFromPlan,
  generateFormValuesFromPlanJSONs
} from "../../customer-helper";
import { useNavigate } from "react-router-dom";
import { putRequest } from "../../../../services";
import { putBillingSchedule } from "../../../../services/apiConfig";
import { info } from "../../../../utils/commonActions";
import {
  ActiveBillingScheduleIcon,
  DisableBillingScheduleIcon
} from "../../../common/icons";
import CustomerPlanDetail from "./CustomerPlanDetail";

/*
planJSONs is a list like:
[
        {
            "id": "13538b21-dc1e-44ee-80b6-a3671772fcc7",
            "plan_id": "6de10289-a677-486e-b912-28a58ffb67ec",
            "status": "active",
            "customer_id": "d9edb5ce-e437-423c-ac9c-47ca7c3665ac",
            "salesorder": null,
            "payment_terms": "net 30",
            "start_date": "2022-07-01",
            "end_date": "2023-07-01",
            "billing_currency": "USD",
            "sales_tax_percent": 1.0,
            "company_id": 1,
            "date_created": "2022-09-22 13:29:25.767126+00:00",
            "date_updated": "2022-09-22 13:29:25.767128+00:00"
        },
        ....
    ]
*/

const CustomerPlansDetails = (props) => {
  const navigate = useNavigate();

  const { accountPlans, setAccountPlans, } = props; // plan JSON will be for customer plan endpoint TBD
  const {
    pricingPlans,
    onFinish,
    onFinishFailed,
    customerId,
    external_guid
  } = props; // plan JSON will be for customer plan endpoint TBD

  const [dropDownVal, setDropDownVal] = useState(); // will contain the uid of plan selected.

  const form = Form.useFormInstance();

  useEffect(() => {
    const formVals = generateFormValuesFromPlanJSONs(accountPlans);
    form.setFieldsValue(formVals);
    return () => {};
  }, [accountPlans]);

  const addPlanToCustomer = () => {
    if (dropDownVal) {
      const pln = pricingPlans.find((p) => p.uid === dropDownVal);
      console.log('plan found = ', pln);
      const newLine = createNewCustomerPlanJSONitemFromPlan(
        pln.uid,
        customerId
      );
      console.log("New Plan = ", newLine);
      setAccountPlans([...accountPlans, newLine]);
      setDropDownVal("");
    } else {
      console.log("select a plan first");
    }
  };

  const handleChange = (value) => {
    setDropDownVal(value);
  };
  return (
    <>
      <SectionTitle title="Assign a Plan" />
      <Box mb={3}>
        <Select
          style={{
            width: 320,
            marginRight: 16
          }}
          value={dropDownVal}
          placeholder="Select a Plan"
          onChange={handleChange}
        >
          {pricingPlans?.map((p) => {
            // Any plan that is already assigned is greyed out in the dropdown.
            // Any plan that is not Active is greyed out in the drop down.
            return (
              <Select.Option
                value={p.uid}
                disabled={
                  accountPlans?.some(ap => ap.plan_id === p.uid) ||
                  p.status == "draft"
                }
              >
                {p.name}
              </Select.Option>
            );
          })}
        </Select>
        <Button
          type="primary"
          onClick={() => addPlanToCustomer()}
          disabled={!!!customerId}
        >
          Assign
        </Button>
      </Box>

      <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
        {accountPlans &&
          pricingPlans &&
          accountPlans?.map(accountPlan => {
            const pricingPlan = pricingPlans.find((pp => pp.uid === accountPlan.plan_id));
            return <CustomerPlanDetail key={pricingPlan.uid} pricingPlan={pricingPlan} accountPlan={accountPlan} external_guid={external_guid} />;
          })
        }
      </Form>
    </>
  );
};

export default CustomerPlansDetails;
