import React, { useState } from "react";
import { message } from "antd";
import { postRequest } from "../../services";
import { APICONFIG } from "../../services/apiConfig";
import { useLocation } from "react-router-dom";
import ChangePasswordCard from "../../components/loginSignUp/setPassword";
import { info } from "../../utils/commonActions";
import { setLoginDetailsinLS } from "../../utils/helper";
import postLogin from "./postLogin";
import { useContext } from "react";
import { AuthContext } from "../../App";
import { useNavigate, useSearchParams } from "react-router-dom";

const Confirm = () => {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const username = location.state?.username;
  const session = location.state?.session;
  const formInfo = "Your are required to set a new password";
  const [alert, setAlert] = useState({message: "", type: ""});
  const { dispatch } = useContext(AuthContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const onFinish = async (values) => {
    try {
      if (values["new_password"] !== values["confirm_password"]) {
        info("New password and confirm password must be same");
        return;
      }
      if (!values["username"]) {
        values["username"] = username;
      }
      if (!values["session"]) {
        values["session"] = session;
      }

      setAlert({message: "", type: ""});
      setLoading(true);
      const response = await postRequest(APICONFIG.getSetPasswordURL, values);
      console.log(response);
      const status = response['op_status'];
      if (status === 'OK') {
        message.info("Your password is changed.");
        let idToken = response['response']['AuthenticationResult']['IdToken']
        setLoginDetailsinLS(response['response'], username);
        await postLogin(idToken, dispatch, searchParams, navigate);
      } else if (status === 'ERROR') {
        setAlert({message: response['message'], type: "error"});
      } else {
        message.error("An error occurred while processing your request. Please try again.");
      }
    } catch (err) {
      console.log(err);
      message.error(err.message);
    } finally {
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <ChangePasswordCard
      onSubmit={onFinish}
      onSubmitFailed={onFinishFailed}
      isLoading={loading}
      username={username}
      formInfo={formInfo}
      alert={alert}
    />
  );
};

export default Confirm;